import SolitudeDetail from './SolicitudeDetail';
import { authRoles } from 'auth';

export const SolicitudeDetailConfig = {
    settings: {
        layout: {
            config: {
            }
        }
    },
    // auth: authRoles.admin,
    routes: [{
        path: '/detalle/solicitud/:id?',
        component: SolitudeDetail
    }
    ]
};