import Thanks from './Thanks';
import {authRoles} from 'auth';

export const ThanksConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.user,
    routes  : [
        {
            path     : '/gracias',
            component: Thanks
        }
    ]
};