import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Button, ListItemIcon, ListItemText, Icon, MenuItem, Popover, Typography, Hidden } from '@material-ui/core';
import { FuseAnimate } from '@fuse';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from 'auth/store/actions';
import * as Actions from 'store/actions';
import { List } from '@material-ui/icons';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    seperator: {
        width: 1,
        height: 64,
        backgroundColor: theme.palette.divider
    },
    layoutRoot: {},
    button: {
        marginTop: theme.spacing.unit
    },
});

class HeaderComponent extends Component {
    state = {
        userMenu: null
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.user.success && nextProps.user.success !== this.props.user.success) {
            const pathname = '/';
            this.props.history.push({
                pathname
            });
        }
    }

    userMenuClick = event => {
        this.setState({ userMenu: event.currentTarget });
    }

    userMenuClose = () => {
        this.setState({ userMenu: null });
    }

    render() {
        const { classes, user, logout } = this.props;
        const { userMenu } = this.state;
        console.log(user)
        return (
            <div className={classNames(classes.root, "flex flex-row p-24")}>
                <div className="flex-1 flex pt-24">
                    <div className="w-1/4">
                    <Hidden lgUp>
                    <Button onClick={this.props.navbarOpenMobile} >
                            <List />
                        </Button>
                    </Hidden>
                        <img className="w-160 sm:w-160 md:w-224 lg:w-256 mb-32" src="assets/images/logos/cuauhtemoc_blanco.svg" alt="cuauhtemoc" />
                    </div>
                    <div className="w-1/2 text-center">
                        <Typography color="inherit" className="font-bold text-16 sm:text-16 md:text-28 lg:text-32 xl:text-36">
                            Sistema de cuidados y derechos humanos
                        </Typography>
                    </div>
                    <div className="w-1/4">
                        <FuseAnimate delay={300}>
                            <Button className="h-64 float-right" onClick={this.userMenuClick}>
                                {user.data.photoURL ?
                                    (
                                        <Avatar className="" alt="user photo" src={user.data.photoURL} />
                                    )
                                    :
                                    (
                                        <Avatar className="">
                                            {user.data.displayName ? user.data.displayName[0] : 'A'}
                                        </Avatar>
                                    )
                                }

                                <div className="hidden md:flex flex-col ml-12 items-start">
                                    <Typography component="span" className="normal-case font-600 flex">
                                        {user.data.role}
                                    </Typography>
                                    <Typography className="normal-case font-10 flex" color="textSecondary">
                                        {user.data.username}
                                    </Typography>
                                </div>

                                <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
                            </Button>
                        </FuseAnimate>
                        <Popover
                            open={Boolean(userMenu)}
                            anchorEl={userMenu}
                            onClose={this.userMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            classes={{
                                paper: "py-8"
                            }}
                        >
                            {user.role === 'guest' ? (
                                <React.Fragment>
                                    <MenuItem component={Link} to="/login">
                                        <ListItemIcon>
                                            <Icon>lock</Icon>
                                        </ListItemIcon>
                                        <ListItemText className="pl-0" primary="Ingresar" />
                                    </MenuItem>
                                    {/* <MenuItem component={Link} to="/registrar">
                                        <ListItemIcon>
                                            <Icon>person_add</Icon>
                                        </ListItemIcon>
                                        <ListItemText className="pl-0" primary="Registrarme" />
                                    </MenuItem> */}
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        <MenuItem component={Link} to="/home">
                                            <ListItemIcon>
                                                <Icon>home</Icon>
                                            </ListItemIcon>
                                            <ListItemText className="pl-0" primary="Inicio" />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                logout();
                                                this.userMenuClose();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <Icon>exit_to_app</Icon>
                                            </ListItemIcon>
                                            <ListItemText className="pl-0" primary="Cerrar Sesión" />
                                        </MenuItem>
                                    </React.Fragment>
                                )}
                        </Popover>
                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout: authActions.logoutUser,
        navbarOpenMobile: Actions.navbarOpenMobile
    }, dispatch);
}

function mapStateToProps({ auth, fuse }) {
    return {
        user: auth.user,
        navbar: fuse.navbar
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)));