import { fetchRoles } from 'api'

export const FETCHING_ROLES = 'FETCHING_ROLES';
export const FETCHING_ROLES_SUCCESS = 'FETCHING_ROLES_SUCCESS';
export const FETCHING_ROLES_FAILURE = 'FETCHING_ROLES_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_ROLES
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_ROLES_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_ROLES_FAILURE,
        error
    }
}

export const listRoles = () => {
    return (dispatch) => {
        dispatch(getData())
        fetchRoles()
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            }).catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}