import React from 'react';
import { Typography, Button, Divider, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FuseAnimate } from '@fuse';
import * as Action from 'store/actions';
import * as ActionsLogin from 'auth/store/actions';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        display: 'none',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        // button: '50%',
        top: '50%',
        right: 50,
        marginTop: -12,
        marginLeft: -12,
    },
});

class BulkLoadView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            message: 'Selecciona un archivo con dos columnas(folio, fecha), en formato CSV (delimitado por comas). La fecha debe cumplir con el formato "YYYY-MM-DD".',
            name_file: '',
            file: null
        }
    }

    componentWillMount() {
        const token = localStorage.getItem('@token')
        if (token) {
            this.props.reacceso();
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.bulkload.success && nextProps.bulkload.success !== this.props.bulkload.success) {
            console.log('Archivo cargado con éxito.')
            this.setState({
                message: 'Archivo cargado con éxito.',
                name_file: '',
                file: null
            })
        }
        if (nextProps.bulkload.error && nextProps.bulkload.error !== this.props.bulkload.error) {
            console.log('Error al cargar archivo.')
            this.setState({
                message: 'Error al cargar archivo.',
                name_file: '',
                file: null
            })
        }
    }

    loadFile = (e) => {
        e.preventDefault();
        const fileUpload = this.file.files[0]
        if (fileUpload) {
            if (fileUpload.type === 'text/csv') {
                this.setState({
                    file: fileUpload,
                    name_file: fileUpload.name
                })
            }
        }
    }

    submitFile = () => {
        let form = new FormData();
        form.append('csv_file', this.state.file, 'bulkload.csv');
        this.props.sendBulkLoad(form)
    }

    render() {
        const { classes, bulkload } = this.props;
        console.log('files', bulkload)
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h4" style={{ color: '#616161' }}> <strong>Autorización Masiva</strong></Typography>
                    <br />
                    <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 1 }} />
                </div>
                <br />
                <div className="m-auto">
                    <FuseAnimate animation={{ translateX: [0, '100%'] }}>
                        <React.Fragment>
                            <div className={classNames(classes.root, 'justify-end')}>
                                <Typography variant="subtitle1" style={{ color: 'gray', marginRight: 10 }}>{this.state.message}</Typography>
                                <input
                                    accept="text/csv"
                                    className={classes.input}
                                    id="file"
                                    type="file"
                                    disabled={bulkload.isFetching}
                                    ref={(file) => { this.file = file }}
                                    onChange={(e) => { this.loadFile(e) }}
                                />
                                <label htmlFor="file">
                                    <Button variant="outlined" disabled={bulkload.isFetching} component="span">
                                        {bulkload.isFetching ? "Cargando..." : "Buscar"}
                                    </Button>
                                </label>
                                {bulkload.isFetching && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography variant="subtitle1" style={{ color: 'gray', marginRight: 10 }}>{this.state.name_file}</Typography>
                                {bulkload.success &&
                                    <a href={bulkload.data && bulkload.data.log_file} target='_blank'>
                                        <Typography color='primary' variant="subtitle1" style={{marginRight: 10 }}>LOGS</Typography>
                                    </a>

                                }
                                <br />
                                <Button variant='outlined' color='primary' onClick={this.submitFile} disabled={(this.state.file === null ? true : false) || bulkload.isFetching}>Guardar carga</Button>
                            </div>
                        </React.Fragment>
                    </FuseAnimate>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        reacceso: ActionsLogin.reacceso,
        sendBulkLoad: Action.sendBulkLoad,
    }, dispatch);
}

function mapStateToProps({ solicitude }) {
    return {
        bulkload: solicitude.bulkload,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkLoadView)));