import * as Actions from '../../actions/index';

const initialState = {
    data: [],
    success: false,
    isFetching: false,
    error: false
}

const way_know = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_WAY_KNOW: {
            return {
                ...state,
                data: [],
                success: false,
                isFetching: true
            }
        }
        case Actions.FETCHING_WAY_KNOW_SUCCESS: {
            return {
                ...state,
                data: action.data,
                success: true,
                isFetching: false
            }
        }
        case Actions.FETCHING_WAY_KNOW_FAILURE: {
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }
        }
        default: {
            return state
        }
    }
}

export default way_know;