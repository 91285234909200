import React, { Component } from 'react';
import { TextField, Typography, Paper, MenuItem } from '@material-ui/core';
import _ from '@lodash';

import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import { withStyles } from '@material-ui/core/styles';
import { withFormsy } from 'formsy-react';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: 'flex',
        padding: 10,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 10,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 100,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    }
});


function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            disabled={props.selectProps.disabled}
            error={props.selectProps.error}
            helperText={props.selectProps.helperText}
            required
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
            fullWidth
            variant='outlined'
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class Select2 extends Component {

    constructor(props) {
        super(props);
        this.state = { value: '' }
    }

    changeValue = (event) => {
        console.log("change", event)
        this.setState({ value: event })
        this.props.setValue(event);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    render() {
        const { classes } = this.props
        const importedProps = _.pick(this.props, [
            'autoComplete',
            'autoFocus',
            'children',
            'className',
            'defaultValue',
            'disabled',
            'FormHelperTextProps',
            'fullWidth',
            'id',
            'InputLabelProps',
            'inputProps',
            'InputProps',
            'inputRef',
            'label',
            'multiline',
            'name',
            'onBlur',
            'onChange',
            'onFocus',
            'placeholder',
            'required',
            'rows',
            'rowsMax',
            'select',
            'SelectProps',
            'type',
            'variant',
            'validate',
            'classes',
            'options',
            'value',
            'error',
            'helperText',
            'async',
            'loadOptions',
            'noOptionsMessage',
            'loadingMessage',
            'isClearable',
            'isMulti'
        ]);

        const Selector = this.props.async ? AsyncSelect : Select
        const value =  this.props.getValue();

        return (
            <div className={classes.root}>
                <Selector
                    {...importedProps}
                    classes={classes}
                    components={components}
                    value={value}
                    onChange={this.changeValue}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withFormsy(Select2));
