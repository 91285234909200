import * as Actions from '../../actions/solicitude/index';

const initialState = {
    itemCheck: {}
}

const validate_check = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_VALIDATE_CHECK: {
            let itemCheck = state.itemCheck
            const target = action.value.target;
            const value = target.checked;
            itemCheck[action.name] = value
            return {
                ...state,
                itemCheck
            }
        }
        case Actions.CLEAN_CHECK_ITEM: {

            // var cuenta = Object.keys(initialState.itemCheck).length
            // console.log("Cuanto --->", cuenta)
            // if (cuenta > 0) {
            //     Object.keys(initialState.itemCheck).map(key=>{
            //         storage.removeItem(`persist:${key}`);
            //     })
            // }
            return initialState
        }

        default:
            return state
    }
}

export default validate_check;