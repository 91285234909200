import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import classNames from 'classnames';

const styles = theme => ({
    root: {}
});

function MainFooter({classes}){
	// const year = new Date().getFullYear();
    return (
        <div className={classNames(classes.root, "flex-1")}>
            <div className="m-auto w-1/2">
                <div className="flex flex-row justify-center w-full">
                    <div className="w-1/2 m-6">
                        <img className="h-52 float-right" src="assets/images/logos/cuauhtemoc.svg" alt="cuauhtemoc"/>
                    </div>
                    <div className="w-1/2 m-6">
                        <p className="text-10">Aldama y Mina S/N Buenavista</p>
                        <p className="text-10">Alcaldía Cuauhtémoc, C.P.06350, Ciudad de México</p>
                        <p className="text-10">+(55)24223100</p>
                        <p className="text-10">contacto@cuauhtemoc.cdmx.gob.mx</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles, {withTheme: true})(MainFooter);