import { FuseLoadable } from '@fuse';
import {authRoles} from 'auth';

export const ProgramListConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/programas',
            component: FuseLoadable({
                loader: () => import('./ProgramList')
            })
        }
    ]
};