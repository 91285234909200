import { fetchCancelPetition } from 'api'

export const FETCHING_PETITION_CANCEL = 'FETCHING_PETITION_CANCEL';
export const FETCHING_PETITION_CANCEL_SUCCESS = 'FETCHING_PETITION_CANCEL_SUCCESS';
export const FETCHING_PETITION_CANCEL_FAILURE = 'FETCHING_PETITION_CANCEL_FAILURE';
export const SET_COMMENT_CANCEL = 'SET_COMMENT_CANCEL';
export const CLEAN_COMMENT_CANCEL = 'CLEAN_COMMENT_CANCEL';

export const getData = () => {
    return {
        type: FETCHING_PETITION_CANCEL
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_PETITION_CANCEL_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_CANCEL_FAILURE,
        error
    }
}

export const set_comment_cancel = e => {
    return {
        type: SET_COMMENT_CANCEL,
        value: e.target.value
    }
}

export const clean_comment_cancel = () => {
    return {
        type: CLEAN_COMMENT_CANCEL
    }
}

export const cancelPetition = (id_petition, comment) => {
    return (dispatch) => {
        dispatch(getData())
        fetchCancelPetition(id_petition, comment)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}