import { FuseLoadable } from '@fuse';
import { authRoles } from 'auth';

export const ErrorConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: true
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/error',
            component: FuseLoadable({
                loader: () => import('./Error.js')
            })
        }
    ]
};