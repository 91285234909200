export const SET_PROGRAM_CHOICE = 'SET_PROGRAM_CHOICE';
export const SET_BENEFICIARIES_DATA = 'SET_BENEFICIARIES_DATA';

export const set_program_choice = data => {
    return {
        type: SET_PROGRAM_CHOICE,
        value: data.name,
        data : data
    }
}

export const setBeneficiariesData = data => {
    return {
        type: SET_BENEFICIARIES_DATA,
        data : data
    }
}