import { fetchGeolocations } from 'api'

export const FETCHING_GEOLOCATION = 'FETCHING_GEOLOCATION';
export const RESET_GEOLOCATION = 'RESET_GEOLOCATION';
export const FETCHING_GEOLOCATION_SUCCESS = 'FETCHING_GEOLOCATION_SUCCESS';
export const FETCHING_GEOLOCATION_FAILURE = 'FETCHING_GEOLOCATION_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_GEOLOCATION
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_GEOLOCATION_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_GEOLOCATION_FAILURE,
        error
    }
}

export const resetGeolocation = () => {
    return {
        type: RESET_GEOLOCATION
    }
}

export const getGeolocation = (params) => {
    return (dispatch) => {
        dispatch(getData())
        fetchGeolocations(params)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            }).catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}