/**
 * Authorization Roles
 */
const authRoles = {
    superadmin: ['admin'],
    admin    : ['admin', 'reviser'],
    staff    : ['staff', 'admin', 'reviser'],
    user     : ['user', 'staff','admin'],
    onlyGuest: ['guest']
};

export default authRoles;
