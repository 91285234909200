import { fetchSocieconimicUpdate } from 'api'


export const FETCHING_UPDATE_SOCIOECONOMIC = 'FETCHING_UPDATE_SOCIOECONOMIC';
export const FETCHING_UPDATE_SOCIOECONOMIC_SUCCESS = 'FETCHING_UPDATE_SOCIOECONOMIC_SUCCESS';
export const FETCHING_UPDATE_SOCIOECONOMIC_FAILURE = 'FETCHING_UPDATE_SOCIOECONOMIC_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_UPDATE_SOCIOECONOMIC
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_UPDATE_SOCIOECONOMIC_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_UPDATE_SOCIOECONOMIC_FAILURE,
        error
    }
}


export const updateSocioeconomic = (id, data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchSocieconimicUpdate(id, data)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}