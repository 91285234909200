import { fetchPetitionUpdate } from 'api'

export const FETCHING_SAVE_WAY_KNOW_SOLICITUDE = 'FETCHING_SAVE_WAY_KNOW_SOLICITUDE';
export const FETCHING_SAVE_WAY_KNOW_SOLICITUDE_SUCCESS = 'FETCHING_SAVE_WAY_KNOW_SOLICITUDE_SUCCESS';
export const FETCHING_SAVE_WAY_KNOW_SOLICITUDE_FAILURE = 'FETCHING_SAVE_WAY_KNOW_SOLICITUDE_FAILURE';
export const RESET_FETCHING_SOLICITUDE_SAVE_WAY_KNOW = 'RESET_FETCHING_SOLICITUDE_SAVE_WAY_KNOW';
export const RESET_SOLICITUDE_SAVE_WAY_KNOW = 'RESET_SOLICITUDE_SAVE_WAY_KNOW';


export const getData = () => {
	return {
		type: FETCHING_SAVE_WAY_KNOW_SOLICITUDE
	}
}

export const getDataSuccess = data => {
	return {
		type: FETCHING_SAVE_WAY_KNOW_SOLICITUDE_SUCCESS,
		data
	}
}

export const getDataFailure = data => {
	return {
		type: FETCHING_SAVE_WAY_KNOW_SOLICITUDE_FAILURE,
		data
	}
}

export const saveWayKnowSolicitude = (data, id) => {
	return (dispatch) => {
		dispatch(getData())
		fetchPetitionUpdate(data, id).then(data => {
			dispatch(getDataSuccess(data.data))
		}).catch(error => {
			dispatch(getDataFailure(error.response ? error.response.data : {}))
		}).finally(() => {
			dispatch(resetFetching())
		})
	}
}

export function resetSaveWayKnowSolicitude(){
    return {
        type: RESET_SOLICITUDE_SAVE_WAY_KNOW
    }
}

export function resetFetching(){
    return {
        type: RESET_FETCHING_SOLICITUDE_SAVE_WAY_KNOW
    }
}