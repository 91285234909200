import React, { Component } from 'react';
import { Typography, withStyles, Divider, Button } from '@material-ui/core';
import UploadComponent from '../../components/UploadComponent'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import { connect } from 'react-redux';
import FacebookLoading from '../../components/FacebookLoading'


const styles = theme => ({
    layoutRoot: {},
    button: {
        marginLeft: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});


class AttachmentUploads extends Component {
    state = {
        contador: 1,
        // cansubmit: false
    }

    componentDidMount() {
        const { choice } = this.props
        this.props.getPetitionDetail(choice.saved && choice.saved.id)
        // if (choice.saved) {
        //     choice.saved.beneficiarios.forEach((beneficiary) => {
        //         // console.log(beneficiary);
        //         if (beneficiary.tipo_beneficiario === 'directo') {
        //             choice.data.required_document_direct.forEach((document) => {
        //                 if (document.is_required) {
        //                     this.setState({ contador: this.state.contador++ });
        //                 }
        //             });
        //         }
        //         if (beneficiary.tipo_beneficiario === 'directo') {
        //             choice.data.required_document_indirect.forEach((document) => {
        //                 if (document.is_required) {
        //                     this.setState({ contador: this.state.contador++ });
        //                 }
        //             });
        //         }
        //     });
        // }
    }

    componentWillUpdate(nextProps, nextState) {
        // if (nextState.contador == nextProps.upload.completed && !this.state.cansubmit) {
        //     this.setState({ cansubmit: true });
        // }
        if (nextProps.solicitude_detail.success && nextProps.solicitude_detail.success !== this.props.solicitude_detail.success) {
            nextProps.solicitude_detail.data.beneficiarios.forEach((beneficiary) => {
                if (beneficiary.tipo_beneficiario === 'directo') {
                    this.props.choice.data.required_document_direct.forEach((document) => {
                        if (document.is_required) {
                            this.setState({ contador: this.state.contador++ })
                        }
                    })
                }
                if (beneficiary.tipo_beneficiario === 'directo') {
                    this.props.choice.data.required_document_indirect.forEach((document) => {
                        if (document.is_required) {
                            this.setState({ contador: this.state.contador++ })
                        }
                    })
                }
            })
        }
        if (nextProps.finalize.success && nextProps.finalize.success !== this.props.finalize.success) {
            const pathname = '/enterado';
            this.props.history.push({
                pathname
            });
        }
    }

    onBack = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes, choice, upload, solicitude_detail, finalize } = this.props;
        const { contador } = this.state;
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h4" style={{ color: '#616161' }}> <strong>3. Carga de documentos</strong></Typography>
                    <Typography variant="h5" style={{ color: '#E61B72' }}> <strong>Programa: {choice.name_program}</strong></Typography>
                    <br />
                    <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 1 }} />
                </div>
                <br />
                <Typography variant='subtitle1'>Solo archivos PDF, JPG, PNG.</Typography>
                {solicitude_detail.isFetching && <div className="flex justify-center">
                    <FacebookLoading />
                </div>}
                {solicitude_detail.success && solicitude_detail.data.beneficiarios.map((beneficiary, index) => (
                    <div key={index}>
                        {index === 0 && <Typography className="text-18 mb-12">{'Documentación para el ' + choice.data.beneficiary_label + ': ' + beneficiary.first_name + ' ' + beneficiary.last_name + ' ' + beneficiary.m_last_name}</Typography>}
                        {index > 0 && <div>
                            <br />
                            <Divider />
                            <br />
                            <Typography className="text-18 mb-12">{'Documentación para el ' + choice.data.dependent_label + ': ' + beneficiary.first_name + ' ' + beneficiary.last_name + ' ' + beneficiary.m_last_name}</Typography>
                        </div>}
                        <div className="flex-row">
                            <div className="flex flex-wrap">
                                {beneficiary.tipo_beneficiario === 'directo' && choice.data.required_document_direct.map((document, index) => (
                                    <UploadComponent key={index} beneficiary={beneficiary} docuemnt_type={document} />
                                ))}

                                {beneficiary.tipo_beneficiario === 'indirecto' && choice.data.required_document_indirect.map((document, index) => (
                                    <UploadComponent key={index} beneficiary={beneficiary} docuemnt_type={document} />
                                ))}
                            </div>
                        </div>
                    </div>
                ))}

                {(contador - upload.completed) >= 1 &&
                    <div className="flex flex-wrap justify-end">
                        <Typography color="primary" className="text-12">
                            {'Requiere adjunte almenos ' + (contador - upload.completed) + ' documento(s) requerido(s).'}
                        </Typography>
                    </div>}

                {finalize.error &&
                    <div className="flex flex-wrap justify-end">
                        <Typography color="error" className="text-12">
                            {finalize.data}
                        </Typography>
                    </div>}

                <div className="flex flex-wrap justify-end">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.props.finalizeSolicitude({ id: choice.saved.id })}
                        disabled={solicitude_detail.isFetching || finalize.isFetching}
                        className={classes.button}
                        aria-label="SIGUIENTE"
                        value="legacy"
                    >
                        SIGUIENTE
                    </Button>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: Actions.showMessage,
        hideMessage: Actions.hideMessage,
        getPetitionDetail: Actions.getPetitionDetail,
        finalizeSolicitude: Actions.finalizeSolicitude,
    }, dispatch);
}

function mapStateToProps({ program, solicitude }) {
    return {
        choice: program.choice,
        solicitude_detail: solicitude.solicitude_detail,
        finalize: solicitude.finalize,
        upload: solicitude.upload
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(AttachmentUploads)));