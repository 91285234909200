import * as Actions from '../../actions/solicitude/index';

const initialState = {
    data: [],
    success: false,
    file: '',
    isFetching: false,
    error: false
}

const validate_attach = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_VALIDATE_ATTACH:
            return {
                ...state,
                data: [],
                success: false,
                isFetching: true
            }
        case Actions.FETCHING_VALIDATE_ATTACH_SUCCESS:
            return {
                ...state,
                data: action.data,
                success: true,
                isFetching: false
            }
        case Actions.FETCHING_VALIDATE_ATTACH_FAILURE:
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }
        case Actions.GET_FIlE: {
            return {
                ...state,
                file: action.file
            }
        }
        default:
            return state
    }
}

export default validate_attach;