import TarjetaNotificacion from './TarjetaNotificacion';
import {authRoles} from 'auth';

export const TarjetaNotificacionConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.user,
    routes  : [{
            path     : '/tarjeta_notificacion',
            component: TarjetaNotificacion
        }
    ]
};