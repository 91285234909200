import {fetchUserIsActive} from 'api'

export const FETCHING_IS_ACTIVE = 'FETCHING_IS_ACTIVE';
export const FETCHING_IS_ACTIVE_SUCCESS = 'FETCHING_IS_ACTIVE_SUCCESS';
export const FETCHING_IS_ACTIVE_FAILURE = 'FETCHING_IS_ACTIVE_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_IS_ACTIVE
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_IS_ACTIVE_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_IS_ACTIVE_FAILURE,
        error
    }
}

export const toggleUserIsActive = (data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchUserIsActive(data)
            .then(data => {
                dispatch(getDataSuccess(data.data))
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}