import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Icon, Button, Typography, Dialog, DialogContent, DialogContentText, DialogActions, LinearProgress, DialogTitle} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as Actions from 'store/actions';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import Select2 from '../../components/Select2'

const styles = theme => ({
    layoutRoot: {},
    selectField: {
        flexGrow: 1,
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});

class Notificacion extends Component {
    state = {
        isFormValid: false,
        texto_complementario: '',
        open_dialog: false,
        mensaje: '',
        open_confirmacion: false
    }

    componentDidMount() {
        this.props.typeNotificationList()
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.alta_type_notification.success && nextProps.alta_type_notification.success !== this.props.alta_type_notification.success) {
            this.handleCloseDialogConfirmacion()
            this.handleOpenDialogMsj('Se ha guardado correctamente')
        }
    }

    submitForm = () => {
        const {lista} = this.props
        const model = this.formNotification.getModel()
        this.props.altaTypeNotification(lista.id_solicitud, {type_notification: model.type_notification.value})
    }


    disableButton = () => {
        this.setState({
            isFormValid: false
        })
    }

    enableButton = () => {
        this.setState({
            isFormValid: true
        })
    }
    handleChange = (model) => {
        if (model !== null) {
            this.setState({
                texto_complementario: model.complementary_text
            })
        }
    }


    msjDialog = () => {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_dialog}
                onClose={this.handleCloseDialogMsj}
                aria-labelledby="msj"
            >
                <DialogContent>
                    <DialogContentText>
                        {this.state.mensaje}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.handleCloseDialogMsj}
                    >
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleOpenDialogMsj = (mensaje) => {
        this.setState({
            open_dialog: true,
            mensaje: mensaje
        })
    }

    handleCloseDialogMsj = () => {
        this.setState({
            open_dialog: false,
            mensaje: ''
        })
    }


    confirmacionDialog = () => {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_confirmacion}
                onClose={this.handleCloseDialogConfirmacion}
                aria-labelledby="msj"
            >
                {this.props.alta_type_notification.isFetching && <LinearProgress color="secondary"/>}
                <DialogTitle id="comment-solicitud">Advertencia!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de asignar este tipo de notificación a la solicitud?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.handleCloseDialogConfirmacion}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.submitForm}
                    >
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleOpenDialoConfirmacion = () => {
        this.setState({
            open_confirmacion: true
        })
    }

    handleCloseDialogConfirmacion = () => {
        this.setState({
            open_confirmacion: false
        })
    }

    render() {
        const {classes, detalle, list_type_notification} = this.props;
        return (
            <React.Fragment>
                <this.msjDialog/>
                <this.confirmacionDialog/>
                <br/>

                {detalle.data && (detalle.data.status !== 'AUTORIZADA' && detalle.data.is_notified !== true) &&
                <React.Fragment>
                    <div className="flex flex-wrap">
                        <Typography variant="h6" color="primary" className={classes.grow}>
                            Tipo de notificación
                        </Typography>
                    </div>
                    <Formsy
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        ref={(formNotification) => this.formNotification = formNotification}
                        className="flex flex-col justify-center"
                    >
                        <div className='flex flex-wrap'>
                            <Select2
                                name="type_notification"
                                placeholder='Tipo de notificacion'
                                options={list_type_notification.data}
                                className={classes.selectField}
                                required
                                isClearable
                                value={detalle.data && (detalle.data.type_notification ? detalle.data.type_notification : null)}
                                onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                        <div>
                            <Typography variant='subtitle1'>Texto complementario:</Typography>
                            <Typography variant='subtitle2'
                                        dangerouslySetInnerHTML={{__html: this.state.texto_complementario || detalle.data && detalle.data.type_notification && detalle.data.type_notification.complementary_text}}/>
                        </div>
                        <div className="flex flex-wrap justify-end">
                            <Button
                                onClick={this.handleOpenDialoConfirmacion}
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                disabled={!this.state.isFormValid}
                                aria-label="CONTINUAR"
                                value="legacy"
                            >
                                GUARDAR
                            </Button>
                        </div>
                    </Formsy>
                </React.Fragment>
                }
                {detalle.data && (detalle.data.is_notified && detalle.data.status !== 'AUTORIZADA') &&
                <React.Fragment>
                    <br/>
                    <Typography variant='h6' style={{textAlign: 'center'}}>
                        Ya ha fué notificado
                    </Typography>
                </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        typeNotificationList: Actions.typeNotificationList,
        altaTypeNotification: Actions.altaTypeNotification
    }, dispatch);
}

function mapStateToProps({solicitude}) {
    return {
        lista: solicitude.list,
        detalle: solicitude.detalle_tarjeta_notificacion,
        list_type_notification: solicitude.list_type_notification,
        alta_type_notification: solicitude.alta_type_notification
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Notificacion)));