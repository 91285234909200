import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { withFormsy } from 'formsy-react';
import _ from '@lodash';

class TextUFormsy extends Component {

    normalize = (str) => {
        const from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÇç",
            to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuucc",
            mapping = {};

        for (let i = 0, j = from.length; i < j; i++)
            mapping[from.charAt(i)] = to.charAt(i);

        let ret = [];
        for (let i = 0, j = str.length; i < j; i++) {
            let c = str.charAt(i);
            if (mapping.hasOwnProperty(str.charAt(i)))
                ret.push(mapping[c]);
            else
                ret.push(c);
        }
        return ret.join('')
    }

    changeValue = (event) => {
        let value = this.normalize(event.currentTarget.value)
        // if (this.props.trim) {
        //     value = value.trim()
        // }
        // if (this.props.uppercase) {
        //     value = value.toUpperCase()
        // }
        // console.log("event", value.toUpperCase())
        this.props.setValue(value.toUpperCase());
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    render() {
        const importedProps = _.pick(this.props, [
            'autoComplete',
            'autoFocus',
            'children',
            'className',
            'defaultValue',
            'disabled',
            'helperText',
            'FormHelperTextProps',
            'fullWidth',
            'id',
            'InputLabelProps',
            'inputProps',
            'InputProps',
            'inputRef',
            'label',
            'multiline',
            'name',
            'onBlur',
            'onChange',
            'onFocus',
            'placeholder',
            'required',
            'rows',
            'rowsMax',
            'select',
            'SelectProps',
            'type',
            'variant',
            'trim',
            'uppercase',
            'validations',
            // 'validationErrors'
        ]);

        const errorMessage = this.props.getErrorMessage();
        const value = this.props.getValue() || '';

        return (
            <TextField
                {...importedProps}
                onChange={this.changeValue}
                value={value}
                error={Boolean(errorMessage)}
                helperText={errorMessage || this.props.helperText}
            />
        );
    }
}

export default withFormsy(TextUFormsy);
