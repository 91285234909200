import {fetchRefencePerson} from 'api'

export const SET_REFERENCE_PERSON = 'SET_REFERENCE_PERSON';
export const FETCH_REFERENCE_PERSON = 'FETCH_REFERENCE_PERSON';
export const FETCH_REFERENCE_PERSON_SUCCESS = 'FETCH_REFERENCE_PERSON_SUCCESS';
export const FETCH_REFERENCE_FAILURE = 'FETCH_REFERENCE_FAILURE';
export const CLEAN_REFERENCE_PERSON = 'CLEAN_REFERENCE_PERSON';


export const getData = () => {
    return {
        type: FETCH_REFERENCE_PERSON
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCH_REFERENCE_PERSON_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCH_REFERENCE_FAILURE,
        error
    }
}

export const set_reference_person = data => {
    return {
        type: SET_REFERENCE_PERSON,
        data: data
    }
}

export const clean_reference_person = () => {
    return {
        type: CLEAN_REFERENCE_PERSON
    }

}

export const referencesPerson = () => {
    return (dispatch) => {
        dispatch(getData())
        fetchRefencePerson()
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}