import { fetchChangeProgramBeneficiarie } from 'api'

export const FETCHING_CHANGE_PROGRAM = 'FETCHING_CHANGE_PROGRAM';
export const FETCHING_CHANGE_PROGRAM_SUCCESS = 'FETCHING_CHANGE_PROGRAM_SUCCESS';
export const FETCHING_CHANGE_PROGRAML_FAILURE = 'FETCHING_CHANGE_PROGRAML_FAILURE';


const getData = () => {
    return {
        type: FETCHING_CHANGE_PROGRAM
    }
}

const getDataSuccess = (data) => {
    return {
        type: FETCHING_CHANGE_PROGRAM_SUCCESS,
        data
    }
}

const getDataFailure = error => {
    return {
        type: FETCHING_CHANGE_PROGRAML_FAILURE,
        error
    }
}


export const changeProgram = (id_petition, data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchChangeProgramBeneficiarie(id_petition, data)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}