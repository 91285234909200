import * as Actions from '../../actions/solicitude/index';

const initialState = {
    id: 0,
    program: 0,
    beneficiaries: [],
    comment: '',
    canSubmit: false
}

const normalize = (str) => {
    var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuucc",
        mapping = {};

    for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
        var c = str.charAt(i);
        if (mapping.hasOwnProperty(str.charAt(i)))
            ret.push(mapping[c]);
        else
            ret.push(c);
    }
    return ret.join('').toUpperCase()
}

const form = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_SOLICITUDE_ID:
            return {
                ...state,
                id: action.id,
            }
        case Actions.SET_PROGRAM_FORM:
            return {
                ...state,
                program: action.data.id,
            }
        case Actions.RESET_SOLICITUDE_FORM:
            return {
                ...initialState,
                beneficiaries: [],
            }
        case Actions.RESET_FULL_FORM:
            state.beneficiaries[0] = { curp: action.curp, email: '', birth_date: '', first_name: '', last_name: '', m_last_name: '', social_name: '', gender_trans: '', gender_trans_value: '', phone_type_value: '', phone_type: null, relationship: 1, phone_number: '', ethnic_group: 1, address: { electoral_section_value: {}, colony: null, street: '', out_number: '', int_number: '' } }
            state.beneficiaries.splice(1, state.beneficiaries.length)
            return {
                ...state,
                comment: '',
            }
        case Actions.RESET_BENEFICIARY_FORM:
            state.beneficiaries[action.index] = { curp: '', email: '', birth_date: '', first_name: '', last_name: '', m_last_name: '', phone_type_value: '', phone_type: null, relationship: 1, phone_number: '', ethnic_group: 1, address: { electoral_section_value: {}, colony: null, street: '', out_number: '', int_number: '' } }
            return {
                ...state,
            }
        case Actions.RESET_BENEFICIARY_USER_TRANS_FORM:
            state.beneficiaries[action.index] = { curp: '', email: '', birth_date: '', first_name: '', last_name: '', m_last_name: '', social_name: '', gender_trans: '', gender_trans_value: '', phone_type_value: null, phone_type: null, relationship: 1, phone_number: '', ethnic_group: 1, address: { electoral_section_value: '', colony: null, street: '', out_number: '', int_number: '' } }
            return {
                ...state
            }
        case Actions.RESET_DEPENDANT_FORM:
            state.beneficiaries[action.index] = { curp: '', email: 'mail@mail.com', first_name: '', last_name: '', m_last_name: '', phone_type: 'movil', relationship_value: '', relationship: null, phone_number: '0000' }
            return {
                ...state
            }
        case Actions.RESET_DEPENDENT_ETHNIC_FORM:
            state.beneficiaries[action.index] = { curp: '', email: 'mail@mail.com', first_name: '', last_name: '', m_last_name: '', phone_type: 'movil', relationship_value: '', relationship: null, phone_number: '0000', ethnic_group: '', ethnic_group_value: '', }
            return {
                ...state
            }
        case Actions.ADD_BENEFICIARY_USER_FORM:
            state.beneficiaries.push({ curp: action.user.data.username, email: action.user.data.email, first_name: '', last_name: '', m_last_name: '', phone_type_value: '', phone_type: null, relationship: 1, phone_number: '', ethnic_group: 1, address: { electoral_section_value: '', colony: null, street: '', out_number: '', int_number: '' } })
            if (state.beneficiaries.length > action.data.min_beneficiary) {
                state.canSubmit = true
            }
            return {
                ...state
            }
        case Actions.ADD_BENEFICIARY_USER_TRANS_FORM:
            state.beneficiaries.push({ curp: action.user.data.username, email: action.user.data.email, first_name: '', last_name: '', m_last_name: '', social_name: '', gender_trans: '', gender_trans_value: '', phone_type_value: '', phone_type: null, relationship: 1, phone_number: '', ethnic_group: 1, address: { electoral_section_value: '', colony: null, street: '', out_number: '', int_number: '' } })
            if (state.beneficiaries.length > action.data.min_beneficiary) {
                state.canSubmit = true
            }
            return {
                ...state
            }
        case Actions.ADD_BENEFICIARY_TRANS_FORM:
            state.beneficiaries.push({ curp: '', email: '', first_name: '', last_name: '', m_last_name: '', social_name: '', gender_trans: '', gender_trans_value: '', phone_type_value: '', phone_type: null, relationship: 1, phone_number: '', ethnic_group: 1, address: { electoral_section_value: '', colony: null, street: '', out_number: '', int_number: '' } })
            if (state.beneficiaries.length > action.data.min_beneficiary) {
                state.canSubmit = true
            }
            return {
                ...state
            }
        case Actions.ADD_BENEFICIARY_FORM:
            state.beneficiaries.push({ curp: '', email: '', first_name: '', last_name: '', m_last_name: '', phone_type_value: '', phone_type: null, relationship: 1, phone_number: '', ethnic_group: 1, address: { electoral_section_value: '', colony: null, street: '', out_number: '', int_number: '' } })
            if (state.beneficiaries.length > action.data.min_beneficiary) {
                state.canSubmit = true
            }
            return {
                ...state
            }
        case Actions.ADD_DEPENDENT_FORM:
            state.beneficiaries.push({ curp: '', email: 'mail@mail.com', first_name: '', last_name: '', m_last_name: '', phone_type: 'movil', relationship_value: '', relationship: null, phone_number: '0000', ethnic_group: 1 })
            if (state.beneficiaries.length > action.data.min_beneficiary) {
                state.canSubmit = true
            }
            return {
                ...state
            }
        case Actions.ADD_DEPENDENT_ETHNIC_FORM:
            state.beneficiaries.push({ curp: '', email: 'mail@mail.com', first_name: '', last_name: '', m_last_name: '', phone_type: 'movil', relationship_value: '', relationship: null, phone_number: '0000', ethnic_group: 1 })
            if (state.beneficiaries.length > action.data.min_beneficiary) {
                state.canSubmit = true
            }
            return {
                ...state
            }
        case Actions.REMOVE_BENEFICIARY_FORM:
            state.beneficiaries.splice(action.index, 1)
            if (state.beneficiaries.length <= action.data.min_beneficiary) {
                state.canSubmit = false
            }
            return {
                ...state
            }
        case Actions.UPDATE_BENEFICIARY_TEXT_FORM:
            switch (action.target.name) {
                case 'id':
                    state.beneficiaries[action.index].id = action.target.value
                    return {
                        ...state
                    }
                case 'curp':
                    // if (action.target.value.length === 4) {
                    //     let name = action.target.value.substr(3, 1).toUpperCase()
                    //     state.beneficiaries[action.index].first_name = name
                    //     let last_name = action.target.value.substr(0, 1).toUpperCase()
                    //     state.beneficiaries[action.index].last_name = last_name
                    //     let m_last_name = action.target.value.substr(2, 1).toUpperCase()
                    //     state.beneficiaries[action.index].m_last_name = m_last_name
                    // }
                    const curp = action.target.value
                    if (curp.length >= 10) {
                        let year = parseInt(curp.substr(4, 2)) + 1900
                        const month = curp.substr(6, 2)
                        const day = curp.substr(8, 2)
                        if (year < 1920) {
                            year += 100
                        }
                        state.beneficiaries[action.index].birth_date = year + '-' + month + '-' + day
                    }
                    state.beneficiaries[action.index].curp = action.target.value

                    return {
                        ...state
                    }
                case 'birth_date':
                    state.beneficiaries[action.index].birth_date = action.target.value
                    return {
                        ...state
                    }
                case 'email':
                    state.beneficiaries[action.index].email = action.target.value
                    return {
                        ...state
                    }
                case 'first_name':
                    state.beneficiaries[action.index].first_name = normalize(action.target.value)
                    return {
                        ...state
                    }
                case 'last_name':
                    state.beneficiaries[action.index].last_name = normalize(action.target.value)
                    return {
                        ...state
                    }
                case 'm_last_name':
                    state.beneficiaries[action.index].m_last_name = normalize(action.target.value)
                    return {
                        ...state
                    }
                case 'phone_number':
                    state.beneficiaries[action.index].phone_number = action.target.value
                    return {
                        ...state
                    }
                case 'social_name':
                    state.beneficiaries[action.index].social_name = action.target.value
                    return {
                        ...state
                    }
                case 'address_id':
                    state.beneficiaries[action.index].address.id = action.target.value
                    return {
                        ...state
                    }
                case 'street':
                    state.beneficiaries[action.index].address.electoral_section_value = ''
                    state.beneficiaries[action.index].address.colony = null
                    state.beneficiaries[action.index].address.street = normalize(action.target.value)
                    return {
                        ...state
                    }
                case 'out_number':
                    state.beneficiaries[action.index].address.electoral_section_value = ''
                    state.beneficiaries[action.index].address.colony = null
                    state.beneficiaries[action.index].address.out_number = normalize(action.target.value)
                    return {
                        ...state
                    }
                case 'int_number':
                    state.beneficiaries[action.index].address.int_number = normalize(action.target.value)
                    return {
                        ...state
                    }
                default:
                    return state
            }
        case Actions.SET_BENEFICIARY_SELECT_FORM:
            state.beneficiaries[action.index].phone_type_value = action.value
            state.beneficiaries[action.index].phone_type = action.value ? action.value.value : null
            return {
                ...state
            }
        case Actions.SET_RELATION_SHIP_SELECT_FORM: {
            state.beneficiaries[action.index].relationship_value = action.value
            state.beneficiaries[action.index].relationship = action.value ? action.value.value : null
            return {
                ...state
            }
        }
        case Actions.SET_GENDER_TRANS_SELECT_FORM: {
            state.beneficiaries[action.index].gender_trans_value = action.value
            state.beneficiaries[action.index].gender_trans = action.value ? action.value.value : null
            return {
                ...state
            }
        }
        case Actions.SET_ELECTORAL_SECTION_SELECT_FORM: {
            state.beneficiaries[action.index].address.electoral_section_value = action.value
            state.beneficiaries[action.index].address.colony = action.value ? action.value.value : null
            return {
                ...state
            }
        }

        case Actions.SET_ETHNIC_SELECT_FORM: {
            state.beneficiaries[action.index].ethnic_group_value = action.value
            state.beneficiaries[action.index].ethnic_group = action.value ? action.value.value : null
            return {
                ...state
            }
        }
        case Actions.ADD_PEOPLE_CARE_FORM: {
            state.people_care = ''
            state.people_care_value = ''
            return {
                ...state
            }
        }
        case Actions.SET_PEOPLE_CARE_FORM: {
            state.people_care_value = action.value
            state.people_care = action.value ? action.value.value : ''
            return {
                ...state
            }
        }
        case Actions.SET_COMMENT_FORM: {
            return {
                ...state,
                comment: action.value
            }
        }
        default:
            return state
    }
}

export default form;