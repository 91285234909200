import * as Actions from '../../actions/users/index';

const initialState = {
    data: undefined,
    success: false,
    isFetching: false,
    error: false
}

const edit = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_EDIT_BENEFICIARY: {
            return {
                ...state,
                data: undefined,
                error: false,
                success: false,
                isFetching: true
            }
        }
        case Actions.FETCHING_EDIT_BENEFICIARY_SUCCESS: {
            return {
                ...state,
                data: action.data,
                success: true,
                error: false,
                isFetching: false
            }
        }
        case Actions.FETCHING_EDIT_BENEFICIARY_FAILURE: {
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }
        }
        default:
            return state
    }
}

export default edit;