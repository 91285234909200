import {fetchDetalleTarjetaNotificacion} from 'api'


export const FETCHING_DETALLE_TARJETA_NOTIFICACION = 'FETCHING_DETALLE_TARJETA_NOTIFICACION';
export const FETCHING_DETALLE_TARJETA_NOTIFICACION_SUCCESS = 'FETCHING_DETALLE_TARJETA_NOTIFICACION_SUCCESS';
export const FETCHING_DETALLE_TARJETA_NOTIFICACION_FAILURE = 'FETCHING_DETALLE_TARJETA_NOTIFICACION_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_DETALLE_TARJETA_NOTIFICACION
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_DETALLE_TARJETA_NOTIFICACION_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_DETALLE_TARJETA_NOTIFICACION_FAILURE,
        error
    }
}


export const detalleTarjetaNotificacion = (id_petition) => {
    return (dispatch) => {
        dispatch(getData())
        fetchDetalleTarjetaNotificacion(id_petition)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}