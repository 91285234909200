import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Typography, Divider} from '@material-ui/core'
import HeaderComponent from '../components/HeaderComponent';
import { FusePageCarded } from '@fuse';

const styles = theme => ({
    layoutRoot: {}
});

class Home extends Component {

    render() {
        const { classes } = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <HeaderComponent />
                }
                content={
                    <div className="p-24">
                        <div style={{ textAlign: 'center' }}>
                            <Typography variant="h4" style={{ color: '#616161' }}> <strong>Programas sociales de la Alcaldía Cuauhtémoc</strong></Typography>
                            <br />
                            <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 1 }} />
                        </div>
                        <br />
                        <h4></h4>
                    </div>
                }
            />
        )
    }
}

export default withStyles(styles, { withTheme: true })(Home);