import { fetchSendEmailPetition } from 'api'

export const FETCHING_SEND_EMAIL = 'SEND_EMAIL';
export const FETCHING_SEND_EMAIL_SUCCESS = 'FETCHING_SEND_EMAIL_SUCCESS';
export const FETCHING_SEND_EMAIL_FAILURE = 'FETCHING_SEND_EMAIL_FAILURE';

export const getData = () => {
    return {
        type: FETCHING_SEND_EMAIL
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_SEND_EMAIL_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_SEND_EMAIL_FAILURE,
        error
    }
}


export const sendEmailPetition = (id_petition, beneficiario) => {
    return (dispatch) => {
        dispatch(getData())
        fetchSendEmailPetition(id_petition, beneficiario)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}