import { fetchRelationShips } from 'api'

export const FETCHING_RELATION_SHIP_LIST = 'FETCHING_RELATION_SHIP_LIST';
export const FETCHING_RELATION_SHIP_LIST_SUCCESS = 'FETCHING_RELATION_SHIP_LIST_SUCCESS';
export const FETCHING_RELATION_SHIP_LIST_FAILURE = 'FETCHING_RELATION_SHIP_LIST_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_RELATION_SHIP_LIST
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_RELATION_SHIP_LIST_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_RELATION_SHIP_LIST_FAILURE,
        error
    }
}

export const relationShipList = () => {
    return (dispatch) => {
        dispatch(getData())
        fetchRelationShips()
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            }).catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}