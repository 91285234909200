import SolicitudeRegister from './SolicitudeRegister';
import {authRoles} from 'auth';

export const SolicitudeRegisterConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.user,
    routes  : [{
            path     : '/solicitud',
            component: SolicitudeRegister
        }
    ]
};