import * as Actions from '../../actions/solicitude/index';

const initialState = {
    data: [],
    comment: '',
    success: false,
    isFetching: false,
    error: false
}

const cancel = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_PETITION_CANCEL:
            return {
                ...state,
                data: [],
                success: false,
                error: false,
                isFetching: true
            }
        case Actions.FETCHING_PETITION_CANCEL_SUCCESS:
            return {
                ...state,
                data: action.data,
                success: true,
                error: false,
                isFetching: false
            }
        case Actions.FETCHING_PETITION_CANCEL_FAILURE:
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }
        case Actions.SET_COMMENT_CANCEL: {
            return {
                ...state,
                comment: action.value
            }
        }
        case Actions.CLEAN_COMMENT_CANCEL: {
            return {
                ...state,
                comment : ''
            }
        }
        default:
            return state
    }
}

export default cancel;