import * as Actions from '../../actions/index';

const initialState = {
    latitude: 19.419444,
    longitude: -99.145556,
    zoom: 14,
    success: false,
    isFetching: false,
    error: false
}

const geolocation = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_GEOLOCATION: {
            return {
                ...state,
                success: false,
                isFetching: true
            }
        }
        case Actions.RESET_GEOLOCATION: {
            return initialState
        }
        case Actions.FETCHING_GEOLOCATION_SUCCESS: {
            return {
                ...state,
                latitude: action.data.latitude,
                longitude: action.data.longitude,
                zoom:16,
                success: true,
                isFetching: false
            }
        }
        case Actions.FETCHING_GEOLOCATION_FAILURE: {
            return {
                ...state,
                error: true,
                success: false,
                isFetching: false
            }
        }
        default: {
            return state
        }
    }
}

export default geolocation;