import {fethPetitionAttachDetail} from 'api'
import {SET_COMMENT_SOLICITUDE} from "./solicitude.actions";

export const FETCHING_PETITION_DETAIL = 'FETCHING_PETITION_DETAIL';
export const FETCHING_PETITION_DETAIL_SUCCESS = 'FETCHING_PETITION_DETAIL_SUCCESS';
export const FETCHING_PETITION_DETAIL_FAILURE = 'FETCHING_PETITION_DETAIL_FAILURE';
export const GET_STATUS = 'GET_STATUS';
export const GET_CODE_STATUS = 'GET_CODE_STATUS';
export const GET_FOLIO = 'GET_FOLIO';
export const GET_IS_NOTIFIED = 'GET_IS_NOTIFIED';
export const SET_NAME = 'SET_NAME';
export const SET_FORM = 'SET_FORM';
export const GET_NAME = 'GET_NAME';


export const getData = () => {
    return {
        type: FETCHING_PETITION_DETAIL
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_PETITION_DETAIL_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_DETAIL_FAILURE,
        error
    }
}

export const getStatus = status => {
    return {
        type: GET_STATUS,
        status
    }
}

export const getCodeStatus = code => {
    return {
        type: GET_CODE_STATUS,
        code
    }
}

export const get_folio_authorize = folio => {
    return {
        type: GET_FOLIO,
        folio
    }
}

export const get_is_notified = is_notified => {
    return {
        type: GET_IS_NOTIFIED,
        is_notified
    }
}

export const set_name = e => {
    return {
        type: SET_NAME,
        name: e.target.name,
        value: e.target.value
    }
}

export const set_form = e => {
    return {
        type: SET_FORM,
        name: e.target.name,
        value: e.target.value
    }
}

export const getName = name => {
    return {
        type: GET_NAME,
        name
    }
}

export const petitionAttachDetail = (id_petition) => {
    return (dispatch) => {
        dispatch(getData())
        fethPetitionAttachDetail(id_petition)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                    dispatch(getStatus(data.data.status.label))
                    dispatch(getCodeStatus(data.data.code))
                    dispatch(get_folio_authorize(data.data.approved_folio))
                    dispatch(get_is_notified(data.data.is_notified))
                    dispatch(getName(data.data.full_name))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}