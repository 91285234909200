import {fetchPetitionList} from 'api'

export const FETCHING_PETITION_LIST = 'FETCHING_PETITION_LIST';
export const FETCHING_PETITION_LIST_SUCCESS = 'FETCHING_PETITION_LIST_SUCCESS';
export const FETCHING_PETITION_LIST_FAILURE = 'FETCHING_PETITION_LIST_FAILURE';
export const SELECT_ID_SOLICITUD = 'SELECT_ID_SOLICITUD';


export const getData = () => {
    return {
        type: FETCHING_PETITION_LIST
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_PETITION_LIST_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_LIST_FAILURE,
        error
    }
}

export const selectIdSolicitud = id_solicitud => {
    return {
        type: SELECT_ID_SOLICITUD,
        id_solicitud
    }
}

export const petitionList = (state, search) => {
    return (dispatch) => {
        dispatch(getData())
        fetchPetitionList(state, search).then(data => {
            if (data.data) {
                let dat = data.data
                const pages = dat.count / state.pageSize
                dat.pages = Math.ceil(pages)
                dispatch(getDataSuccess(dat))
            }
        }).catch(error => {
            // dispatch(getDataFailure(error.response))

            // dispatch(getDataFailure(error.response.data))

            dispatch(getDataFailure(error.response ? error.response.data : {}))

        })
    }
}