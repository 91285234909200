import {setDefaultSettings} from 'store/actions/fuse';
import {submitLogout} from '../actions';
import {FuseDefaultSettings} from '@fuse';
import _ from '@lodash';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';

export function setUserData(user){
    return (dispatch) => {
        dispatch({
            type   : SET_USER_DATA,
            data: user
        })
    }
}

export function updateUserSettings(settings){
    return (dispatch, getState) => {
        const oldUser = getState().auth.user;
        const user = _.merge({}, oldUser, {data: {settings}});
        updateUserData(user);
        return dispatch(setUserData(user));
    }
}

export function updateUserShortcuts(shortcuts){
    return (dispatch, getState) => {
        const user = getState().auth.user;
        const newUser = {
            ...user,
            data: {
                ...user.data,
                shortcuts
            }
        }
        updateUserData(newUser);
        return dispatch(setUserData(newUser));
    }
}

export function removeUserData(){
    return {
        type: REMOVE_USER_DATA
    }
}

export function logoutUser(){
    return (dispatch, getState) => {
        dispatch(setDefaultSettings(FuseDefaultSettings));
        dispatch(submitLogout());
        localStorage.removeItem("@profile")
    }
}

export function userLogout(user){
    return (dispatch) => {
        dispatch({
            type: USER_LOGGED_OUT
        })
    }
}

function updateUserData(user){
    if ( user.role === 'guest' ){
        return;
    }
}
