import * as Actions from '../../actions/users/index';

const initialState = {
    data: undefined,
    success: false,
    isFetching: false,
    error: false
}

const register = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_SAVE_REGISTER:
            return {
                ...state,
                data: undefined,
                error: false,
                success: false,
                isFetching: true
            }
        case Actions.FETCHING_SAVE_REGISTER_SUCCESS:
            return {
                ...state,
                data: action.data,
                success: true,
                isFetching: false
            }
        case Actions.FETCHING_SAVE_REGISTER_FAILURE:
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }
        case Actions.RESET_REGISTER:
            return initialState
        default:
            return state
    }
}

export default register;