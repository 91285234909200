import { fetchApprovalPetition } from 'api'

export const FETCHING_PETITION_APPROVAL = 'FETCHING_PETITION_APPROVAL';
export const FETCHING_PETITION_APPROVAL_SUCCESS = 'FETCHING_PETITION_APPROVAL_SUCCESS';
export const FETCHING_PETITION_APPROVAL_FAILURE = 'FETCHING_PETITION_APPROVAL_FAILURE';
export const SET_COMMENT_APPROVAL = 'SET_COMMENT_APPROVAL';
export const CLEAN_COMMENT_APPROVAL = 'CLEAN_COMMENT_APPROVAL';

export const getData = () => {
    return {
        type: FETCHING_PETITION_APPROVAL
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_PETITION_APPROVAL_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_APPROVAL_FAILURE,
        error
    }
}

export const set_comment_approval = e => {
    return {
        type: SET_COMMENT_APPROVAL,
        value: e.target.value
    }
}

export const clean_comment_approval = () => {
    return {
        type: CLEAN_COMMENT_APPROVAL
    }
}

export const approvalPetition = (id_petition, comment) => {
    return (dispatch) => {
        dispatch(getData())
        fetchApprovalPetition(id_petition, comment)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}