import {fetchAltaTypeNotification} from 'api';

export const FETCHING_ALTA_TYPE_NOTIFICATION = 'FETCHING_ALTA_TYPE_NOTIFICATION';
export const FETCHING_ALTA_TYPE_NOTIFICATION_SUCCESS = 'FETCHING_ALTA_TYPE_NOTIFICATION_SUCCESS';
export const FETCHING_ALTA_TYPE_NOTIFICATION_FAILURE = 'FETCHING_ALTA_TYPE_NOTIFICATION_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_ALTA_TYPE_NOTIFICATION
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_ALTA_TYPE_NOTIFICATION_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_ALTA_TYPE_NOTIFICATION_FAILURE,
        error
    }
}


export const altaTypeNotification = (id_petition, data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchAltaTypeNotification(id_petition, data)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            }).catch(error => {
            dispatch(getDataFailure(error.response ? error.response.data : {}))

        })
    }
}