import * as Actions from '../../actions/index';

const initialState = {
    data: undefined,
    detail: undefined,
    saved: undefined,
    name_program: '',
    selected: false
}

const choice = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_PROGRAM_CHOICE: {
            return {
                ...state,
                name_program: action.value,
                data: action.data,
                selected: true
            }
        }
        case Actions.SET_BENEFICIARIES_DATA: {
            return {
                ...state,
                saved: action.data,
            }
        }
        case Actions.CLEAN_PROGRAM_CHOICE: {
            return {
                ...initialState,
                data: undefined,
                saved: undefined,
            }
        }
        default: {
            return state
        }
    }
}

export default choice;