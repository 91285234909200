import React, {Component} from 'react'
import Formsy from 'formsy-react';
import {withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles/index';
import {Typography, Icon, Button, InputAdornment, Divider} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import * as Actions from 'auth/store/actions';
import {FuseAnimate, TextFieldFormsy} from '@fuse';
import connect from 'react-redux/es/connect/connect';

const styles = theme => ({
    button: {
        marginLeft: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});

class RecoveryForm extends Component {
    state = {
        canSubmit: false
    };

    form = React.createRef();

    disableButton = () => {
        this.setState({canSubmit: false});
    }

    enableButton = () => {
        this.setState({canSubmit: true});
    }

    onSubmit = (model) => {
        this.disableButton();
        // this.props.submitLogin(model);
    }

    onBack = () => {
        this.props.history.goBack();
    }

    componentDidMount(){
        const token = localStorage.getItem('@token')
        if(token){
            this.props.reacceso();
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.login.success && nextProps.login.success !== this.props.login.success) {
            // const {user, survey} = this.props;
            // let pathname = '/';
            // if (survey.id){
            //     pathname = '/survey'
            // }
            // if(user.role === 'staff'){
            //     pathname = '/home';
            // } else if (user.role === 'module'){
            //     pathname = '/pollchooser';
            // }
            // this.props.history.push({
            //     pathname
            // });
        }
        if (nextProps.login.error && nextProps.login.error !== this.props.login.error) {
            this.enableButton();
        }
    }

    render(){
        const {classes} = this.props;
        const {canSubmit} = this.state;

        return (
            <div className="flex-1">
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h4" style={{ color: '#616161' }}> <strong>Programas sociales de la Alcaldía Cuauhtémoc</strong></Typography>
                    <br />
                    <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 3 }} />
                </div>
                <br />
                <FuseAnimate animation={{translateX: [0, '100%']}}>
                    <div className="m-auto max-w-512">
                        <Typography variant="h6" className="text-center md:w-full mb-24">RECUPERAR MI CUENTA</Typography>
                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            ref={(form) => this.form = form}
                            className="flex flex-col justify-center w-full"
                        >
                            <TextFieldFormsy
                                className="mb-16"
                                type="text"
                                name="username"
                                label="Usuario"
                                validations={{
                                    maxLength: 20,
                                    minLength: 4
                                }}
                                validationErrors={{
                                    maxLength: 'La longitud máxima de carácteres es 20',
                                    minLength: 'La longitud mínima de carácteres es 4'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">person_outline</Icon></InputAdornment>
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="mb-16"
                                type="text"
                                name="email"
                                label="Correo electrónico"
                                validations={{
                                    minLength: 4
                                }}
                                validationErrors={{
                                    maxLength: 'La longitud máxima de carácteres es 20',
                                    minLength: 'La longitud mínima de carácteres es 4'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">email</Icon></InputAdornment>
                                }}
                                variant="outlined"
                            />
                            <div className="flex flex-wrap justify-end">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.onBack}
                                    className={classes.button}
                                    aria-label="ATRAS"
                                    value="legacy"
                                >
                                    ATRAS
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    aria-label="ENTRAR"
                                    disabled={!canSubmit}
                                    value="legacy"
                                >
                                    ENVIAR
                                </Button>
                            </div>
                        </Formsy>
                    </div>
                </FuseAnimate>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        submitLogin: Actions.submitLogin,
        reacceso: Actions.reacceso
    }, dispatch);
}

function mapStateToProps({auth})
{
    return {
        login: auth.login,
        user : auth.user
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(RecoveryForm)));
