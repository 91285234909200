import * as Actions from '../../actions/solicitude/index';

const initialState = {
	data : {},
	success: false,
	isFetching : false,
	error : false
}

const solicitude_detail = (state=initialState, action) => {
	switch (action.type){
		case Actions.FETCHING_SOLICITUDE_DETAIL:
			return {
				...state,
				data: {},
                success: false,
				isFetching: true
			}
		case Actions.FETCHING_SOLICITUDE_DETAIL_SUCCESS:
			return {
				...state,
				data: action.data,
                success: true,
				isFetching: false
			}
		case Actions.FETCHING_SOLICITUDE_DETAIL_FAILURE:
			return {
                ...state,
                data : action.error,
				error: true,
                success: false,
				isFetching: false
			}
		default:
			return state
	} 
}

export default solicitude_detail;