import { fetchValidateAttachment } from 'api'

export const FETCHING_VALIDATE_ATTACH = 'FETCHING_VALIDATE_ATTACH';
export const FETCHING_VALIDATE_ATTACH_SUCCESS = 'FETCHING_VALIDATE_ATTACH_SUCCESS';
export const FETCHING_VALIDATE_ATTACH_FAILURE = 'FETCHING_VALIDATE_ATTACH_FAILURE';
export const GET_FIlE = 'GET_FIlE';
export const GET_ID_VALID = 'GET_ID_VALID';


export const getData = () => {
    return {
        type: FETCHING_VALIDATE_ATTACH
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_VALIDATE_ATTACH_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_VALIDATE_ATTACH_FAILURE,
        error
    }
}

export const getFile = (file) => {

    return {
        type: GET_FIlE,
        file
    }
}

export const getIdValid = (id, is_valid) => {
    return {
        type: GET_ID_VALID,
        name: id,
        value: is_valid
    }
}


export const validateAttachment = (id, data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchValidateAttachment(id, data)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                    dispatch(getFile(data.data.file))
                    
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}