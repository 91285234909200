import { fetchUpdateProgramRolUser } from 'api'

export const FETCHING_UPDATE_PROGRAM_ROLE = 'FETCHING_UPDATE_PROGRAM_ROLE';
export const FETCHING_UPDATE_PROGRAM_ROLE_SUCCESS = 'FETCHING_UPDATE_PROGRAM_ROLE_SUCCESS';
export const FETCHING_UPDATE_PROGRAM_ROLE_FAILURE = 'FETCHING_UPDATE_PROGRAM_ROLE_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_UPDATE_PROGRAM_ROLE
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_UPDATE_PROGRAM_ROLE_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_UPDATE_PROGRAM_ROLE_FAILURE,
        error
    }
}

export const updateProgramRoleUser = (id_user, data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchUpdateProgramRolUser(id_user, data)
            .then(data => {
                dispatch(getDataSuccess(data.data))
            })
            .catch(error => {
                dispatch(getDataFailure(error.response))
            })
    }
}