import { fetchPetitionDetail } from 'api'

export const FETCHING_SOLICITUDE_DETAIL = 'FETCHING_SOLICITUDE_DETAIL';
export const FETCHING_SOLICITUDE_DETAIL_SUCCESS = 'FETCHING_SOLICITUDE_DETAIL_SUCCESS';
export const FETCHING_SOLICITUDE_DETAIL_FAILURE = 'FETCHING_SOLICITUDE_DETAIL_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_SOLICITUDE_DETAIL
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_SOLICITUDE_DETAIL_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_SOLICITUDE_DETAIL_FAILURE,
        error
    }
}

export const getPetitionDetail = (id) => {
    return (dispatch) => {
        dispatch(getData())
        fetchPetitionDetail(id).then(data => {
            dispatch(getDataSuccess(data.data))
        }).catch(error => {
            dispatch(getDataFailure(error.response ? error.response.data : {}))
        })
    }
}