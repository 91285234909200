import { fetchRegisterUser } from 'api'

export const FETCHING_SAVE_REGISTER = 'FETCHING_SAVE_REGISTER';
export const FETCHING_SAVE_REGISTER_SUCCESS = 'FETCHING_SAVE_REGISTER_SUCCESS';
export const FETCHING_SAVE_REGISTER_FAILURE = 'FETCHING_SAVE_REGISTER_FAILURE';
export const RESET_REGISTER = 'RESET_REGISTER';


export const getData = () => {
    return {
        type: FETCHING_SAVE_REGISTER
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_SAVE_REGISTER_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_SAVE_REGISTER_FAILURE,
        error
    }
}

export const saveUser = data => {
    return (dispatch) => {
        dispatch(getData())
        fetchRegisterUser(data)
            .then(data => {
                dispatch(getDataSuccess(data.data))
            })
            .catch(error => {
                dispatch(getDataFailure(error.response))
            })
    }
}

export function reset_form_register() {
    return {
        type: RESET_REGISTER
    }
}