export const SET_VALIDATE_CHECK = 'SET_VALIDATE_CHECK';
export const CLEAN_CHECK_ITEM = 'CLEAN_CHECK_ITEM';


export const set_validate_check = (name, e) => {
    return {
        type: SET_VALIDATE_CHECK,
        value: e,
        name: name
    }
}

export const clean_check_item = () => {
    return {
        type: CLEAN_CHECK_ITEM
    }
}