import { fetchPetitionRead } from 'api'

export const FETCHING_PETITION_READ = 'FETCHING_PETITION_READ';
export const FETCHING_PETITION_READ_SUCCESS = 'FETCHING_PETITION_READ_SUCCESS';
export const FETCHING_PETITION_READ_FAILURE = 'FETCHING_PETITION_READ_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_PETITION_READ
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_PETITION_READ_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_READ_FAILURE,
        error
    }
}

export const petitionRead = (id, data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchPetitionRead(id, data)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}