import {fetchAsignarTarjeta} from 'api'

export const FETCHING_ASIGNAR_TARJETA = 'FETCHING_ASIGNAR_TARJETA';
export const FETCHING_ASIGNAR_TARJETA_SUCCESS = 'FETCHING_ASIGNAR_TARJETA_SUCCESS';
export const FETCHING_ASIGNAR_TARJETA_FAILURE = 'FETCHING_ASIGNAR_TARJETA_FAILURE';

export const getData = () => {
    return {
        type: FETCHING_ASIGNAR_TARJETA
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_ASIGNAR_TARJETA_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_ASIGNAR_TARJETA_FAILURE,
        error
    }
}


export const asignarTarjeta = (id_petition, data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchAsignarTarjeta(id_petition, data)
            .then(data => {
                console.log("dataaaaa", data)
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                console.log("eroorrrrrr", error.response)
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}