import {fetchTipoNotificacion} from 'api';

export const FETCHING_TYPE_NOTIFICATION = 'FETCHING_TYPE_NOTIFICATION';
export const FETCHING_TYPE_NOTIFICATION_SUCCESS = 'FETCHING_TYPE_NOTIFICATION_SUCCESS';
export const FETCHING_TYPE_NOTIFICATION_FAILURE = 'FETCHING_TYPE_NOTIFICATION_FAILURE';
export const SELECT_ID_TYPE_NOTIFICATION = 'SELECT_ID_TYPE_NOTIFICATION';


export const getData = () => {
    return {
        type: FETCHING_TYPE_NOTIFICATION
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_TYPE_NOTIFICATION_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_TYPE_NOTIFICATION_FAILURE,
        error
    }
}

export const selectIdTypeNotification = id => {
    return {
        type: SELECT_ID_TYPE_NOTIFICATION,
        id
    }
}

export const typeNotificationList = () => {
    return (dispatch) => {
        dispatch(getData())
        fetchTipoNotificacion()
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            }).catch(error => {
            dispatch(getDataFailure(error.response ? error.response.data : {}))

        })
    }
}