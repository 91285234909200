import * as Actions from '../../actions/solicitude/index';

const initialState = {
    data: {},
    success: false,
    isFetching: false,
    error: false
}

const bulkload = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_BULK_LOAD:
            return {
                ...state,
                isFetching: true,
                error: false,
                success: false,
            }
        case Actions.FETCHING_BULK_LOAD_SUCCESS:
            return {
                ...state,
                data: action.data,
                success: true,
                isFetching: false,
                error: false
            }
        case Actions.FETCHING_BULK_LOAD_FAILURE:
            return {
                ...state,
                data: action.error,
                error: true,
                isFetching: false,
                success: false,
            }
        default:
            return state
    }
}

export default bulkload;