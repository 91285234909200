import * as Actions from '../actions';

const initialState = {
    role: 'guest',
    success: false,
    data: {}
};

const user = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.SET_USER_DATA:
        {
            return {
                ...initialState,
                role: action.data.role,
                data: action.data
            };
        }
        case Actions.REMOVE_USER_DATA:
        {
            return {
                ...initialState
            };
        }
        case Actions.USER_LOGGED_OUT:
        {
            return {
                ...initialState,
                success: true
            };
        }
        default:
        {
            return state
        }
    }
};

export default user;
