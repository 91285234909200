import {authRoles} from 'auth';

export const fuseNavigationConfig = [{
        'id': 'system',
        'title': 'Sistema',
        'type': 'group',
        'icon': 'list',
        'children': [{
            'id': 'home',
            'title': 'Inicio',
            'type': 'item',
            'icon': 'home',
            'url': '/home'
        },{
            'id': 'programas',
            'title': 'Nueva solicitud',
            'type': 'item',
            'icon': 'add_circle',
            'url': '/programas',
            'auth': authRoles.user ? authRoles.user : ''
        },{
            'id': 'solicitudes',
            'title': 'Lista de Solicitudes',
            'type': 'item',
            'icon': 'description',
            'url': '/solicitudes'
        },{
            'id': 'usuarios',
            'title': 'Administración de Usuarios',
            'type': 'item',
            'icon': 'supervisor_account',
            'url': '/usuarios',
            auth   : authRoles.superadmin,
        },{
            'id': 'bulkload',
            'title': 'Autorización Masiva',
            'type': 'item',
            'icon': 'note',
            'url': '/bulk-load',
            auth   : authRoles.superadmin,
        },{
            'id': 'reportes',
            'title': 'Reportes',
            'type': 'item',
            'icon': 'folder_open',
            'url': '/reportes',
            auth   : authRoles.superadmin,
    }]
}];
