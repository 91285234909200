import { fetchCurpSearch } from 'api'

export const FETCHING_CURP_SEARCH = 'FETCHING_CURP_SEARCH';
export const FETCHING_CURP_SEARCH_LIST_SUCCESS = 'FETCHING_CURP_SEARCH_LIST_SUCCESS';
export const FETCHING_CURP_SEARCH_LIST_FAILURE = 'FETCHING_CURP_SEARCH_LIST_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_CURP_SEARCH
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_CURP_SEARCH_LIST_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_CURP_SEARCH_LIST_FAILURE,
        error
    }
}

export const curpSearch = (curp) => {
    return (dispatch) => {
        dispatch(getData())
        fetchCurpSearch(curp).then(data => {
            dispatch(getDataSuccess(data.data))
        }).catch(error => {
            dispatch(getDataFailure(error.response ? error.response.data : {}))
        })
    }
}