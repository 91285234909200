import React, { Component } from 'react';
import { Typography, Icon, Button, withStyles } from '@material-ui/core';
import { fetchAdjunto, fetchUploadAttachment, fetchDocuments } from 'api'
import Config from '../../../config';
import Autocomplete from './Autocomplete'
import FacebookLoading from './FacebookLoading'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import { connect } from 'react-redux';
import classNames from 'classnames';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: 'none',
    },
    selectField: {
        marginBottom: theme.spacing.unit,
    },
});

class UploadComponent extends Component {
    state = {
        options: [],
        canSubmit: false,
        document_value: '',
        document: '',
        file: '',
        fileName: '',
        fetching: false,
        success: false,
        error: false,
        error_extension: false,
        data: {}
    }

    componentDidMount() {
        const { beneficiary, docuemnt_type } = this.props;
        fetchDocuments(docuemnt_type.document_type.id).then(resp => {
            if (resp.data.length === 1) {
                this.setDocument(resp.data[0])
            }
            this.setState({ options: resp.data })
            fetchAdjunto(beneficiary.id, docuemnt_type.document_type.id).then(attachment => {
                if (attachment.data.found){
                    if (docuemnt_type.is_required){
                        this.props.addUploadCompleted()
                    }
                    this.setDocument({label: attachment.data.data.document_label, value: attachment.data.data.document})
                    this.setState({data:attachment.data, file: attachment.data.data.file.slice(1), fileName: attachment.data.data.file.split("/").pop()})
                }
            })
        }).catch(error => {
            fetchDocuments(docuemnt_type.document_type.id).then(resp => {
                if (resp.data.length === 1) {
                    this.setDocument(resp.data[0])
                }
                this.setState({ options: resp.data })
                fetchAdjunto(beneficiary.id, docuemnt_type.document_type.id).then(attachment => {
                    if (attachment.data.found){
                        if (docuemnt_type.is_required){
                            this.props.addUploadCompleted()
                        }
                        this.setDocument({label: attachment.data.data.document_label, value: attachment.data.data.document})
                        this.setState({data:attachment.data, file: attachment.data.data.file.slice(1), fileName: attachment.data.data.file.split("/").pop()})
                    }
                })
            })
        })
        this.normalize = (function () {
            var from = " ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
                to = "_AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
                mapping = {};

            for (var i = 0, j = from.length; i < j; i++)
                mapping[from.charAt(i)] = to.charAt(i);

            return function (str) {
                var ret = [];
                for (var i = 0, j = str.length; i < j; i++) {
                    var c = str.charAt(i);
                    if (mapping.hasOwnProperty(str.charAt(i)))
                        ret.push(mapping[c]);
                    else
                        ret.push(c);
                }
                return ret.join('');
            }
        })();
    }

    loadAttachment = (e) => {
        e.preventDefault();
        const { docuemnt_type, beneficiary } = this.props;
        const { data, document } = this.state;
        const fileUpload = this.sendFile.files[0]
        if (fileUpload) {
            if (fileUpload.type === 'image/png' || fileUpload.type === 'image/jpeg' || fileUpload.type === 'application/pdf') {

                let form = new FormData();
                form.append('beneficiary', beneficiary.id);
                form.append('document', document);
                let name = this.normalize(fileUpload.name)
                name = name.replace(/[^a-zA-Z0-9_.]/g, '')
                const arreglo = name.split('.')
                const filename = arreglo[0]
                if (filename.length > 40) {
                    const extension = arreglo[1]
                    name = filename.substring(0, 40) + '.' + extension
                }
                const file = new File([fileUpload], name, { type: fileUpload.type });
                form.append('file', file);
                this.setState({ fileName: file.name, fetching: true, success: false, error: false })
                fetchUploadAttachment(form, data.id).then(resp => {
                    if (docuemnt_type.is_required){
                        this.props.addUploadCompleted()
                    }
                    this.setState({ fetching: false, data: resp.data, success: true })
                }).catch(error => {
                    this.setState({ fetching: false, data: {}, error: true })
                }).finally(() => {
                    this.setState({ fetching: false })
                })

            }else{
                this.setState({
                    error: true,
                    error_extension: true
                })
            }

        }
    }

    setDocument = (e) => {
        this.setState({ document_value: e, document: e ? e.value : 'undefined', canSubmit: e ? true : false })
    }

    render() {
        const { classes, docuemnt_type, beneficiary } = this.props;
        const { options, canSubmit, fileName, fetching, success, document_value, error, file } = this.state;
        return (
            <div className={classNames(classes.root, "flex-row border-1 border-solid border-black p-12 m-6")}>
                <div className="flex-1">
                    <Typography className="text-18 text-center mb-12">{docuemnt_type.document_type.name}</Typography>
                    <Autocomplete
                        name="document"
                        placeholder='Documento'
                        options={options}
                        className={classes.selectField}
                        value={document_value}
                        onChange={(e) => { this.setDocument(e) }}
                        isClearable
                    />
                    {/* <Typography className="text-12 text-center mb-12">{fileName}</Typography> */}
                    <a href={Config.client_url + file} target='_blank' rel="noopener noreferrer">
                        <Typography className="text-16 text-center mb-12">{fileName}</Typography>
                    </a>
                </div>
                <div className="flex justify-between">
                    {error && <Icon className="text-32" color="error">error</Icon>}
                    {error && <Typography variant='subtitle2' color='error'>{this.state.error_extension ? 'Archivo no válido.' : 'Error al cargar archivo'}</Typography>}
                    {success && <Icon className="text-32" color="primary">check_circle</Icon>}
                    {fetching && <FacebookLoading />}
                    <input
                        accept="application/pdf, image/*"
                        className={classes.input}
                        id={beneficiary.id + '' + docuemnt_type.type_beneficiary + '' + docuemnt_type.document_type.id}
                        multiple
                        type="file"
                        disabled={!canSubmit}
                        ref={(input) => { this.sendFile = input }}
                        onChange={(e) => { this.loadAttachment(e) }}
                    />
                    <label htmlFor={beneficiary.id + '' + docuemnt_type.type_beneficiary + '' + docuemnt_type.document_type.id}>
                        <Button variant="outlined" disabled={!canSubmit} component="span">
                            Buscar
                        </Button>
                    </label>
                </div>
                {docuemnt_type.is_required === true ? (<Typography style={{fontSize:11}} color='primary'>* Obligatorio</Typography>):(<span></span>)}
                <br/>
                <span>{docuemnt_type.comments}</span>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addUploadCompleted: Actions.addUploadCompleted
    }, dispatch);
}

function mapStateToProps({ solicitude }) {
    return {
        upload: solicitude.upload
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadComponent)));