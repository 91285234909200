import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles/index';
import PropTypes from 'prop-types';
import {
    Typography,
    Icon,
    Button,
    Divider,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Switch,
    FormControlLabel,
    FormControl,
    Tooltip,
    Snackbar
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as Actions from 'store/actions';
import { FuseAnimate } from '@fuse';
import connect from 'react-redux/es/connect/connect';
import Paper from '@material-ui/core/Paper';
import { Print, Attachment, EditOutlined } from '@material-ui/icons';
import FacebookLoading from '../../components/FacebookLoading'
import moment from 'moment';
import 'moment/locale/es';
import Config from '../../../../config';
import * as ActionsUser from 'auth/store/actions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import UploadComponent from '../../components/UploadDetailComponet';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Autocomplete from '../../components/Autocomplete';
import { TextFieldFormsy } from '@fuse';
import Formsy from 'formsy-react';
import TextUFormsy from '../../components/TextUFormsy'
import Select2 from "../../components/Select2";
import DatePickerSy from "../../components/DatePicker";

import GoogleMap from 'google-map-react';

function Marker({ text }) {
    return (
        <Tooltip title={text} placement="top">
            <Icon className="text-red">location_on</Icon>
        </Tooltip>
    );
}

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    color_icon: {
        color: "#F29100"
    },
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    list_docx: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    mapField: {
        flewGrow: 1,
        height: '250px',
    },

    iOSSwitchBase: {
        '&$iOSChecked': {
            color: theme.palette.common.white,
            '& + $iOSBar': {
                backgroundColor: '#52d869'
            }
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp
        })
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none'
        }
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
    },
    iOSIcon: {
        width: 24,
        height: 24
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1]
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        marginLeft: theme.spacing.unit
    },

    menu: {
        width: 500,
    },
    input: {
        display: 'none',
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    table: {
        minWidth: 700,
    },
    selectField: {
        flexGrow: 1,
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});


const suggestions = [
    {
        value: 'temporal',
        label: 'Suspensión temporal'
    },
    {
        value: 'definitiva',
        label: 'Baja'
    }
]

const options_baja = [
    { value: 1, label: 'Incumplimiento de requisitos' },
    { value: 2, label: 'Fallecimiento' },
    { value: 3, label: 'No localizado' },
]

class SolicitudeDetailView extends Component {
    state = {
        checked: [0],
        open_cancel: false,
        open_approval: false,
        open_authorize: false,
        open_unsubscribe: false,
        open_email: false,
        open_map: false,
        open_revision: false,
        estatus_text: '',
        disable_cancel_btn: false,
        disable_approve_btn: false,
        disable_authorize_btn: false,
        disable_unsubscribe_btn: false,
        disable_solicitude_btn: false,
        checkedB: false,
        open_snack: false,
        msj_snack: '',
        vertical: 'top',
        horizontal: 'right',
        edit_person: false,
        open_solicitud: false,
        open_msj: false,
        open_edit: false,
        mensaje: '',
        isFormValid: false,
        isFormValidEdit: false,
        tipo_baja: undefined,
        programValue: null
    }

    disableButton = () => {
        this.setState({
            isFormValid: false
        })
    }

    enableButton = () => {
        this.setState({
            isFormValid: true
        })
    }

    componentWillUpdate(nextProps, nextState) {
        // console.log("detail",nextProps.detail.success)
        if (nextProps.detail.error && nextProps.detail.error !== this.props.detail.error) {
            let pathname = '/error';
            this.props.history.replace({
                pathname
            });
        }
        if (nextProps.detail.success && nextProps.detail.success !== this.props.detail.success) {

            var e = undefined;
            nextProps.detail.data.data_direct.documents && nextProps.detail.data.data_direct[0].documents.map(direct => {
                if (direct.files_direc.length !== 0) {
                    var e = {
                        target: {
                            checked: direct.files_direc[0].is_valid
                        }

                    }
                    this.props.set_validate_check(direct.files_direc[0].id_file, e)
                }

            })

            nextProps.detail.data.data_indirect.documents && nextProps.detail.data.data_indirect[0].documents.map(indirect => {
                if (indirect.files_indirec.length !== 0) {
                    var e = {
                        target: {
                            checked: indirect.files_indirec[0].is_valid
                        }

                    }
                    this.props.set_validate_check(indirect.files_indirec[0].id_file, e)
                }

            })
        }


        if (nextProps.cancel.success && nextProps.cancel.success !== this.props.cancel.success) {
            this.handleCloseDialogCancel()
            this.props.getStatus(nextProps.cancel.data.status)
            this.handleOpenDialogMsj("Se ha realizado la operación exitosamente")

            if (this.props.user.role === 'admin') {
                this.setState({
                    disable_approve_btn: false
                })
                this.props.getCodeStatus(nextProps.cancel.data.code)
            } else {
                this.setState({
                    disable_cancel_btn: true
                })
                this.props.getCodeStatus(nextProps.cancel.data.code)
            }

        }
        if (nextProps.approval.success && nextProps.approval.success !== this.props.approval.success) {
            this.handleCloseDialogApproval()
            this.props.getStatus(nextProps.approval.data.status)
            this.handleOpenDialogMsj("Se ha realizado la operación exitosamente")

            if (this.props.user.role === 'admin') {
                this.setState({
                    disable_cancel_btn: false,
                    disable_solicitude_btn: true
                })
                this.props.getCodeStatus(nextProps.approval.data.code)
            } else {
                this.setState({
                    disable_approve_btn: true
                })
                this.props.getCodeStatus(nextProps.approval.data.code)
            }

        }

        if (nextProps.solicitude.success && nextProps.solicitude.success !== this.props.solicitude.success) {
            this.handleCloseDialogSolicitud()
            this.props.getStatus(nextProps.solicitude.data.status)
            this.handleOpenDialogMsj("Se ha realizado la operación exitosamente")
            if (this.props.user.role === 'admin') {
                this.setState({
                    disable_solicitude_btn: false
                })
                this.props.getCodeStatus(nextProps.solicitude.data.code)
            }
        }

        if (nextProps.authorize.success && nextProps.authorize.success !== this.props.authorize.success) {
            this.handleCloseDialogAuthorize()

            if (nextProps.authorize.data.authorize === false) {
                this.handleOpenDialogMsj("Se ha alcanzado el límite de solicitudes autorizadas")
            } else {
                this.props.getStatus(nextProps.authorize.data.status)
                this.props.get_folio_authorize(nextProps.authorize.data.folio)
                this.handleOpenDialogMsj("Se ha realizado la operación exitosamente, el número de folio de autorización es: " + nextProps.authorize.data.folio)

                if (this.props.user.role === 'admin') {
                    this.setState({
                        disable_authorize_btn: false,
                        disable_solicitude_btn: true
                    })
                    this.props.getCodeStatus(nextProps.authorize.data.code)
                }
            }


        }

        if (nextProps.email.success && nextProps.email.success !== this.props.email.success) {
            this.handleCloseDialogEmailPetition()
            this.handleOpenDialogMsj("Se ha enviado una notificación al correo proporcionado")
            this.props.get_is_notified(nextProps.email.data.response)
        }

        if (nextProps.unsubscribe.success && nextProps.unsubscribe.success !== this.props.unsubscribe.success) {
            this.handleCloseDialogUnsubscribe()
            this.props.getStatus(nextProps.unsubscribe.data.status)

            if (this.props.user.role === 'admin') {
                this.setState({
                    disable_unsubscribe_btn: false
                })
                this.props.getCodeStatus(nextProps.unsubscribe.data.code)
            }
        }
        if (nextProps.detail.success && nextProps.detail.success !== this.props.detail.success) {
            // console.log("refe -: ", nextProps.detail.data)
            if (nextProps.detail.data.reference_person.value !== undefined) {
                this.props.set_reference_person({ value: nextProps.detail.data.reference_person.value, label: nextProps.detail.data.reference_person.label })
            }
        }

        if (nextProps.edit.success && nextProps.edit.success !== this.props.edit.success) {
            this.handleCloseDialogEdit()
            this.handleOpenDialogMsj("Se editó el nombre del beneficiario correctamente")
            // this.props.getName(nextProps.detail.first_name + ' ' + nextProps.detail.last_name + ' ' + nextProps.detail.m_last_name)
            const { match, petitionAttachDetail } = this.props
            petitionAttachDetail(match.params.id)
        }


        if (nextProps.revision.success && nextProps.revision.success !== this.props.revision.success) {
            this.handleCloseDialogRevision()
            this.props.getStatus(nextProps.revision.data.status)
            this.handleOpenDialogMsj("Se ha realizado la operación exitosamente")

            if (this.props.user.role === 'admin') {
                // this.setState({
                //     disable_cancel_btn: false,
                //     disable_solicitude_btn: true
                // })
                this.props.getCodeStatus(nextProps.revision.data.code)
            }

        }

        if (nextProps.change_program.success && nextProps.change_program.success !== this.props.change_program.success) {
            const { match, petitionAttachDetail } = this.props
            petitionAttachDetail(match.params.id)
        }
    }

    componentWillMount() {
        const { match, petitionAttachDetail, petitionRead, set_reference_person, detail } = this.props
        petitionAttachDetail(match.params.id)
        petitionRead(match.params.id, { is_read: true })

    }

    componentDidMount() {
        const token = localStorage.getItem('@token')
        if (token) {
            this.props.reacceso();
        }
        this.props.clean_check_item()
        this.props.clean_reference_person()
        this.props.listElectoralSections()
        this.props.listProgramSelect()

    }

    handleOpenDialogMap = () => {
        this.setState({
            open_map: true
        })
    }

    handleCloseMap = () => {
        this.setState({
            open_map: false
        })
    }

    handleCancelPetition = () => {
        const { match, cancelPetition, cancel } = this.props
        cancelPetition(match.params.id, cancel.comment)
    }

    handleOpenDialogCancel = () => {
        this.setState({
            open_cancel: true
        })
    }

    handleCloseDialogCancel = () => {
        this.setState({
            open_cancel: false
        })
        this.props.clean_comment_cancel()
    }

    handleApprovalPetition = () => {
        const { match, approvalPetition, approval } = this.props
        approvalPetition(match.params.id, approval.comment)
    }


    handleOpenDialogApproval = () => {
        this.setState({
            open_approval: true
        })
    }

    handleCloseDialogApproval = () => {
        this.setState({
            open_approval: false
        })
        this.props.clean_comment_approval()
    }


    handleAuthorizePetition = () => {
        const { match, authorizePetition, authorize } = this.props
        authorizePetition(match.params.id, authorize.comment)
    }


    handleOpenDialogAuthorize = () => {
        this.setState({
            open_authorize: true
        })
    }

    handleCloseDialogAuthorize = () => {
        this.setState({
            open_authorize: false
        })
        this.props.clean_comment_authorize()
    }

    handleUnsubscribePetition = (model) => {
        const { match, unsubscribePetition, detail } = this.props;


        let data = {
            "id": match.params.id,
            "comment": model.comment,
            "ini_date": model.fecha_desde && moment(model.fecha_desde).format('YYYY-MM-DD'),
            "fin_date": model.fecha_hasta && moment(model.fecha_hasta).format('YYYY-MM-DD'),
            "unsubscribe_type": model.unsubscribe_type && model.unsubscribe_type.value,
            "reason_cancellation": model.reason_cancellation && model.reason_cancellation.value,
            "beneficiario": detail.data.data_direct[0].id_beneficiario
        }
        unsubscribePetition(data)
        console.log("model ", model)
    }
    handleChangeTipoBaja = (e) => {
        if (e !== null) {
            this.setState({
                tipo_baja: e.value
            })
        }
    }

    handleOpenDialogUnsubscribe = () => {
        this.setState({
            open_unsubscribe: true
        })
    }

    handleCloseDialogUnsubscribe = () => {
        this.setState({
            open_unsubscribe: false
        })
        this.props.clean_comment_unsubscribe()
    }


    handleSendEmailPetition = () => {
        const { match, sendEmailPetition, detail } = this.props;
        sendEmailPetition(match.params.id, detail.data.data_direct[0].id_beneficiario)

    }

    handleOpenDialogEmailPetition = () => {
        this.setState({
            open_email: true
        })
    }

    handleCloseDialogEmailPetition = () => {
        this.setState({
            open_email: false
        })
    }

    handleChangeStatusSolicitud = () => {
        const { match, solicitudePetition, solicitude } = this.props
        solicitudePetition(match.params.id, solicitude.comment)
    }

    handleOpenDialogSolicitud = () => {
        this.setState({
            open_solicitud: true
        })
    }

    handleCloseDialogSolicitud = () => {
        this.setState({
            open_solicitud: false
        })
    }

    handleOpenDialogMsj = mensaje => {
        this.setState({
            open_snack: true,
            msj_snack: mensaje
        })
    }

    handleCloseSnack = () => {
        this.setState({
            open_snack: false
        })
    }

    cancelDialog = () => {
        const { cancel, set_comment_cancel } = this.props;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_cancel}
                onClose={this.handleCloseDialogCancel}
                aria-labelledby="comment-cancel"
            >
                {cancel.isFetching && <LinearProgress color="secondary" />}
                <DialogTitle id="comment-cancel">Advertencia!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de rechazar esta solicitud?
                    </DialogContentText>
                    <br />
                    <TextField
                        autoFocus
                        multiline
                        margin="normal"
                        id="comment_cancel"
                        label="Comentario"
                        type="text"
                        variant='outlined'
                        rows='4'
                        onChange={set_comment_cancel}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialogCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        disabled={cancel.isFetching}
                        onClick={this.handleCancelPetition}
                        color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    approvalDialog = () => {
        const { approval, set_comment_approval } = this.props;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_approval}
                onClose={this.handleCloseDialogApproval}
                aria-labelledby="comment-approval"
            >
                {approval.isFetching && <LinearProgress color="secondary" />}
                <DialogTitle id="comment-approval">Advertencia!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de dar visto bueno a esta solicitud?
                    </DialogContentText>
                    <br />
                    <TextField
                        autoFocus
                        multiline
                        margin="normal"
                        id="comment_approval"
                        label="Comentario"
                        type="text"
                        variant='outlined'
                        rows='4'
                        onChange={set_comment_approval}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialogApproval} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        disabled={approval.isFetching}
                        onClick={this.handleApprovalPetition}
                        color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    authorizeDialog = () => {
        const { authorize, set_comment_authorize } = this.props;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_authorize}
                onClose={this.handleCloseDialogAuthorize}
                aria-labelledby="comment-authorize"
            >
                {authorize.isFetching && <LinearProgress color="secondary" />}
                <DialogTitle id="comment-authorize">Advertencia!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de autorizar esta solicitud?
                    </DialogContentText>
                    <br />
                    <TextField
                        autoFocus
                        multiline
                        margin="normal"
                        id="comment_authorize"
                        label="Comentario"
                        type="text"
                        variant='outlined'
                        rows='4'
                        onChange={set_comment_authorize}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialogAuthorize} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        disabled={authorize.isFetching}
                        onClick={this.handleAuthorizePetition}
                        color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    mapDialog = () => {
        const { classes, detail } = this.props;
        let latitude = 19.419444
        let longitude = -99.145556
        if (detail.data.data_direct) {
            latitude = detail.data.data_direct[0].latitude
            longitude = detail.data.data_direct[0].longitude
            // console.log(latitude, longitude)
        }
        return (
            <Dialog
                open={this.state.open_map}
                onClose={this.handleCloseMap}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="comment-authorize"
            >
                {/* <DialogTitle id="comment-authorize">Dirección</DialogTitle> */}
                <DialogContent>
                    <div className={classes.mapField}>
                        <GoogleMap
                            bootstrapURLKeys={{
                                key: "IzaSyDqmrKiD0tij9jcQ5H7c_YoW67nTWdwb_0"  
                            }}
                            zoom={16}
                            center={[latitude, longitude]}
                        >
                            <Marker
                                text="Domicilio"
                                lat={latitude}
                                lng={longitude}
                            />
                        </GoogleMap>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    unsubscribeDialog = () => {
        const { unsubscribe, detail, classes } = this.props;
        const { tipo_baja } = this.state
        return (
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_unsubscribe}
                onClose={this.handleCloseDialogUnsubscribe}
                aria-labelledby="comment-unsubscribe"
            >
                {unsubscribe.isFetching && <LinearProgress color="secondary" />}
                <DialogTitle id="comment-unsubscribe">Advertencia!</DialogTitle>
                <Formsy
                    onValidSubmit={this.handleUnsubscribePetition}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    ref={(unsubscribe) => this.unsubscribe = unsubscribe}
                    className="flex flex-col justify-center"
                >
                    <DialogContent>
                        <DialogContentText>
                            Se dará aplicará la Baja/Suspensión al beneficiario {detail.data.data_direct && detail.full_name} y
                            se le notificará mediante correo electrónico. El envío del correo electrónico, no se puede deshacer.
                        </DialogContentText>
                        <br />


                        <Select2
                            name='unsubscribe_type'
                            className={classNames(classes.textField)}
                            placeholder='Seleccionar tipo de baja'
                            options={suggestions}
                            isClearable
                            required
                            fullWidth
                            onChange={this.handleChangeTipoBaja}
                        />
                        {tipo_baja === 'definitiva' &&
                            <Select2
                                name='reason_cancellation'
                                className={classNames(classes.textField)}
                                placeholder='Seleccionar motivo de baja'
                                options={options_baja}
                                isClearable
                                required
                                fullWidth
                            />
                        }
                        {tipo_baja === 'temporal' &&
                            <React.Fragment>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale="es" moment={moment}>
                                    <DatePickerSy
                                        name='fecha_desde'
                                        className={classNames(classes.textField)}
                                        margin="normal"
                                        label="Fecha de baja desde"
                                        clearable={true}
                                        autoOk={true}
                                        variant='outlined'
                                        format='YYYY-MM-DD'
                                        cancelLabel="CANCELAR"
                                        clearLabel="LIMPIAR"
                                        required
                                        fullWidth
                                    />

                                </MuiPickersUtilsProvider>

                                <MuiPickersUtilsProvider utils={MomentUtils} locale="es" moment={moment}>
                                    <DatePickerSy
                                        name='fecha_hasta'
                                        className={classNames(classes.textField)}
                                        margin="normal"
                                        label="Fecha de baja hasta"
                                        clearable={true}
                                        autoOk={true}
                                        variant='outlined'
                                        format='YYYY-MM-DD'
                                        cancelLabel="CANCELAR"
                                        clearLabel="LIMPIAR"
                                        required
                                        fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </React.Fragment>
                        }
                        <TextFieldFormsy
                            name='comment'
                            autoFocus
                            multiline
                            margin="normal"
                            id="comment_unsubscribe"
                            label="Comentario"
                            type="text"
                            variant='outlined'
                            rows='4'
                            fullWidth
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialogUnsubscribe} color="primary">
                            Cancelar
                        </Button>
                        <Button
                            type='submit'
                            disabled={!this.state.isFormValid || unsubscribe.isFetching}
                            color="primary">
                            Aceptar
                        </Button>
                    </DialogActions>
                </Formsy>
            </Dialog>
        )
    }

    emailDialog = () => {
        const { email, detail } = this.props;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_email}
                onClose={this.handleCloseDialogEmailPetition}
                aria-labelledby="send_email_petition"
            >
                {email.isFetching && <LinearProgress color="secondary" />}
                <DialogTitle id="send_email_petition">
                    {detail.code !== 'AUTORIZADA' && 'NOTIFICACIÓN DE NEGATIVA'}
                    {detail.code === 'AUTORIZADA' && 'NOTIFICACIÓN DE ACEPTACION'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro que desea notificar
                        al {detail.data.data_direct && detail.data.program.beneficiary_label} {detail.data.data_direct && detail.data.data_direct[0].beneficiario.toUpperCase()}? Esta acción no puede
                        deshacerse.
                    </DialogContentText>
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialogEmailPetition} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        disabled={email.isFetching}
                        onClick={this.handleSendEmailPetition}
                        color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    solicitudDialog = () => {
        const { solicitude, set_comment_solicitude } = this.props;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_solicitud}
                onClose={this.handleCloseDialogSolicitud}
                aria-labelledby="comment-authorize"
            >
                {solicitude.isFetching && <LinearProgress color="secondary" />}
                <DialogTitle id="comment-solicitud">Advertencia!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de cambiar a estatus Solicitud?
                    </DialogContentText>
                    <br />
                    <TextField
                        autoFocus
                        multiline
                        margin="normal"
                        id="comment_solicitud"
                        label="Comentario"
                        type="text"
                        variant='outlined'
                        rows='4'
                        onChange={set_comment_solicitude}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialogSolicitud} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        disabled={solicitude.isFetching}
                        onClick={this.handleChangeStatusSolicitud}
                        color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleCheckItem = (id, e) => {
        this.props.set_validate_check(id, e)
        // console.log(id, ' -> ', e.target.checked)
        this.props.validateAttachment(id, { is_valid: e.target.checked })
    }

    handleEditPerson = () => {
        this.setState({
            edit_person: true
        })
    }

    handleUpdatePerson = () => {
        const { match, petitionRead, reference_person } = this.props
        petitionRead(match.params.id, { reference_person: reference_person.person_reference_value })
        this.handleOpenDialogMsj("Se ha realizado la operación exitosamente")
        this.setState({
            edit_person: false
        })
    }

    msjDialog = () => {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_msj}
                onClose={this.handleCloseDialogMsj}
                aria-labelledby="msj"
            >
                <DialogContent>
                    <DialogContentText>
                        {this.state.mensaje}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.handleCloseDialogMsj}
                    >
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleOpenDialogMsj = (mensaje) => {
        this.setState({
            open_msj: true,
            mensaje: mensaje
        })
    }

    handleCloseDialogMsj = () => {
        this.setState({
            open_msj: false,
            mensaje: ''
        })
    }

    submitFormEdit = (model) => {
        const { editBeneficiary, detail } = this.props
        let data = {
            first_name: model.first_name,
            last_name: model.last_name,
            m_last_name: model.m_last_name !== undefined ? model.m_last_name : '',
            email: model.email,
            phone_number: model.phone_number,
            id_colony_owner: model.id_colony_owner,
            address: {
                id: model.id_address,
                street: model.street,
                out_number: model.out_number,
                int_number: model.int_number !== undefined ? model.int_number : '',
                colony: model.colony.value
            }
        }
        editBeneficiary(detail.data.data_direct[0].id_beneficiario, data)
    }

    disableButtonEdit = () => {
        this.setState({
            isFormValidEdit: false
        })
    }

    enableButtonEdit = () => {
        this.setState({
            isFormValidEdit: true
        })
    }

    editDialog = () => {
        const { classes, detail, set_name, set_form, edit, electoral_sections } = this.props
        console.log("detalle", detail)
        return (
            <Dialog
                maxWidth='md'
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_edit}
                onClose={this.handleCloseDialogEdit}
                aria-labelledby="msj"
            >
                {edit.isFetching && <LinearProgress color="secondary" />}
                <DialogTitle id="comment-solicitud">Advertencia!</DialogTitle>

                <Formsy
                    onValidSubmit={this.submitFormEdit}
                    onValid={this.enableButtonEdit}
                    onInvalid={this.disableButtonEdit}
                    ref={(editForm) => this.editForm = editForm}
                    className="flex flex-col justify-center"
                >
                    <DialogContent>
                        <DialogContentText>
                            ¿Está seguro de editar el nombre del beneficiario?
                    </DialogContentText>
                        <br />

                        <TextFieldFormsy name='id_address' type='hidden' value={detail.data.data_direct && detail.data.data_direct[0].id_address} />
                        <TextFieldFormsy name='id_colony_owner' type='hidden' value={detail.data.data_direct && detail.data.data_direct[0].id_colony_owner} />
                        <TextUFormsy
                            className={classes.textField}
                            name='first_name'
                            label="Nombre(s)"
                            type="text"
                            variant='outlined'
                            value={detail.data.data_direct && detail.data.data_direct[0].first_name}
                            required
                        />
                        <TextUFormsy
                            className={classes.textField}
                            name='last_name'
                            label="Apellido paterno"
                            type="text"
                            variant='outlined'
                            value={detail.data.data_direct && detail.data.data_direct[0].last_name}
                            required
                        />
                        <TextUFormsy
                            className={classes.textField}
                            name='m_last_name'
                            label="Apellido materno"
                            type="text"
                            variant='outlined'
                            value={detail.data.data_direct && detail.data.data_direct[0].m_last_name}
                        />
                        <TextFieldFormsy
                            className={classes.textField}
                            name='email'
                            label="Correo electrónico"
                            type="text"
                            variant='outlined'
                            value={detail.data.data_direct && detail.data.data_direct[0].email}
                            required
                        />
                        <TextFieldFormsy
                            className={classes.textField}
                            name='phone_number'
                            label="Número de telefono"
                            type="text"
                            variant='outlined'
                            value={detail.data.data_direct && detail.data.data_direct[0].phone}
                            required
                        />
                        <TextUFormsy
                            className={classes.textField}
                            name='street'
                            label="Calle"
                            type="text"
                            variant='outlined'
                            value={detail.data.data_direct && detail.data.data_direct[0].street}
                            required
                        />
                        <TextUFormsy
                            className={classes.textField}
                            name='out_number'
                            label="Número exterior"
                            type="text"
                            variant='outlined'
                            value={detail.data.data_direct && detail.data.data_direct[0].out_number}
                            required
                        />
                        <TextUFormsy
                            className={classes.textField}
                            name='int_number'
                            label="Número interior"
                            type="text"
                            variant='outlined'
                            value={detail.data.data_direct && detail.data.data_direct[0].int_number}
                            required
                        />
                        <Select2
                            name='colony'
                            className={classNames(classes.textField)}
                            placeholder='Seleccionar una colonia'
                            options={electoral_sections.data}
                            value={detail.data.data_direct && detail.data.data_direct[0].colony}
                            isClearable
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.button}
                            onClick={this.handleCloseDialogEdit}
                        >
                            CANCELAR
                    </Button>
                        <Button
                            type='submit'
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            disabled={!this.state.isFormValidEdit || edit.isFetching}
                        >
                            ACEPTAR
                    </Button>
                    </DialogActions>
                </Formsy>
            </Dialog>
        )

    }

    handleOpenDialogEdit = () => {
        this.setState({
            open_edit: true
        })
    }

    handleCloseDialogEdit = () => {
        this.setState({
            open_edit: false
        })
    }

    revisionDialog = () => {
        const { revision, set_comment_revision } = this.props;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_revision}
                onClose={this.handleCloseDialogRevision}
                aria-labelledby="comment-approval"
            >
                {revision.isFetching && <LinearProgress color="secondary" />}
                <DialogTitle id="comment-approval">Advertencia!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de poner en pendiente de revisión a esta solicitud?
                    </DialogContentText>
                    <br />
                    <TextField
                        autoFocus
                        multiline
                        margin="normal"
                        id="comment_approval"
                        label="Comentario"
                        type="text"
                        variant='outlined'
                        rows='4'
                        onChange={set_comment_revision}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialogRevision} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        disabled={revision.isFetching}
                        onClick={this.handleRevisionPetition}
                        color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    handleOpenDialogRevision = () => {
        this.setState({
            open_revision: true
        })
    }

    handleCloseDialogRevision = () => {
        this.setState({
            open_revision: false
        })
        this.props.clean_comment_revision()
    }


    handleRevisionPetition = () => {
        const { match, revisionPetition, revision } = this.props
        revisionPetition(match.params.id, revision.comment)
    }

    programChange = (e) => {
        this.setState({ programValue: e });
    }

    submitChangeProgram = () => {
        const { match, changeProgram } = this.props
        changeProgram(match.params.id, { id: match.params.id, program: this.state.programValue.value })
    }

    render() {
        const { classes, detail, user, validate_check, reference_person, set_reference_person, read, program } = this.props;
        const { vertical, horizontal, msj_snack, open_snack, edit_person, programValue } = this.state
        console.log(this.props.change_program)
        return (
            <div className="flex-1">
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open_snack}
                    onClose={this.handleCloseSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{msj_snack}</span>}
                />
                <this.cancelDialog />
                <this.approvalDialog />
                <this.authorizeDialog />
                <this.unsubscribeDialog />
                <this.emailDialog />
                <this.mapDialog />
                <this.solicitudDialog />
                <this.msjDialog />
                <this.editDialog />
                <this.revisionDialog />
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h4" style={{ color: '#616161' }}> <strong>Solicitudes | Gestión</strong></Typography>
                    <br />
                    <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 3 }} />
                </div>
                <br />
                <FuseAnimate animation={{ translateX: [0, '100%'] }}>
                    {detail.isFetching ?
                        <div className="flex justify-center">
                            <FacebookLoading />
                        </div>
                        : (
                            <div className="m-auto">
                                <Paper className={classes.root} elevation={1}>
                                    <div className='flex'>
                                        <div className='flex-1'>
                                            <Typography variant="title">Programa: {detail.data.program ? detail.data.program.name : ''} | {detail.data.register_folio}</Typography>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='flex'>
                                        {detail.folio_authorize !== null &&
                                            <div className='flex-1' style={{ textAlign: "right" }}>
                                                <Typography variant="subtitle1">Folio de autorización: {detail.folio_authorize}</Typography>
                                            </div>
                                        }
                                    </div>
                                    <div className='flex'>
                                        <div className='flex-1' style={{ textAlign: "right" }}>
                                            <Typography variant="subtitle1">Apoyo Comparte: {detail.data.contingency_support}</Typography>
                                        </div>
                                    </div>

                                    <Divider />

                                    <div className='flex'>
                                        <div className='flex-grow flex-initial'>
                                            {detail.data.data_direct && (
                                                <div>
                                                    <div className='flex'>
                                                        <div className='flex-1'>
                                                            <Typography variant="subtitle1"><strong>Solicitado:</strong> {moment(detail.data.created).format('YYYY-MM-DD')}</Typography>
                                                        </div>
                                                    </div>
                                                    <List className={classes.root}>
                                                        <ListItem key={1} dense button>
                                                            <Typography variant='subtitle2'>
                                                                <strong>{detail.data.program.beneficiary_label}:</strong> {detail.full_name}
                                                            </Typography>
                                                            {user.role === 'admin' &&
                                                                <IconButton onClick={this.handleOpenDialogEdit}>
                                                                    <Icon color='primary' className="text-28">edit</Icon>
                                                                </IconButton>
                                                            }
                                                        </ListItem>
                                                        <ListItem key={2} dense>
                                                            <Typography variant='subtitle2'>
                                                                <strong>CURP: </strong> {detail.data.data_direct[0].curp} &nbsp;&nbsp;
                                                                <strong>Email:</strong> {detail.data.data_direct[0].email} &nbsp;&nbsp;
                                                                <strong>Teléfono:</strong> {detail.data.data_direct[0].phone}
                                                            </Typography>
                                                        </ListItem>
                                                        <ListItem key={3} dense button>
                                                            <Typography variant='subtitle2'><strong>Fecha de nacimiento:</strong> {detail.data.data_direct[0].birth_date} <strong>Edad al dia de la
                                                                solicitud:</strong> {moment(detail.data.created).diff(moment(detail.data.data_direct[0].birth_date).format('YYYY-MM-DD'), 'years')} años</Typography>
                                                        </ListItem>
                                                        <ListItem key={4} dense button>
                                                            {detail.data.program.code === 'P0003' &&
                                                                <Typography variant='subtitle2'><strong>Cuida a:</strong> {detail.data.people_care}
                                                                    <strong>Dirección:</strong> {detail.data.data_direct[0].address}</Typography>
                                                            }
                                                            {detail.data.program.code === 'P0007' &&
                                                                <Typography variant='subtitle2'> <strong>Nombre social:</strong> {detail.data.data_direct[0].social_name}
                                                                    <strong>Dirección:</strong> {detail.data.data_direct[0].address}</Typography>
                                                            }
                                                            {(detail.data.program.code !== 'P0007' && detail.data.program.code !== 'P0003') &&
                                                                <Typography variant='subtitle2'><strong>Dirección:</strong> {detail.data.data_direct[0].address}</Typography>
                                                            }
                                                        </ListItem>
                                                        <ListItem key={5} dense button>
                                                            {detail.data.assigned_card !== null &&
                                                                <Typography variant='subtitle2'>
                                                                    <strong>Tarjeta asignada:</strong> {detail.data.assigned_card}

                                                                </Typography>
                                                            }
                                                        </ListItem>
                                                    </List>

                                                </div>
                                            )
                                            }
                                            <List className={classes.root}>
                                                {detail.data.data_indirect && detail.data.data_indirect.map(indirect =>
                                                    <ListItem key={indirect.id_beneficiario} dense button>
                                                        <Typography
                                                            variant='subtitle2'><strong>{detail.data.program.dependent_label}:</strong> {indirect.beneficiario}, {moment(detail.data.created).diff(moment(indirect.birth_date).format('YYYY-MM-DD'), 'years')} años
                                                            al dia de la
                                                            solicitud, {indirect.relationship}</Typography>
                                                    </ListItem>
                                                )}
                                            </List>

                                        </div>

                                    </div>
                                    <div className="flex">
                                        <Typography variant='subtitle1'><strong>¿Cómo se enteró?:</strong> {detail.data.way_know}, {detail.data.way_know_text}</Typography>
                                    </div>
                                    <br />

                                    <div className='flex'>
                                        <div className='flex-1 text-justify'>
                                            <Typography variant='subtitle1'><strong>Comentarios:</strong> <br /> {detail.data.comment}</Typography>
                                        </div>
                                        <div className='flex-1 self-end' style={{ textAlign: "right" }}>
                                            <Typography variant='subtitle1'>ESTATUS: {this.props.detail.data.status && detail.status}</Typography>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='flex'>
                                        <div className='flex-grow' style={{ textAlign: 'center' }}>
                                            <IconButton onClick={this.handleOpenDialogMap}>
                                                <Icon className="text-60">map</Icon>
                                            </IconButton>
                                            <Typography variant='subtitle2'>Ver ubicación en el mapa</Typography>
                                        </div>
                                        <div className='flex-grow' style={{ textAlign: 'center' }}>
                                            <a href={Config.client_url + 'reports/escrito/?peticion_id=' + detail.data.id} target="_blanck" rel="noreferrer">
                                                <IconButton>
                                                    <Print style={{ fontSize: 60 }} />
                                                </IconButton>
                                            </a>
                                            <Typography variant='subtitle2'>Ficha de solicitud</Typography>
                                        </div>
                                        {/* {detail.data.code === "AUTORIZADA" && <div className='flex-grow' style={{textAlign: 'center'}}>
                                            <a href={Config.client_url + 'reports/carta-compromiso/?solicitud_id=' + detail.data.id} target="_blanck" rel="noreferrer">
                                                <IconButton>
                                                    <Icon color='action' className="text-60">fingerprint</Icon>
                                                </IconButton>
                                            </a>
                                            <Typography variant='subtitle2'>Carta compromiso</Typography>
                                        </div>} */}
                                        {detail.data.code === "AUTORIZADA" && <div className='flex-grow' style={{ textAlign: 'center' }}>
                                            <a href={Config.client_url + 'reports/carta-aceptacion-masiva/?solicitud_id=' + detail.data.id} target="_blanck" rel="noreferrer">
                                                <IconButton>
                                                    <Icon color='action' className="text-60">how_to_reg</Icon>
                                                </IconButton>
                                            </a>
                                            <Typography variant='subtitle2'>Carta de aceptación</Typography>
                                        </div>}
                                        {detail.data.code === "AUTORIZADA" && <div className='flex-grow' style={{ textAlign: 'center' }}>
                                            <a href={Config.client_url + 'reports/carta_compromiso_2020/?solicitud_id=' + detail.data.id} target="_blanck" rel="noreferrer">
                                                <IconButton>
                                                    <Icon color='action' className="text-60">assignment</Icon>
                                                </IconButton>
                                            </a>
                                            <Typography variant='subtitle2'>Carta de compromiso</Typography>
                                        </div>}
                                        {detail.data.code === "AUTORIZADA" && <div className='flex-grow' style={{ textAlign: 'center' }}>
                                            <a href={Config.client_url + 'reports/contrarecibos/?solicitud_id=' + detail.data.id} target="_blanck" rel="noreferrer">
                                                <IconButton>
                                                    <Icon color='action' className="text-60">fingerprint</Icon>
                                                </IconButton>
                                            </a>
                                            <Typography variant='subtitle2'>Contrarecibo</Typography>
                                        </div>}
                                    </div>
                                </Paper>
                                <br />
                                {(user.role === 'admin' || user.role === 'reviser') && (
                                    <Paper className={classes.root} elevation={1}>
                                        <div className='flex flex-wrap'>
                                            <div className='flex-grow'>
                                                <Typography variant="title">Opciones para esta solicitud</Typography>
                                            </div>
                                        </div>
                                        <br />
                                        <Divider />
                                        <br />

                                        {user.role === 'admin' && detail.data.reference_person ? (detail.data.reference_person.label &&
                                            (<div className='flex flex-wrap'>
                                                <Typography variant='subtitle1'>Persona de referencia: {reference_person.person_reference_data && reference_person.person_reference_data.label} <Button
                                                    color='primary' onClick={this.handleEditPerson}><EditOutlined /></Button></Typography>
                                                <br />
                                            </div>)

                                        ) : null}

                                        {user.role === 'admin' &&
                                            <div className='flex flex-wrap'>
                                                {(edit_person || (detail.data.reference_person && detail.data.reference_person.label === undefined)) &&
                                                    <FormControl className="flex-grow">
                                                        <Autocomplete
                                                            name="reference_person"
                                                            placeholder='Persona de referencia'
                                                            options={detail.data.data_reference_person && detail.data.data_reference_person}
                                                            className={classNames(classes.textField)}
                                                            value={reference_person.person_reference_data}
                                                            onChange={set_reference_person}
                                                            // error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].relationship ? true : false) : false) : false) : false}
                                                            // helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].relationship ? save.data.beneficiaries[index].relationship : '') : '') : '') : ''}
                                                            isClearable
                                                        />
                                                        <Button
                                                            onClick={this.handleUpdatePerson}
                                                            disabled={read.isFetching}
                                                            variant="contained"
                                                            color='primary'
                                                            size="medium"
                                                            className={classNames(classes.button)}
                                                            aria-label="CAMBIAR"
                                                            value="legacy"
                                                        >
                                                            CAMBIAR
                                                        </Button>
                                                    </FormControl>
                                                }
                                                {/* <div className='flex flex-wrap'> */}
                                                <FormControl className="flex-grow">
                                                    <Autocomplete
                                                        error={this.props.change_program.error}
                                                        helperText={this.props.change_program.error && this.props.change_program.data.non_field_errors}
                                                        className={classNames(classes.textField)}
                                                        placeholder='Programa'
                                                        options={program.data}
                                                        value={programValue}
                                                        onChange={(e) => {
                                                            this.programChange(e)
                                                        }}
                                                        isClearable
                                                    />
                                                    <Button
                                                        onClick={this.submitChangeProgram}
                                                        disabled={this.props.change_program.isFetching || programValue === null}
                                                        variant="contained"
                                                        color='primary'
                                                        size="medium"
                                                        className={classNames(classes.button)}
                                                        aria-label="CAMBIAR"
                                                        value="legacy"
                                                    >
                                                        CAMBIAR DE PROGRAMA
                                                        </Button>
                                                </FormControl>
                                                {/* </div> */}
                                            </div>
                                        }


                                        <br />
                                        <Divider />
                                        <br />

                                        <div className='flex flex-wrap'>
                                            <div className='flex-1 flex-grow'>
                                                <Button
                                                    hidden={(detail.code === 'BAJA') ? true : false}
                                                    disabled={user.role && (user.role === 'reviser' ? ((this.state.disable_cancel_btn === true || detail.code !== "SOLICITADO") ? true : false) : user.role === 'admin' && ((this.state.disable_cancel_btn === true || detail.code === "RECHAZADO" || detail.code === 'AUTORIZADA') ? true : false))}
                                                    variant="extendedFab"
                                                    color='default'
                                                    size="large"
                                                    className={classNames(classes.button)}
                                                    aria-label="RECHAZAR"
                                                    value="legacy"
                                                    onClick={this.handleOpenDialogCancel}

                                                >
                                                    RECHAZAR
                                                </Button>
                                            </div>
                                            <div className='flex-1 flex-grow'>
                                                <Button
                                                    hidden={(detail.code === 'BAJA') ? true : false}
                                                    disabled={user.role && (user.role === 'reviser' ? ((this.state.disable_approve_btn === true || detail.code !== "SOLICITADO") ? true : false) : user.role === 'admin' && ((this.state.disable_approve_btn === true || detail.code === "VoBo" || detail.code === 'AUTORIZADA') ? true : false))}
                                                    variant="extendedFab"
                                                    color='secondary'
                                                    size="large"
                                                    className={classNames(classes.button)}
                                                    aria-label="VISTO BUENO"
                                                    value="legacy"
                                                    onClick={this.handleOpenDialogApproval}
                                                >
                                                    VISTO BUENO
                                                </Button>
                                            </div>
                                            {user.role === 'admin' &&

                                                <div className='flex-1 flex-grow'>
                                                    <Button
                                                        hidden={(detail.code === 'BAJA') ? true : false}
                                                        disabled={(this.state.disable_authorize_btn === true || detail.code !== 'VoBo') ? true : false}
                                                        variant="extendedFab"
                                                        color='primary'
                                                        size="large"
                                                        className={classNames(classes.button)}
                                                        aria-label="AUTORIZAR"
                                                        value="legacy"
                                                        onClick={this.handleOpenDialogAuthorize}
                                                    >
                                                        AUTORIZAR
                                                </Button>
                                                </div>
                                            }
                                        </div>
                                        <div className='flex flex-wrap'>
                                            {user.role === 'admin' &&
                                                <div className='flex-1 flex-grow'>
                                                    <Button
                                                        hidden={(detail.code !== 'AUTORIZADA') ? true : false}
                                                        variant="extendedFab"
                                                        color='default'
                                                        size="large"
                                                        className={classNames(classes.button)}
                                                        aria-label="BAJA"
                                                        value="legacy"
                                                        onClick={this.handleOpenDialogUnsubscribe}
                                                    >
                                                        DAR DE BAJA
                                                </Button>
                                                </div>
                                            }

                                            {(user.role === 'admin' || user.role === 'reviser') && ((detail.code === 'AUTORIZADA') || (detail.code !== 'AUTORIZADA' && detail.data.type_notification !== null)) &&
                                                <div className='flex-1 flex-grow' style={{ textAlign: 'center' }}>
                                                    <Button

                                                        variant="extendedFab"
                                                        color='primary'
                                                        size="large"
                                                        className={classNames(classes.button)}
                                                        aria-label="EMAIL"
                                                        style={{ backgroundColor: '#f29100' }}
                                                        onClick={this.handleOpenDialogEmailPetition}

                                                    >
                                                        ENVIAR CORREO
                                                </Button>
                                                    <Typography variant="subtitle2">{detail.is_notified === true ? 'NOTIFICADO' : 'NO HA SIDO NOTIFICADO'}</Typography>
                                                </div>
                                            }
                                            {user.role === 'admin' &&
                                                <div className='flex-1 flex-grow' style={{ textAlign: 'center' }} hidden={(detail.code !== 'VoBo' && detail.code !== 'AUTORIZADA' && detail.code !== 'REVISION') ? true : false}>
                                                    <Button
                                                        variant="extendedFab"
                                                        color='secondary'
                                                        size="large"
                                                        className={classNames(classes.button)}
                                                        aria-label="SOLICITUD"
                                                        onClick={this.handleOpenDialogSolicitud}
                                                    >
                                                        REGRESAR AL ESTATUS DE SOLICITUD
                                                </Button>
                                                </div>
                                            }

                                            {user.role === 'admin' &&
                                                <div className='flex-1 flex-grow' style={{ textAlign: 'center' }} hidden={(detail.code !== 'SOLICITADO') ? true : false}>
                                                    <Button
                                                        variant="extendedFab"
                                                        color='primary'
                                                        size="large"
                                                        className={classNames(classes.button)}
                                                        aria-label="SOLICITUD"
                                                        onClick={this.handleOpenDialogRevision}
                                                    >
                                                        PENDIENTE DE REVISION
                                                </Button>
                                                </div>
                                            }
                                        </div>
                                    </Paper>
                                )}


                                <br />
                                <Paper id='table_1' className={classes.root} elevation={1}>
                                    <div className='flex flex-wrap'>
                                        <div className='flex-grow'>
                                            <Typography variant="title">Documentación proporcionada en la solicitud</Typography>
                                        </div>
                                    </div>
                                    <br />
                                    <Divider />
                                    <br />

                                    <div>
                                        {detail.data.data_direct &&
                                            <div>
                                                <Typography variant='subtitle2'> <strong>{detail.data.data_direct[0].beneficiary_label}:</strong> {detail.data.data_direct[0].beneficiario.toUpperCase()}
                                                </Typography>

                                                <Table id="direct_1" className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Documento</TableCell>
                                                            <TableCell>Adjunto</TableCell>
                                                            <TableCell>Validar</TableCell>
                                                            <TableCell>Cargar</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {detail.data.data_direct && detail.data.data_direct[0].documents.map((document_required, index) =>

                                                            <TableRow key={document_required.id}>
                                                                <TableCell style={{ width: 100 }}>{document_required.name_documet}</TableCell>
                                                                <TableCell style={{ width: 100 }}>
                                                                    {document_required.files_direc && (document_required.files_direc.length > 0 ?
                                                                        (<a href={Config.client_url + 'media/' + document_required.files_direc[0].file} target='_blank' rel="noreferrer"> <Tooltip
                                                                            title='Ver adjunto'><Attachment style={{ fontSize: 25 }} color='primary' /></Tooltip></a>)
                                                                        :
                                                                        'No se adjuntó documento')}

                                                                </TableCell>
                                                                <TableCell style={{ width: 100 }}>
                                                                    {document_required.files_direc && (document_required.files_direc.length > 0 ?
                                                                        (
                                                                            <div>

                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Switch
                                                                                            name={document_required.files_direc[0].id_file}
                                                                                            classes={{
                                                                                                switchBase: classes.iOSSwitchBase,
                                                                                                bar: classes.iOSBar,
                                                                                                icon: classes.iOSIcon,
                                                                                                iconChecked: classes.iOSIconChecked,
                                                                                                checked: classes.iOSChecked,
                                                                                            }}
                                                                                            disableRipple
                                                                                            checked={validate_check.itemCheck[document_required.files_direc[0].id_file] && validate_check.itemCheck[document_required.files_direc[0].id_file]}
                                                                                            onChange={(e) => this.handleCheckItem(document_required.files_direc[0].id_file, e)}
                                                                                        />
                                                                                    }
                                                                                    label="Documento válido"
                                                                                />
                                                                            </div>
                                                                        )
                                                                        :
                                                                        null
                                                                    )}

                                                                </TableCell>
                                                                <TableCell>
                                                                    <UploadComponent key={document_required.id} beneficiary={{ "id": detail.data.data_direct[0].id_beneficiario }}
                                                                        docuemnt_type={{ "document_type": { "id": document_required.id, "name": document_required.name_documet } }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        )}

                                                    </TableBody>
                                                </Table>
                                            </div>
                                        }

                                        {detail.data.data_indirect &&
                                            <div>
                                                {detail.data.data_indirect && detail.data.data_indirect.map(beneficiario =>
                                                    <div key={beneficiario.id_beneficiario}>
                                                        <Typography variant='subtitle2'> <strong>{beneficiario.dependent_label}:</strong> {beneficiario.beneficiario.toUpperCase()}</Typography>
                                                        <Table id="indirect_1" className={classes.table}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Documento</TableCell>
                                                                    <TableCell>Adjunto</TableCell>
                                                                    <TableCell>Validar</TableCell>
                                                                    <TableCell>Cargar</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {detail.data.data_indirect && detail.data.data_indirect[0].documents.map((document_required, index) =>
                                                                    <TableRow key={document_required.id}>
                                                                        <TableCell style={{ width: 100 }}>{document_required.name_documet}</TableCell>
                                                                        <TableCell style={{ width: 100 }}>
                                                                            {document_required.files_indirec && (document_required.files_indirec.length > 0 ?
                                                                                (<a href={Config.client_url + 'media/' + document_required.files_indirec[0].file} target='_blank' rel="noreferrer"><Tooltip
                                                                                    title='Ver adjunto'><Attachment style={{ fontSize: 25 }} color='primary' /></Tooltip></a>)
                                                                                :
                                                                                'No se adjuntó documento')}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 100 }}>
                                                                            {document_required.files_indirec && (document_required.files_indirec.length > 0 ?
                                                                                (
                                                                                    <div>

                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Switch
                                                                                                    name={document_required.files_indirec[0].id_file}
                                                                                                    classes={{
                                                                                                        switchBase: classes.iOSSwitchBase,
                                                                                                        bar: classes.iOSBar,
                                                                                                        icon: classes.iOSIcon,
                                                                                                        iconChecked: classes.iOSIconChecked,
                                                                                                        checked: classes.iOSChecked,
                                                                                                    }}
                                                                                                    disableRipple
                                                                                                    checked={validate_check.itemCheck[document_required.files_indirec[0].id_file] && validate_check.itemCheck[document_required.files_indirec[0].id_file]}
                                                                                                    onChange={(e) => this.handleCheckItem(document_required.files_indirec[0].id_file, e)}


                                                                                                />
                                                                                            }
                                                                                            label="Documento válido"
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                null
                                                                            )}

                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <UploadComponent key={document_required.id} beneficiary={{ "id": detail.data.data_indirect[0].id_beneficiario }}
                                                                                docuemnt_type={{ "document_type": { "id": document_required.id, "name": document_required.name_documet } }} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </Paper>
                                <br />
                                <Paper id='table_2' className={classes.root} elevation={1}>
                                    <div className='flex flex-wrap'>
                                        <div className='flex-grow'>
                                            <Typography variant="title">Comentarios</Typography>
                                        </div>
                                    </div>
                                    <br />
                                    <Divider />
                                    <br />
                                    <Table id="comment_1" className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Estatus</TableCell>
                                                <TableCell>Comentario</TableCell>
                                                <TableCell>Usuario</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {detail.data.comment_status && detail.data.comment_status.map(comment_statu => (
                                                <TableRow key={comment_statu.id}>
                                                    <TableCell component="th" scope="row">{moment(comment_statu.created).format('YYYY-MM-DD')}</TableCell>
                                                    <TableCell>{comment_statu.status.name}</TableCell>
                                                    <TableCell>{comment_statu.comment}</TableCell>
                                                    <TableCell>{comment_statu.user.username}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </div>
                        )}
                </FuseAnimate>
            </div>
        )
    }
}

SolicitudeDetailView.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        petitionAttachDetail: Actions.petitionAttachDetail,
        reacceso: ActionsUser.reacceso,
        petitionRead: Actions.petitionRead,
        cancelPetition: Actions.cancelPetition,
        set_comment_cancel: Actions.set_comment_cancel,
        clean_comment_cancel: Actions.clean_comment_cancel,
        approvalPetition: Actions.approvalPetition,
        set_comment_approval: Actions.set_comment_approval,
        clean_comment_approval: Actions.clean_comment_approval,
        authorizePetition: Actions.authorizePetition,
        set_comment_authorize: Actions.set_comment_authorize,
        clean_comment_authorize: Actions.clean_comment_authorize,
        getStatus: Actions.getStatus,
        getCodeStatus: Actions.getCodeStatus,
        validateAttachment: Actions.validateAttachment,
        set_validate_check: Actions.set_validate_check,
        clean_check_item: Actions.clean_check_item,
        get_folio_authorize: Actions.get_folio_authorize,
        sendEmailPetition: Actions.sendEmailPetition,
        get_is_notified: Actions.get_is_notified,

        unsubscribePetition: Actions.unsubscribePetition,
        set_comment_unsubscribe: Actions.set_comment_unsubscribe,
        set_select_unsubscribe: Actions.set_select_unsubscribe,
        clean_comment_unsubscribe: Actions.clean_comment_unsubscribe,
        set_ini_date: Actions.set_ini_date,
        set_fin_date: Actions.set_fin_date,
        set_reference_person: Actions.set_reference_person,
        clean_reference_person: Actions.clean_reference_person,

        solicitudePetition: Actions.solicitudePetition,
        set_comment_solicitude: Actions.set_comment_solicitude,
        clean_comment_solicitude: Actions.clean_comment_solicitude,
        set_name: Actions.set_name,
        set_form: Actions.set_form,
        editBeneficiary: Actions.editBeneficiary,
        getName: Actions.getName,

        revisionPetition: Actions.revisionPetition,
        set_comment_revision: Actions.set_comment_revision,
        clean_comment_revision: Actions.clean_comment_revision,

        listElectoralSections: Actions.listElectoralSections,
        listProgramSelect: Actions.listProgramSelect,
        changeProgram: Actions.changeProgram

    }, dispatch);
}

function mapStateToProps({ solicitude, auth, users, catalogs }) {
    return {
        detail: solicitude.detail,
        user: auth.user,
        read: solicitude.read,
        cancel: solicitude.cancel,
        approval: solicitude.approval,
        authorize: solicitude.authorize,
        validate_attach: solicitude.validate_attach,
        validate_check: solicitude.validate_check,
        unsubscribe: solicitude.unsubscribe,
        email: solicitude.email,
        reference_person: solicitude.reference_person,
        solicitude: solicitude.solicitude,
        edit: users.edit,
        revision: solicitude.revision,
        electoral_sections: catalogs.electoral_sections,
        program: catalogs.program,
        change_program: solicitude.change_program


    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SolicitudeDetailView)));
