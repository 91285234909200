import {fetchEditBeneficiary} from 'api'

export const FETCHING_EDIT_BENEFICIARY = 'FETCHING_EDIT_BENEFICIARY';
export const FETCHING_EDIT_BENEFICIARY_SUCCESS = 'FETCHING_EDIT_BENEFICIARY_SUCCESS';
export const FETCHING_EDIT_BENEFICIARY_FAILURE = 'FETCHING_EDIT_BENEFICIARY_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_EDIT_BENEFICIARY
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_EDIT_BENEFICIARY_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_EDIT_BENEFICIARY_FAILURE,
        error
    }
}

export const editBeneficiary = (id, data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchEditBeneficiary(id, data)
            .then(data => {
                dispatch(getDataSuccess(data.data))
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}