import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse/index';
import { LoginConfig } from 'main/content/login/LoginConfig';
import { RecoveryConfig } from 'main/content/recovery/RecoveryConfig';
import { HomeConfig } from 'main/content/home/HomeConfig';
import { ProgramListConfig } from 'main/content/programs/list/ProgramListConfig';
import { SolicitudeRegisterConfig } from 'main/content/solicitude/register/SolicitudeRegisterConfig';
import { AttachmentConfig } from 'main/content/solicitude/attachment/AttachmentConfig';
import { SolicitudeListConfig } from 'main/content/solicitude/list/SolicitudeListConfig';
import { BulkLoadConfig } from 'main/content/solicitude/bulkload/BulkLoadConfig';
import { WayKnowConfig } from 'main/content/wayknow/WayKnowConfig';
import { ThanksConfig } from 'main/content/thanks/ThanksConfig';
import { RegisterConfig } from 'main/content/users/register/RegisterConfig';
import { SolicitudeDetailConfig } from 'main/content/solicitude/detail/SolicitudeDetailConfig';
import { ReportConfig } from 'main/content/reports/ReportConfig';
import { TarjetaNotificacionConfig } from "main/content/solicitude/tarjeta_notificacion/TarjetaNotificacionConfig";
import { SocioeconomicProfileConfig } from "main/content/solicitude/socioeconomic/SocioeconomicProfileConfig";
import { UserListConfig } from "main/content/users/list/UserListConfig";
import { ErrorConfig } from "main/content/errors/ErrorConfig";

const routeConfigs = [
    LoginConfig,
    RecoveryConfig,
    HomeConfig,
    ProgramListConfig,
    SolicitudeRegisterConfig,
    AttachmentConfig,
    SolicitudeListConfig,
    BulkLoadConfig,
    WayKnowConfig,
    ThanksConfig,
    RegisterConfig,
    SolicitudeDetailConfig,
    ReportConfig,
    TarjetaNotificacionConfig,
    SocioeconomicProfileConfig,
    UserListConfig,
    ErrorConfig
];

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => <Redirect to="/programas" />
    }
];
