import * as Actions from '../../actions/index';

const initialState = {
    data: [],
    success: false,
    isFetching: false,
    error: false
}

const program = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_PROGRAM_SELECT: {
            return {
                ...state,
                data: [],
                success: false,
                isFetching: true,
                error: false
            }
        }
        case Actions.FETCHING_PROGRAM_SELECT_SUCCESS: {
            return {
                ...state,
                data: action.data,
                success: true,
                isFetching: false,
                error: false
            }
        }
        case Actions.FETCHING_PROGRAM_SELECT_FAILURE: {
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }
        }
        default: {
            return state
        }
    }
}

export default program;