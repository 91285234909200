import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Icon, Button, Typography, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as Actions from 'store/actions';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import {TextFieldFormsy} from '@fuse';

const styles = theme => ({
    layoutRoot: {},
    textField: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        flexGrow: 1,

    },
    button: {
        margin: theme.spacing.unit,
    },
    grow: {
        flexGrow: 1,
    },
});


class Tarjeta extends Component {
    state = {
        isFormValid: false,
        open_dialog: false,
        mensaje: '',
        open_confirmacion : false
    }

    componentDidMount() {
        const {lista} = this.props
        this.props.detalleTarjetaNotificacion(lista.id_solicitud)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.asignacion_tarjeta.error && nextProps.asignacion_tarjeta.error !== this.props.asignacion_tarjeta.error) {
            this.handleOpenDialogMsj(nextProps.asignacion_tarjeta.data.assigned_card)
        }
         if (nextProps.asignacion_tarjeta.success && nextProps.asignacion_tarjeta.success !== this.props.asignacion_tarjeta.success) {
             this.handleCloseDialogConfirmacion()
            this.handleOpenDialogMsj('Se ha guardado correctamente')
        }
    }

    submitForm = () => {
        const {lista} = this.props
        const model = this.formTarjeta.getModel()
        this.props.asignarTarjeta(lista.id_solicitud, model)
    }


    disableButton = () => {
        this.setState({
            isFormValid: false
        })
    }

    enableButton = () => {
        this.setState({
            isFormValid: true
        })
    }

    msjDialog = () => {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_dialog}
                onClose={this.handleCloseDialogMsj}
                aria-labelledby="msj"
            >
                <DialogContent>
                    <DialogContentText>
                        {this.state.mensaje}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.handleCloseDialogMsj}
                    >
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleOpenDialogMsj = (mensaje) => {
        this.setState({
            open_dialog: true,
            mensaje: mensaje
        })
    }

    handleCloseDialogMsj = () => {
        this.setState({
            open_dialog: false,
            mensaje: ''
        })
    }


    confirmacionDialog = () => {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open_confirmacion}
                onClose={this.handleCloseDialogConfirmacion}
                aria-labelledby="msj"
            >
                {this.props.asignacion_tarjeta.isFetching && <LinearProgress color="secondary"/>}
                <DialogTitle id="comment-solicitud">Advertencia!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de asignar número de tarjeta?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.handleCloseDialogConfirmacion}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.submitForm}
                    >
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleOpenDialoConfirmacion = () => {
        this.setState({
            open_confirmacion: true
        })
    }

    handleCloseDialogConfirmacion = () => {
        this.setState({
            open_confirmacion: false
        })
    }

    render() {
        const {classes, detalle, asignacion_tarjeta} = this.props;
        console.log("asignacion ", asignacion_tarjeta)
        return (
            <React.Fragment>
                <this.msjDialog/>
                <this.confirmacionDialog/>
                <div style={{textAlign: 'center'}}>
                    <Typography variant="h4" style={{color: '#616161'}}> <strong>Tarjetas y Notificaciones</strong></Typography>
                    <br/>
                    <Divider style={{backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 1}}/>
                </div>
                <br/>
                <div>
                    <Typography variant='title' style={{textAlign: 'center'}}>
                        {detalle.data && detalle.data.approved_folio} - {detalle.data && detalle.data.beneficiario && detalle.data.beneficiario.first_name} {detalle.data && detalle.data.beneficiario && detalle.data.beneficiario.last_name} {detalle.data && detalle.data.beneficiario && detalle.data.beneficiario.m_last_name}
                        <br/>
                        {detalle.data && detalle.data.programa}
                    </Typography>
                </div>

                {detalle.data && detalle.data.status === 'AUTORIZADA' &&
                <React.Fragment>
                    <div className="flex flex-wrap">
                        <Typography variant="h6" color="primary" className={classes.grow}>
                            Asignar tarjeta
                        </Typography>
                    </div>
                    <Formsy
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        ref={(formTarjeta) => this.formTarjeta = formTarjeta}
                        className="flex flex-col justify-center"
                    >
                        <div className='flex flex-wrap'>
                            <TextFieldFormsy
                                className={classes.textField}
                                type='text'
                                name='assigned_card'
                                value={detalle.data && (detalle.data.assigned_card !== null ? detalle.data.assigned_card : '')}
                                variant='outlined'
                                label='Número de tarjeta asignada'
                                validations={{
                                    maxLength: 16,
                                    minLength: 16
                                }}
                                validationErrors={{
                                    minLength: 'Deben ser 16 caracteres',
                                    maxLength: 'Deben ser 16 caracteres'
                                }}
                                required
                            />
                        </div>
                        <div className="flex flex-wrap justify-end">
                            <Button
                                onClick={this.handleOpenDialoConfirmacion}
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                disabled={!this.state.isFormValid}
                                aria-label="CONTINUAR"
                                value="legacy"
                            >
                                GUARDAR
                            </Button>
                        </div>
                    </Formsy>
                </React.Fragment>
                }

            </React.Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        detalleTarjetaNotificacion: Actions.detalleTarjetaNotificacion,
        asignarTarjeta: Actions.asignarTarjeta
    }, dispatch);
}

function mapStateToProps({solicitude}) {
    return {
        lista: solicitude.list,
        detalle: solicitude.detalle_tarjeta_notificacion,
        asignacion_tarjeta: solicitude.asignacion_tarjeta
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Tarjeta)));