export const ADD_UPLOAD_COMPLETED = 'ADD_UPLOAD_COMPLETED';
export const RESET_UPLOAD_COMPLETED = 'RESET_UPLOAD_COMPLETED';

export function addUploadCompleted(value) {
    return {
        type: ADD_UPLOAD_COMPLETED,
        value: value
    }
}

export function resetUploadCompleted(value) {
    return {
        type: RESET_UPLOAD_COMPLETED,
        value: value
    }
}