import { fetchUnsubscribePetition } from 'api'

export const FETCHING_PETITION_UNSUBSCRIBE = 'FETCHING_PETITION_UNSUBSCRIBE';
export const FETCHING_PETITION_UNSUBSCRIBE_SUCCESS = 'FETCHING_PETITION_UNSUBSCRIBE_SUCCESS';
export const FETCHING_PETITION_UNSUBSCRIBE_FAILURE = 'FETCHING_PETITION_UNSUBSCRIBE_FAILURE';
export const SET_COMMENT_UNSUBSCRIBE = 'SET_COMMENT_UNSUBSCRIBE';
export const CLEAN_COMMENT_UNSUBSCRIBE = 'CLEAN_COMMENT_UNSUBSCRIBE';
export const SET_SELECT_UNSUBSCRIBE = 'SET_SELECT_UNSUBSCRIBE';
export const SET_INI_DATE = 'SET_INI_DATE';
export const SET_FIN_DATE = 'SET_FIN_DATE';

export const getData = () => {
    return {
        type: FETCHING_PETITION_UNSUBSCRIBE
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_PETITION_UNSUBSCRIBE_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_UNSUBSCRIBE_FAILURE,
        error
    }
}

export const set_comment_unsubscribe = e => {
    return {
        type: SET_COMMENT_UNSUBSCRIBE,
        value: e
    }
}

export const set_select_unsubscribe = value => {
    console.log('wwww', value)
    return {
        type: SET_SELECT_UNSUBSCRIBE,
        data: value,
        value: value && value.value
    }
}

export const set_ini_date = date => {
    return {
        type: SET_INI_DATE,
        date
    }
}

export const set_fin_date = date => {
    return {
        type: SET_FIN_DATE,
        date
    }
}

export const clean_comment_unsubscribe = () => {
    return {
        type: CLEAN_COMMENT_UNSUBSCRIBE
    }
}

export const unsubscribePetition = (data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchUnsubscribePetition(data)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}