import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FusePageCarded} from '@fuse';
import {LinearProgress} from '@material-ui/core';
import HeaderComponent from '../components/HeaderComponent';
import LoginForm from './LoginForm';
import {FuseAnimate} from '@fuse';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const styles = theme => ({
    layoutRoot: {},
    button: {
        marginTop: theme.spacing.unit
    },
});

class Login extends Component {

    componentDidMount(){
        
    }

    render() {
        const {classes, login} = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <HeaderComponent />
                }
               
                content={
                    <div>
                        {login.isFetching && <LinearProgress color="primary"/>}
                        <div className="p-24">
                            <FuseAnimate animation={{translateX: [0, '100%']}}>
                                <LoginForm />
                            </FuseAnimate>
                        </div>
                    </div>
                }
            />
        )
    }
}

function mapStateToProps({auth}){
    return {
        login: auth.login
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps, null)(Login)));
