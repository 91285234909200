import React, { Component } from 'react'
import Formsy from 'formsy-react';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles/index';
import { Typography, Icon, Button, InputAdornment, Divider } from '@material-ui/core';
import { bindActionCreators } from 'redux';

import * as Actions from 'auth/store/actions';
import { FuseAnimate, TextFieldFormsy, CheckboxFormsy } from '@fuse';
import connect from 'react-redux/es/connect/connect';

const styles = theme => ({
    button: {
        marginLeft: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});

class LoginForm extends Component {
    state = {
        canSubmit: false
    }

    form = React.createRef();

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    onSubmit = (model) => {
        this.disableButton();
        this.props.submitLogin(model);
    }

    onBack = () => {
        this.props.history.goBack();
    }

    onRcoverPin = () => {
        const pathname = '/recuperar';
        this.props.history.push({
            pathname
        });
    }

    componentDidMount() {
        const token = localStorage.getItem('@token')
        if (token) {
            this.props.reacceso();
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.login.success && nextProps.login.success !== this.props.login.success) {
            const { user } = this.props;
            var pathname = '';
            // if (survey.id) {
            //     pathname = '/survey'
            // }
            if (user.role === 'admin' || user.role === 'staff' || user.role === 'reviser') {
                pathname = '/home';
            } else if (user.role === 'user') {
                pathname = '/programas';
            }
            this.props.history.push({
                pathname
            });
        }
        if (nextProps.login.error && nextProps.login.error !== this.props.login.error) {
            this.enableButton();
        }
    }

    render() {
        const { classes, login, user } = this.props;
        const { canSubmit } = this.state;
        return (
            <div className="flex-1">
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h4" style={{ color: '#616161' }}> <strong>Programas sociales de la Alcaldía Cuauhtémoc</strong></Typography>
                    <br />
                    <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 3 }} />
                </div>
                <br />
                <FuseAnimate animation={{ translateX: [0, '100%'] }}>
                    <div className="m-auto max-w-512">
                        <Typography variant="h6" className="text-center md:w-full mb-24">INGRESAR</Typography>

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            ref={(form) => this.form = form}
                            className="flex flex-col justify-center w-full"
                        >
                            <TextFieldFormsy
                                className="mb-16"
                                type="text"
                                name="username"
                                label="Usuario"
                                validations={{
                                    maxLength: 20,
                                    minLength: 4
                                }}
                                validationErrors={{
                                    maxLength: 'La longitud máxima de carácteres es 20',
                                    minLength: 'La longitud mínima de carácteres es 4'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">person_outline</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                            />
                            <TextFieldFormsy
                                className="mb-4"
                                type="password"
                                name="password"
                                label="Contraseña"
                                validations={{
                                    maxLength: 20,
                                    minLength: 4
                                }}
                                validationErrors={{
                                    maxLength: 'La longitud máxima de carácteres es 20',
                                    minLength: 'La longitud mínima de carácteres es 4'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">lock_open</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                            />

                            <CheckboxFormsy
                                name="refresh"
                                label="Mantener mi sesión activa"
                                value={true}
                            />

                            {login.error && <div className="flex flex-wrap justify-end">
                                <Typography color="error" className="text-12">
                                    {login.message}
                                </Typography>
                            </div>}

                            <div className="flex flex-wrap justify-end">
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="large"
                                    className={classes.button}
                                    aria-label="RECUPERAR CUENTA"
                                    onClick={this.onRcoverPin}
                                    value="legacy"
                                >
                                    RECUPERAR CUENTA
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={this.onBack}
                                    className={classes.button}
                                    aria-label="ATRAS"
                                    value="legacy"
                                >
                                    ATRAS
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    aria-label="ENTRAR"
                                    disabled={!canSubmit}
                                    value="legacy"
                                >
                                    ENTRAR
                                </Button>
                            </div>
                            {/* <div className="flex flex-wrap justify-end">
                                <Button
                                    component={Link}
                                    to={'/registrar'}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    aria-label="REGISTRAR"
                                    value="legacy"
                                    fullWidth
                                >
                                    REGISTRAR
                                </Button>
                            </div> */}
                            <br />
                            <div className="flex flex-wrap justify-center">
                                <Typography variant="subtitle1"><a href={'https://alcaldiacuauhtemoc.mx/aviso-de-privacidad-sistema-de-cuidado-de-derechos-humanos/'} target="_blanck">Consulta nuestro aviso de privacidad.</a></Typography>
                            </div>
                        </Formsy>
                    </div>
                </FuseAnimate>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        submitLogin: Actions.submitLogin,
        reacceso: Actions.reacceso
    }, dispatch);
}

function mapStateToProps({ auth }) {
    return {
        login: auth.login,
        user: auth.user
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm)));
