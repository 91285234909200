import qs from 'qs'
import axios from 'axios';
import config from '../config'

export const URL_REPORT = config.client_url + 'reports/report/';

const getToken = () => {
    return JSON.parse(localStorage.getItem('@token'))
}

const instance = axios.create({
    baseURL: config.client_url
})

export const fetchLogin = (username, password) => {
    return instance.post('o/token/', qs.stringify({
        client_id: config.client_id,
        client_secret: config.client_secret,
        username: username,
        password: password,
        grant_type: 'password'
    }))
}

export const fetchRefresh = () => {
    const token = getToken()
    return instance.post('o/token/', qs.stringify({
        refresh_token: token !== null ? token.refresh_token : '',
        client_id: config.client_id,
        client_secret: config.client_secret,
        grant_type: 'refresh_token'
    }))
}

export const fetchLogout = () => {
    const token = getToken()
    return instance.post('o/revoke_token/', qs.stringify({
        token: token.access_token,
        client_id: config.client_id,
        client_secret: config.client_secret,
    }))
}

export const fetchUserMessage = (username, password) => {
    return instance.get('api/user/', {
        params: {
            username: username,
            password: password
        }
    })
}

export const fetchProfile = () => {
    const token = getToken()
    return instance.get('api/profile/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchListProgram = () => {
    const token = getToken()
    return instance.get('api/catalogs/program/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchDetailProgram = (id) => {
    const token = getToken()
    return instance.get('api/catalogs/program/' + id + '/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchPetitionRegister = (data) => {
    const token = getToken()
    return instance.post('api/petition/add', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchCurpSearch = (curp) => {
    const token = getToken()
    return instance.get('api/petition/search_curp/', {
        params: {
            curp: curp
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchPetitionRecuperate = (data, id) => {
    const token = getToken()
    return instance.put('api/petition/' + id + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchPetitionUpdate = (data, id) => {
    const token = getToken()
    return instance.put('api/petition/update/' + id + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchPetitionFinalize = (data) => {
    const token = getToken()
    return instance.post('api/petition/finalize', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchAttachment = (beneficiary_id, document_type_id) => {
    const token = getToken()
    return instance.get('api/attachment/', {
        params: {
            beneficiary_id: beneficiary_id,
            document_type_id: document_type_id
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchAdjunto = (beneficiary_id, document_type_id) => {
    const token = getToken()
    return instance.get('api/adjunto/', {
        params: {
            beneficiary_id: beneficiary_id,
            document_type_id: document_type_id
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchUploadAttachment = (data, id) => {
    const token = getToken()
    if (id !== undefined) {
        return instance.put('api/upload/attachment/' + id + '/', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
            }
        })
    } else {
        return instance.post('api/upload/attachment', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
            }
        })
    }
}

export const fetchPetitionList = (state, search) => {
    const token = getToken()
    return instance.get('api/petition/list/', {
        params: {
            page: state.page + 1,
            page_size: state.pageSize,
            search: search.beneficiary + '' + search.comment,
            register_folio: search.folio,
            min_date: search.min_date,
            max_date: search.max_date,
            program: search.programValue ? search.programValue.value : '',
            status: search.statusValue ? search.statusValue.value : '',
            is_read: search.is_read,
            assigned_card: search.assigned_card,
            reference_person: search.reference_person ? search.reference_person : '',
            ordering: state.sorted[0] ? (state.sorted[0].desc ? '-' : '') + state.sorted[0].id : 'id'
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchDocuments = (document_type) => {
    const token = getToken()
    return instance.get('api/documents/', {
        params: {
            document_type: document_type
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchRelationShips = () => {
    const token = getToken()
    return instance.get('api/catalgos/relation_ship/list/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchElectoralSections = () => {
    const token = getToken()
    return instance.get('api/catalgos/electoral_sections/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchWayKnow = () => {
    const token = getToken()
    return instance.get('api/formas/enterarse/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchRegisterUser = data => {
    return instance.post('api/user/register/', data)
}

export const fetchEthnicGroupList = () => {
    const token = getToken()
    return instance.get('api/catalogs/ethnic_group/list/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchPetitionDetail = (id) => {
    const token = getToken()
    return instance.get('api/petition/detail/' + id + '/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchPetitionRead = (id, data) => {
    const token = getToken()
    return instance.put('api/petition/detail/' + id + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fethPetitionAttachDetail = (id_petition) => {
    const token = getToken()
    return instance.get('api/petition/data/detail/', {
        params: {
            id_petition: id_petition
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchCancelPetition = (id_petition, comment) => {
    const token = getToken()
    return instance.get('api/petition/cancel/', {
        params: {
            id_petition: id_petition,
            comment: comment
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchApprovalPetition = (id_petition, comment) => {
    const token = getToken()
    return instance.get('api/petition/approval/', {
        params: {
            id_petition: id_petition,
            comment: comment
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}


export const fetchAuthorizePetition = (id_petition, comment) => {
    const token = getToken()
    return instance.get('api/petition/authorize/', {
        params: {
            id_petition: id_petition,
            comment: comment
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchUnsubscribePetition = (data) => {
    const token = getToken()
    return instance.get('api/petition/unsubscribe/', {
        params: {
            data: data
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchSendEmailPetition = (id_petition, beneficiario) => {
    const token = getToken()
    return instance.get('api/petition/send_email/', {
        params: {
            id_petition: id_petition,
            beneficiario: beneficiario
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchSolicitudePetition = (id_petition, comment) => {
    const token = getToken()
    return instance.get('api/petition/solicitude/', {
        params: {
            id_petition: id_petition,
            comment: comment
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchPrograms = () => {
    const token = getToken()
    return instance.get('api/catalogs/programs/', {

        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchStatus = () => {
    const token = getToken()
    return instance.get('api/catalogs/status/', {

        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchValidateAttachment = (id, data) => {
    const token = getToken()
    return instance.put('api/attachment/validate/' + id + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchGeolocations = (params) => {
    const token = getToken()
    return instance.get('api/geolocation/search_address/', {
        params: {
            colony_id: params.colony_id,
            street: params.street,
            out_number: params.out_number
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchRefencePerson = () => {
    const token = getToken();
    return instance.get('api/catalgos/references_person/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchEditBeneficiary = (id, data) => {
    const token = getToken()
    return instance.put('api/beneficiary/edit/' + id + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}


export const fetchRevisionPetition = (id_petition, comment) => {
    const token = getToken()
    return instance.get('api/petition/revision/', {
        params: {
            id_petition: id_petition,
            comment: comment
        },
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchDetalleTarjetaNotificacion = id_solicitud => {
    const token = getToken()
    return instance.get('api/petition/detail/tarjeta_notificacion/' + id_solicitud + '/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchTipoNotificacion = () => {
    const token = getToken()
    return instance.get('api/petition/tipo_notificacion/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchAsignarTarjeta = (id_solicitud, data) => {
    const token = getToken()
    return instance.put('api/petition/asignar_tarjeta/' + id_solicitud + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchAltaTypeNotification = (id_solicitud, data) => {
    const token = getToken()
    return instance.put('api/petition/alta_tipo_notificacion/' + id_solicitud + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchSocieconimicAdd = (data) => {
    const token = getToken();
    return instance.post('api/socioeconomic/add', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchSocieconimicUpdate = (id, data) => {
    const token = getToken();
    return instance.put('api/socioeconomic/' + id + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}


export const fetchGetYears = () => {
    const token = getToken();
    return instance.get('api/get_years/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchListUsers = () => {
    const token = getToken()
    return instance.get('api/catalogs/users/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchUserIsActive = (data) => {
    const token = getToken();
    return instance.post('api/user/is_active/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchUsersActivate = (data) => {
    const token = getToken();
    return instance.post('api/users/activate/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchUsersDeactivate = (data) => {
    const token = getToken();
    return instance.post('api/users/deactivate/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchChangeProgramBeneficiarie = (id_petition, data) => {
    const token = getToken();
    return instance.put('/api/petition/change_program/' + id_petition + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchBulkLoad = (data) => {
    const token = getToken()
    return instance.post('api/bulk_load/', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchRoles = () => {
    const token = getToken()
    return instance.get('api/catalogs/roles/', {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}

export const fetchUpdateProgramRolUser = (id_user, data) => {
    console.log('envia', id_user, data)
    const token = getToken()
    return instance.patch('api/users/detail/' + id_user + '/', data, {
        headers: {
            'Authorization': 'Bearer ' + (token !== null ? token.access_token : '')
        }
    })
}