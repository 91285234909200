import { combineReducers } from 'redux';
import form from './form.reducer';
import save from './save.reducer';
import list from './list.reducer';
import finalize from './finalize.reducer';
import search from './search_filter.reducer';
import upload from './upload.reducer';
import save_way from './save.way.reducer';
import curp_search from './curp.search.reducer';
import detail from './detail.reducer';
import read from './read.reducer';
import cancel from './cancel.reducer';
import approval from './approval.reducer';
import authorize from './authorize.reducer';
import validate_attach from './validate_attach.reducer';
import validate_check from './validate_check.reducer';
import unsubscribe from './unsubscribe.reducer';
import email from './email.reducer';
import reference_person from './set_reference_person.reducer';
import solicitude from './solicitude.reducer';
import revision from './revision.reducer';
import detalle_tarjeta_notificacion from './detalle_tarjeta_notificacion.reducer';
import asignacion_tarjeta from './asignacion_tarjeta.reducer';
import list_type_notification from './list_type_notification.reducer';
import alta_type_notification from './alta_type_notification.reducer';
import add_socioeconomic from './add.socioeconomic.reducer';
import solicitude_detail from './solicitude.detail.reducer';
import update_socioeconomic from './update.socioeconomic.reducer';
import change_program from './change.program.reducer';
import bulkload from './bulk.load.reducer';

const solicitudeReducers = combineReducers({
    form,
    save,
    list,
    finalize,
    search,
    upload,
    save_way,
    curp_search,
    detail,
    read,
    cancel,
    approval,
    authorize,
    validate_attach,
    validate_check,
    unsubscribe,
    email,
    reference_person,
    solicitude,
    revision,
    detalle_tarjeta_notificacion,
    asignacion_tarjeta,
    list_type_notification,
    alta_type_notification,
    add_socioeconomic,
    solicitude_detail,
    update_socioeconomic,
    change_program,
    bulkload
});

export default solicitudeReducers;