import BulkLoad from './BulkLoad';
import { authRoles } from 'auth';

export const BulkLoadConfig = {
    settings: {
        layout: {
            config: {
            }
        }
    },
    auth: authRoles.admin,
    routes: [{
        path: '/bulk-load',
        component: BulkLoad
    }
    ]
};