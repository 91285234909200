import React, {Component} from 'react';
import _ from '@lodash';
import {withStyles} from '@material-ui/core/styles';
import {DatePicker} from "material-ui-pickers"
import {withFormsy} from 'formsy-react';


const styles = theme => ({
    root: {}
});


class DatePickerSy extends Component {

    changeValue = (event) => {
        this.props.setValue(event);
        // if (this.props.onChange) {
        //     this.props.onChange(event);
        // }
    };

    render() {
        const {classes} = this.props
        const importedProps = _.pick(this.props, [
            'onChange',
            'value',
            'allowKeyboardControl',
            'animateYearScrolling',
            'autoOk',
            'disabled',
            'disableFuture',
            'disablePast',
            'disableToolbar',
            'emptyLabel',
            'format',
            'initialFocusedDate',
            'InputAdornmentProps',
            'inputValue',
            'inputVariant',
            'invalidDateMessage',
            'invalidLabel',
            'KeyboardButtonProps',
            'keyboardIcon',
            'labelFunc',
            'leftArrowButtonProps',
            'leftArrowIcon',
            'loadingIndicator',
            'mask',
            'maskChar',
            'maxDate',
            'maxDateMessage',
            'minDate',
            'minDateMessage',
            'onAccept',
            'onClose',
            'onError',
            'onMonthChange',
            'onOpen',
            'onYearChange',
            'open',
            'openTo',
            'PopoverProps',
            'refuse',
            'renderDay',
            'rifmFormatter',
            'rightArrowButtonProps',
            'rightArrowIcon',
            'shouldDisableDate',
            'strictCompareDates',
            'TextFieldComponent',
            'variant',
            'views',
            'cancelLabel',
            'clearable',
            'clearLabel',
            'DialogProps',
            'okLabel',
            'showTodayButton',
            'todayLabel',
            'autoComplete',
            'autoFocus',
            'children',
            'className',
            'defaultValue',
            'disabled',
            'FormHelperTextProps',
            'fullWidth',
            'id',
            'InputLabelProps',
            'inputProps',
            'InputProps',
            'inputRef',
            'label',
            'multiline',
            'name',
            'onBlur',
            'onChange',
            'onFocus',
            'placeholder',
            'required',
            'rows',
            'rowsMax',
            'select',
            'SelectProps',
            'type',
            'variant'
        ]);

        const errorMessage = this.props.getErrorMessage();
        const value = this.props.getValue() || null;

        return (
            <div className={classes.root}>
                <DatePicker
                    {...importedProps}
                    classes={classes}
                    value={value}
                    onChange={this.changeValue}
                    error={Boolean(errorMessage)}
                    helperText={errorMessage || this.props.helperText}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withFormsy(DatePickerSy));
