import {setUserData, userLogout} from 'auth/store/actions/user.actions';
import { fetchLogin, fetchUserMessage, fetchRefresh, fetchLogout, fetchProfile } from 'api'

export const FETCHING_LOGIN = 'FETCHING_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const getData = () => {
	return {
		type: FETCHING_LOGIN
	}
}

export const getDataSuccess = data => {
	return {
		type: LOGIN_SUCCESS,
		data
	}
}

export const getDataFailure = message => {
	return {
        type: LOGIN_ERROR,
        message: message
	}
}

export const getLogoutSuccess = () => {
	return {
		type: LOGOUT_SUCCESS
	}
}

function saveToken(token) {
    localStorage.setItem('@token', JSON.stringify(token))
}

function saveRefresh(refresh) {
    localStorage.setItem('@refresh', refresh)
}

function saveProfile(profile) {
    localStorage.setItem('@profile', JSON.stringify(profile))
}

export function submitLogin({username, password, refresh}){
    return (dispatch) => {
        dispatch(getData())
        fetchLogin(username, password).then(response => {
            saveToken(response.data)
            saveRefresh(refresh)
            return response
        }).then(response => fetchProfile(response))
        .then(response => {
            saveProfile(response.data)
            dispatch(setUserData(response.data))
            return response
        }).then(response => {
            dispatch(getDataSuccess(response.data))
            return response
        }).catch(() => {
            fetchUserMessage(username, password).then(response => {
                dispatch(getDataFailure(response.data.message))
            }).catch(() => {
                dispatch(getDataFailure('Error de conexión'))
            })
        })
    }
}

export function submitLogout(){
    return (dispatch) =>
        fetchLogout().then(response => {
            localStorage.removeItem('@token')
            return response
        })
        .then(() => {
            dispatch(userLogout())
        })
        .then(() => {
            dispatch(getLogoutSuccess())
        })
}

export function reacceso(){
    return (dispatch) =>
        fetchProfile().then(response => {
            dispatch(setUserData(response.data))
            return response
        }).then(response => {
            dispatch(getDataSuccess(response.data))
        }).catch(() => {
            const refresh = localStorage.getItem('@refresh')
            if (refresh === 'true'){
                dispatch(getData())
                fetchRefresh().then(response => {
                    saveToken(response.data)
                    return response
                }).then(response => fetchProfile(response))
                .then(response => {
                    dispatch(setUserData(response.data))
                    return response
                }).then(response => {
                    dispatch(getDataSuccess(response.data))
                }).catch(() => {
                    dispatch(getDataFailure('Error de conexión'))
                })
            } else {
                dispatch(getDataFailure('Error de conexión'))
            }
        })
}