import { fetchGetYears } from 'api'

export const FETCHING_GET_YEAR = 'FETCHING_GET_YEAR';
export const FETCHING_GET_YEAR_SUCCESS = 'FETCHING_GET_YEAR_SUCCESS';
export const FETCHING_GET_YEAR_FAILURE = 'FETCHING_GET_YEAR_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_GET_YEAR
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_GET_YEAR_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_GET_YEAR_FAILURE,
        error
    }
}

export const getYear = () => {
    return (dispatch) => {
        dispatch(getData())
        fetchGetYears()
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            }).catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}