import * as Actions from '../actions';

const initialState = {
    isFetching : false,
    success: false,
    error: false,
    message: '',
    data: {
        username: null,
        password: null
    }
};

const login = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.FETCHING_LOGIN:
        {
            return {
                ...initialState,
                isFetching : true
            };
        }
        case Actions.LOGIN_SUCCESS:
        {
            return {
                ...initialState,
                isFetching : false,
                success: true,
                data  : action.data
            };
        }
        case Actions.LOGIN_ERROR:
        {
            return {
                isFetching : false,
                success: false,
                error: true,
                message  : action.message
            };
        }
        case Actions.LOGOUT_SUCCESS:
        {
            return initialState;
        }
        default:
        {
            return state
        }
    }
};

export default login;