import * as Actions from '../../actions/solicitude/index';

const initialState = {
    completed: 0,
    canSubmit: false
}

const upload = (state = initialState, action) => {
    switch (action.type) {
        case Actions.ADD_UPLOAD_COMPLETED:
            state.completed++
            return {
                ...state
            }
        case Actions.RESET_UPLOAD_COMPLETED:
        return {
            ...initialState,
            completed: 0,
        }
        default:
            return state
    }
}

export default upload;