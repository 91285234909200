import { fetchDetailProgram } from 'api';

export const FETCHING_DETAIL_PROGRAM = 'FETCHING_DETAIL_PROGRAM';
export const FETCHING_DETAIL_PROGRAM_SUCCESS = 'FETCHING_DETAIL_PROGRAM_SUCCESS';
export const FETCHING_DETAIL_PROGRAM_FAILURE = 'FETCHING_DETAIL_PROGRAM_FAILURE';

export const getData = () => {
    return {
        type: FETCHING_DETAIL_PROGRAM
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_DETAIL_PROGRAM_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_DETAIL_PROGRAM_FAILURE,
        error
    }
}

export const detailProgram = (id) => {
    return (dispatch) => {
        dispatch(getData())
        fetchDetailProgram(id)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response.data))
            })
    }
}