import { fetchSocieconimicAdd } from 'api'


export const FETCHING_ADD_SOCIOECONOMIC = 'FETCHING_ADD_SOCIOECONOMIC';
export const FETCHING_ADD_SOCIOECONOMIC_SUCCESS = 'FETCHING_ADD_SOCIOECONOMIC_SUCCESS';
export const FETCHING_ADD_SOCIOECONOMIC_FAILURE = 'FETCHING_ADD_SOCIOECONOMIC_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_ADD_SOCIOECONOMIC
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_ADD_SOCIOECONOMIC_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_ADD_SOCIOECONOMIC_FAILURE,
        error
    }
}


export const addSocioeconomic = (data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchSocieconimicAdd(data)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}