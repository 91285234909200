import * as Actions from '../../actions/solicitude/index';

const initialState = {
	data : {},
	success: false,
	isFetching : false,
	error : false
}

const save_way = (state=initialState, action) => {
	switch (action.type){
		case Actions.FETCHING_SAVE_WAY_KNOW_SOLICITUDE:
			return {
				...state,
				data: {},
				error: false,
                success: false,
				isFetching: true
			}
		case Actions.FETCHING_SAVE_WAY_KNOW_SOLICITUDE_SUCCESS:
			return {
				...state,
				data: action.data,
                success: true,
				isFetching: false
			}
		case Actions.FETCHING_SAVE_WAY_KNOW_SOLICITUDE_FAILURE:
			return {
				...state,
				data: action.data,
				error: true,
                success: false,
				isFetching: false
			}
		case Actions.RESET_SOLICITUDE_SAVE_WAY_KNOW:
			return {
				...initialState,
				data: {},
			}
		case Actions.RESET_FETCHING_SOLICITUDE_SAVE_WAY_KNOW:
			return {
				...state,
				isFetching: false
			}
		default:
			return state
	} 
}

export default save_way;