import {FuseLoadable} from '@fuse';
import {authRoles} from 'auth';

export const SolicitudeListConfig = {
    settings: {
        layout: {
            config: {
            }
        }
    },
    auth    : authRoles.staff,//['admin']
    routes  : [
        {
            path     : '/solicitudes',
            component: FuseLoadable({
                loader: () => import('./SolicitudeList')
            })
        }
    ]
};