import { fetchBulkLoad } from 'api'

export const FETCHING_BULK_LOAD = 'FETCHING_BULK_LOAD';
export const FETCHING_BULK_LOAD_SUCCESS = 'FETCHING_BULK_LOAD_SUCCESS';
export const FETCHING_BULK_LOAD_FAILURE = 'FETCHING_BULK_LOAD_FAILURE';
export const FETCHING_BULK_LOAD_RESET = 'FETCHING_BULK_LOAD_RESET';

export const getData = () => {
    return {
        type: FETCHING_BULK_LOAD
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_BULK_LOAD_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_BULK_LOAD_FAILURE,
        error
    }
}

export const getReset = () => {
    return {
        type: FETCHING_BULK_LOAD_RESET
    }
}

export const sendBulkLoad = (data) => {
    return (dispatch) => {
        dispatch(getData())
        fetchBulkLoad(data)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}