import { fetchAuthorizePetition } from 'api'

export const FETCHING_PETITION_AUTHORIZE = 'FETCHING_PETITION_AUTHORIZE';
export const FETCHING_PETITION_AUTHORIZE_SUCCESS = 'FETCHING_PETITION_AUTHORIZE_SUCCESS';
export const FETCHING_PETITION_AUTHORIZE_FAILURE = 'FETCHING_PETITION_AUTHORIZE_FAILURE';
export const SET_COMMENT_AUTHORIZE = 'SET_COMMENT_AUTHORIZE';
export const CLEAN_COMMENT_AUTHORIZE = 'CLEAN_COMMENT_AUTHORIZE';

export const getData = () => {
    return {
        type: FETCHING_PETITION_AUTHORIZE
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_PETITION_AUTHORIZE_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_AUTHORIZE_FAILURE,
        error
    }
}

export const set_comment_authorize = e => {
    return {
        type: SET_COMMENT_AUTHORIZE,
        value: e.target.value
    }
}

export const clean_comment_authorize = () => {
    return {
        type: CLEAN_COMMENT_AUTHORIZE
    }
}

export const authorizePetition = (id_petition, comment) => {
    return (dispatch) => {
        dispatch(getData())
        fetchAuthorizePetition(id_petition, comment)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}