import {fetchRevisionPetition} from 'api'

export const FETCHING_PETITION_REVISION = 'FETCHING_PETITION_REVISION';
export const FETCHING_PETITION_REVISION_SUCCESS = 'FETCHING_PETITION_REVISION_SUCCESS';
export const FETCHING_PETITION_REVISION_FAILURE = 'FETCHING_PETITION_REVISION_FAILURE';
export const SET_COMMENT_REVISION = 'SET_COMMENT_REVISION';
export const CLEAN_COMMENT_REVISION = 'CLEAN_COMMENT_REVISION';

export const getData = () => {
    return {
        type: FETCHING_PETITION_REVISION
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_PETITION_REVISION_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_REVISION_FAILURE,
        error
    }
}

export const set_comment_revision = e => {
    return {
        type: SET_COMMENT_REVISION,
        value: e.target.value
    }
}

export const clean_comment_revision = () => {
    return {
        type: CLEAN_COMMENT_REVISION
    }
}

export const revisionPetition = (id_petition, comment) => {
    return (dispatch) => {
        dispatch(getData())
        fetchRevisionPetition(id_petition, comment)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}