import React, { Component } from 'react'
import Formsy from 'formsy-react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles/index';
import { Typography, Icon, Button, InputAdornment, Divider, IconButton, Tooltip } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import * as Actions from 'store/actions';
import * as ActionsLogin from 'auth/store/actions';
import { FuseAnimate, TextFieldFormsy } from '@fuse';
import TextFieldFormsy2 from '../../components/TextFieldFormsy2';
import connect from 'react-redux/es/connect/connect';

const styles = theme => ({
    button: {
        marginLeft: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    color_icon: {
        color: "#F29100"
    },
});

class RegisterForm extends Component {
    state = {
        canSubmit: false,
        type_input: 'password',
        tile_tooltip: 'Mostrar contraseña',
        username: '',
        password: ''
    }

    form = React.createRef();

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    onSubmit = (model) => {
        // this.disableButton();
        this.setState({
            username: model.username,
            password: model.password
        })
        this.props.saveUser(model);
    }

    onBack = () => {
        this.props.history.goBack();
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.register.success !== this.props.register.success) {
            this.props.submitLogin({ username: this.state.username, password: this.state.password })
            const pathname = '/';
            this.props.history.push({
                pathname
            });
        }

    }

    showPass = () => {
        const { type_input } = this.state

        if (type_input === 'password') {
            this.setState({
                type_input: 'text',
                tile_tooltip: 'Ocultar contraseña'
            })
        } else {
            this.setState({
                type_input: 'password',
                tile_tooltip: 'Mostrar contraseña'
            })
        }
    }

    render() {
        const { classes, register } = this.props;
        const { canSubmit, type_input, tile_tooltip } = this.state;
        console.log(register)
        return (
            <div className="flex-1">
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h4" style={{ color: '#616161' }}> <strong>Programas sociales de la Alcaldía Cuauhtémoc</strong></Typography>
                    <br />
                    <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 3 }} />
                </div>
                <br />
                <FuseAnimate animation={{ translateX: [0, '100%'] }}>
                    <div className="m-auto max-w-512">
                        <Typography variant="h6" className="text-center md:w-full mb-24">REGISTRAR</Typography>

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            ref={(form) => this.form = form}
                            className="flex flex-col justify-center w-full"
                        >
                            <TextFieldFormsy
                                className="mb-16"
                                type="text"
                                name="email"
                                label="Correo electrónico"
                                validations="isEmail"
                                validationError="No es un correo válido"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">email</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                            />
                            <TextFieldFormsy2
                                className="mb-16"
                                type="text"
                                name="username"
                                label="Usuario CURP"
                                validations={{
                                    maxLength: 18,
                                    minLength: 18
                                }}
                                validationErrors={{
                                    maxLength: 'La longitud máxima de carácteres es 18',
                                    minLength: 'La longitud mínima de carácteres es 18'
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">person_outline</Icon></InputAdornment>
                                }}
                                variant="outlined"
                                required
                            />
                            <TextFieldFormsy
                                className="mb-4"
                                type={type_input}
                                name="password"
                                label="Contraseña"
                                validations={{
                                    maxLength: 20,
                                    minLength: 4
                                }}
                                validationErrors={{
                                    maxLength: 'La longitud máxima de carácteres es 20',
                                    minLength: 'La longitud mínima de carácteres es 4'
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={tile_tooltip}>
                                                <IconButton
                                                    aria-label="Ayuda"
                                                    onClick={this.showPass}
                                                >
                                                    <i className={classNames(classes.color_icon, "material-icons")}>remove_red_eye </i>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                                variant="outlined"
                                required
                            />



                            {/* {login.error && <div className="flex flex-wrap justify-end">
                                <Typography color="error" className="text-12">
                                    {login.message}
                                </Typography>
                            </div>} */}

                            <div className="flex flex-wrap justify-end">

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={this.onBack}
                                    className={classes.button}
                                    aria-label="ATRAS"
                                    value="legacy"
                                >
                                    ATRAS
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    aria-label="ENTRAR"
                                    disabled={!canSubmit}
                                    value="legacy"
                                >
                                    REGISTRAR
                                </Button>
                            </div>
                        </Formsy>
                    </div>
                </FuseAnimate>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveUser: Actions.saveUser,
        submitLogin: ActionsLogin.submitLogin,

    }, dispatch);
}

function mapStateToProps({ users, auth }) {
    return {
        register: users.register,
        login: auth.login
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterForm)));
