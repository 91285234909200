import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FusePageCarded} from '@fuse';
import HeaderComponent from '../components/HeaderComponent';
import ThanksView from './ThanksView';
import {FuseAnimate} from '@fuse';

const styles = theme => ({
    root     : {
        display   : 'flex',
        alignItems: 'center',
        width     : '100%'
    },
    seperator: {
        width          : 1,
        height         : 64,
        backgroundColor: theme.palette.divider
    },
    layoutRoot: {},
    button: {
        marginTop: theme.spacing.unit
    },
});

class Thanks extends Component {
    render() {
        const {classes} = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <HeaderComponent />
                }
                content={
                    <div className="p-24">
                        <FuseAnimate animation={{translateX: [0, '100%']}}>
                            <ThanksView />
                        </FuseAnimate>
                    </div>
                }
            />
        )
    }
}

export default withStyles(styles, {withTheme: true})(Thanks);