import {combineReducers} from 'redux';
import list from './list.reducer';
import detail from './detail.reducer';
import choice from './choice.reducer';

const programReducers = combineReducers({
    list,
    detail,
    choice
});

export default programReducers;