import * as Actions from '../../actions/solicitude/index';

const initialState = {
    data: [],
    success: false,
    status: '',
    code: '',
    folio_authorize: undefined,
    isFetching: false,
    error: false,
    is_notified: undefined,
    first_name: '',
    last_name: '',
    m_last_name: '',
    email: '',
    phone_number: '',
    full_name: ''
}

const normalize = (str) => {
    var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÇç",
        to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuucc",
        mapping = {};

    for (var i = 0, j = from.length; i < j; i++)
        mapping[from.charAt(i)] = to.charAt(i);

    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
        var c = str.charAt(i);
        if (mapping.hasOwnProperty(str.charAt(i)))
            ret.push(mapping[c]);
        else
            ret.push(c);
    }
    return ret.join('').toUpperCase()
}

const detail = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_PETITION_DETAIL:
            return {
                ...state,
                data: [],
                success: false,
                isFetching: true,
                error: false
            }
        case Actions.FETCHING_PETITION_DETAIL_SUCCESS:
            return {
                ...state,
                data: action.data,
                success: true,
                isFetching: false,
                error: false,
            }
        case Actions.FETCHING_PETITION_DETAIL_FAILURE:
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }
        case Actions.GET_STATUS: {
            return {
                ...state,
                status: action.status
            }
        }
        case Actions.GET_CODE_STATUS: {
            return {
                ...state,
                code: action.code
            }
        }
        case Actions.GET_NAME: {
            return {
                ...state,
                full_name: action.name
            }
        }
        case Actions.SET_NAME: {
            const name = action.name
            return {
                ...state,
                [name]: normalize(action.value)
            }
        }
        case Actions.SET_FORM: {
            const name = action.name
            return {
                ...state,
                [name]: action.value
            }
        }
        case Actions.GET_FOLIO: {
            return {
                ...state,
                folio_authorize: action.folio
            }
        }
        case Actions.GET_IS_NOTIFIED: {
            return {
                ...state,
                is_notified: action.is_notified
            }
        }
        default:
            return state
    }
}

export default detail;