export const SEARCH_FOLIO_BENEFICIARY = 'SEARCH_FOLIO_BENEFICIARY';
export const FILTER_BENEFICIARY = 'FILTER_BENEFICIARY'



export const search_folio_beneficiary = (e) => {
    return {
        type: SEARCH_FOLIO_BENEFICIARY,
        value: e.target.value
    }
}

export const filter_beneficiary = (e) => {
    return {
        type: FILTER_BENEFICIARY,
        value: e.target.value
    }
}