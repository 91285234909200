
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Icon, TextField, Button, Typography, Divider } from '@material-ui/core';
import Autocomplete from '../components/Autocomplete'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import { connect } from 'react-redux';

const styles = theme => ({
    layoutRoot: {},
    textField: {
        flexGrow: 1,
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    selectField: {
        flexGrow: 1,
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    button: {
        margin: theme.spacing.unit
    },
    input: {
        display: 'none'
    }
});

class WayKnowView extends Component {
    state = {
        canSubmit: false,
        way_know: undefined,
        way_know_value: '',
        way_know_text: '',
        comment: ''
    }

    componentDidMount() {
        this.props.listWayKnow()
        this.setState({
            comment: this.props.save.data.comment
        })
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.save_way.success && nextProps.save_way.success !== this.props.save_way.success) {
            const pathname = '/gracias';
            this.props.history.push({
                pathname
            });
        }
    }

    handleChange = (way_know_value) => {
        const { way_know_text } = this.state;
        this.setState({ way_know: way_know_value ? way_know_value.value : undefined });
        this.setState({ way_know_value });
        if (way_know_value && way_know_text !== '') {
            this.setState({ canSubmit: true });
        } else {
            this.setState({ canSubmit: false });
        }
    }

    handleChangeText = (event) => {
        const { way_know } = this.state;
        this.setState({ way_know_text: event.target.value });
        if (way_know && event.target.value !== '') {
            this.setState({ canSubmit: true });
        } else {
            this.setState({ canSubmit: false });
        }
    }

    handleChangeComment = (event) => {
        this.setState({ comment: event.target.value });
    }

    render() {
        const { classes, choice, wayknow, save_way, user } = this.props;
        const { way_know, way_know_value, way_know_text, canSubmit, comment } = this.state;
        console.log(this.state)
        return (
            <div>
                <div className="flex-1 text-center">
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h4" style={{ color: '#616161' }}> <strong>4. ¿Cómo se enteró del programa?</strong></Typography>
                        <Typography variant="h5" style={{ color: '#E61B72' }}> <strong>Programa: {choice.name_program}</strong></Typography>
                        <br />
                        <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 1 }} />
                    </div>
                </div>

                <form onSubmit={this.submitForm} noValidate autoComplete="off">
                    <div className="flex flex-wrap">
                        <Autocomplete
                            name="way_know"
                            placeholder='¿Cómo se enteró del programa?'
                            options={wayknow.data}
                            className={classes.selectField}
                            value={way_know_value}
                            onChange={this.handleChange}
                            required
                            isClearable
                        />
                        <TextField
                            name="way_know_text"
                            label={"Describe brevemente"}
                            margin="normal"
                            variant="outlined"
                            className={classes.textField}
                            value={way_know_text}
                            onChange={this.handleChangeText}
                            required
                            inputProps={{
                                maxLength: 150,
                            }}
                        />
                    </div>
                    <div className="flex flex-wrap">
                        <TextField
                            name="comment"
                            label="Comentario"
                            margin="normal"
                            variant="outlined"
                            className={classes.textField}
                            value={comment}
                            multiline
                            rows={5}
                            fullWidth
                            onChange={this.handleChangeComment}
                        />
                    </div>

                    <div className="flex flex-wrap justify-end">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={(e) => this.props.saveWayKnowSolicitude({ way_know: way_know, way_know_text: way_know_text, comment:comment }, choice.saved.id)}
                            disabled={!canSubmit || save_way.isFetching}
                            aria-label="CONTINUAR"
                            value="legacy"
                        >
                            CONTINUAR
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listWayKnow: Actions.listWayKnow,
        saveWayKnowSolicitude: Actions.saveWayKnowSolicitude
    }, dispatch);
}

function mapStateToProps({ program, catalogs, solicitude, auth }) {
    return {
        user: auth.user,
        choice: program.choice,
        wayknow: catalogs.way_know,
        save_way: solicitude.save_way,
        save: solicitude.save,
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(WayKnowView)));