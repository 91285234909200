import * as Actions from '../../actions/solicitude/index';

const initialState = {
    data: [],
    person_reference_data: null,
    person_reference_value: '',
    success: false,
    isFetching: false,
    error: false
}

const reference_person = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCH_REFERENCE_PERSON: {
            return {
                ...state,
                isFetching: true,
                data: [],
                success: false,
                error: false
            }
        }
        case Actions.FETCH_REFERENCE_PERSON_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                data: action.data,
                success: true,
                error: false
            }
        }
        case Actions.FETCH_REFERENCE_FAILURE: {
            return {
                ...state,
                isFetching: false,
                data: action.error,
                success: false,
                error: true
            }
        }
        case Actions.SET_REFERENCE_PERSON: {
            return {
                ...state,
                person_reference_data: action.data,
                person_reference_value: action.data ? action.data.value : ''
            }
        }
        case Actions.CLEAN_REFERENCE_PERSON: {
            return {
                ...state,
                person_reference_data: null,
                person_reference_value: '',
            }
        }
        default:
            return state
    }
}

export default reference_person;