import * as Actions from '../../actions/solicitude/index';

const initialState = {
    folio_beneficiary: '',
    filter_beneficiary: '',
}

const search = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SEARCH_FOLIO_BENEFICIARY: {
            return {
                ...state,
                folio_beneficiary: action.value
            }
        }
        case Actions.FILTER_BENEFICIARY: {
            return {
                ...state,
                filter_beneficiary: action.value
            }
        }

        default:
            return state
    }
}

export default search;