import { combineReducers } from 'redux';
import list from './list.reducer';
import electoral_sections from './electoral.sections.reducer';
import way_know from './way.know.reducer';
import ethnic from './ethnic_group.reducer';
import program from './program.reducer';
import status from './status.reducer';
import geolocation from './geolocation.reducer';
import get_year from './get.years.reducer';
import roles from './roles.reducer';

const catalogsReducers = combineReducers({
    list,
    electoral_sections,
    way_know,
    ethnic,
    program,
    status,
    geolocation,
    get_year,
    roles
});

export default catalogsReducers;