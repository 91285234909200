import * as Actions from '../../actions/users/index';

const initialState = {
    data: undefined,
    success: false,
    isFetching: false,
    error: false
}

const update_program_role_user = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_UPDATE_PROGRAM_ROLE:
            return {
                ...state,
                data: undefined,
                error: false,
                success: false,
                isFetching: true
            }
        case Actions.FETCHING_UPDATE_PROGRAM_ROLE_SUCCESS:
            return {
                ...state,
                data: action.data,
                success: true,
                isFetching: false
            }
        case Actions.FETCHING_UPDATE_PROGRAM_ROLE_FAILURE:
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }
        default:
            return state
    }
}

export default update_program_role_user;