import { fetchWayKnow } from 'api'

export const FETCHING_WAY_KNOW = 'FETCHING_WAY_KNOW';
export const FETCHING_WAY_KNOW_SUCCESS = 'FETCHING_WAY_KNOW_SUCCESS';
export const FETCHING_WAY_KNOW_FAILURE = 'FETCHING_WAY_KNOW_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_WAY_KNOW
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_WAY_KNOW_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_WAY_KNOW_FAILURE,
        error
    }
}

export const listWayKnow = () => {
    return (dispatch) => {
        dispatch(getData())
        fetchWayKnow()
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            }).catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}