
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Icon, Button, Typography, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import { connect } from 'react-redux';
import Config from '../../../config'

const styles = theme => ({
    layoutRoot: {},
    button: {
        margin: theme.spacing.unit
    },
    input: {
        display: 'none'
    }
});

class ThanksView extends Component {
    state = {
        open: false
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    onNext = () => {
        this.props.clean_program_choice();
        this.props.resetSolicitudeForm();
        this.props.resetSolicitudeSave();
        const pathname = '/programas';
        this.props.history.push({
            pathname
        })
    }

    render() {
        const { choice, finalize } = this.props;
        console.log(finalize)
        return (
            <div>
                <div className="flex-1 text-center">
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h4" style={{ color: '#616161' }}> <strong>5. Finalizar</strong></Typography>
                        <Typography variant="h5" style={{ color: '#E61B72' }}> <strong>Programa: {choice.name_program}</strong></Typography>
                        <br />
                        <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 1 }} />
                    </div>
                    <br />
                    <div className="inline-flex min-h-224">
                        <div className="mt-92">
                            <Typography color="inherit" className="font-bold text-24 sm:text-24 md:text-32 lg:text-40 xl:text-48">
                                Se ha completado el registro de su solicitud.
                            </Typography>
                            {choice.saved && <Typography color="inherit" className="font-bold text-grey-darkest text-18">
                                {'Programa: ' + choice.name_program}
                            </Typography>}
                            {choice.saved && <Typography color="inherit" className="font-bold text-grey-darkest text-18">
                                {'Solicitante: ' + choice.saved.beneficiarios[0].first_name + ' ' + choice.saved.beneficiarios[0].last_name + ' ' + choice.saved.beneficiarios[0].m_last_name}
                            </Typography>}
                            {choice.saved && <Typography color="inherit" className="font-bold text-grey-darkest text-18">
                                {'Número de solicitud: ' + finalize.data.register_folio}
                            </Typography>}
                            <br />
                            <Icon color="primary" className="text-48">cloud_download</Icon>
                            <Typography variant='subtitle1'>Por favor, descargue  o imprima los siguientes documentos:</Typography>
                            <br />
                            {/* Para todod */}
                            {/* {choice.saved && <Typography variant="title"> <a href={Config.client_url + 'reports/solicitud/?peticion_id=' + choice.saved.id} target="_blanck">Formato de solicitud de registro</a></Typography>} */}

                            {choice.saved && <Typography variant="title"><a href={Config.client_url + 'reports/escrito/?peticion_id=' + choice.saved.id} target="_blanck">Escrito de solicitud</a></Typography>}

                            {/* Para todos */}

                            {/* Infancia indigena */}
                            {/* {choice.saved ? (choice.data.code === 'P0004' ? (<Typography variant="title"><a href={Config.client_url + 'reports/carta_compromiso/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso</a></Typography>) : null) : null} */}

                            {/* Infancia indigena */}

                            {/* Jovenes deportistas */}
                            {/* {choice.saved ? (choice.data.code === 'P0006' ? (<Typography variant="title"><a href={Config.client_url + 'reports/padre_madre_tutor/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso manifestación de ser el padre madre o tutor del solicitante</a></Typography>) : null) : null} */}

                            {/* {choice.saved ? (choice.data.code === 'P0006' ? (<Typography variant="title"><a href={Config.client_url + 'reports/permanencia_alumno/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso de permanencia del alumno en sus estudios en el nivel escolar correspondiente</a></Typography>) : null) : null} */}

                            {/* {choice.saved ? (choice.data.code === 'P0006' ? (<Typography variant="title"><a href={Config.client_url + 'reports/asistencia_escuelas/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso de asistencia a las Escuelas Técnico Deportivas </a></Typography>) : null) : null} */}

                            {/* {choice.saved ? (choice.data.code === 'P0006' ? (<Typography variant="title"><a href={Config.client_url + 'reports/competencias_trimestrales/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso de asistencia a competencias trimestrales </a></Typography>) : null) : null} */}

                            {/* jovenes deportistas */}

                            {/* Cuidadoras */}
                            {/* {choice.saved ? (choice.data.code === 'P0003' ? (<Typography variant="title"><a href={Config.client_url + 'reports/apoyo_economico/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso del programa social de apoyo económico para el reconocimiento de las personas cuidadoras </a></Typography>) : null) : null} */}

                            {/* {choice.saved ? (choice.data.code === 'P0003' ? (<Typography variant="title"><a href="https://trello-attachments.s3.amazonaws.com/5c4739465c568781459b553f/5c527988d59f1f0c68c5dbf9/79c3372dec94dc64eb5f943f3dfc378d/CARTA_DE_DECLARACI%C3%93N_BAJO_PROTESTA_DE_ESTAR_AL_CUIDADO_DE.pdf" target="_blanck">Carta de declaración bajo protesta de estar al cuidado de un menor </a></Typography>) : null) : null} */}

                            {/* Cuidadoras */}

                            {/* Embarazadas */}
                            {/* {choice.saved ? (choice.data.code === 'P0005' ? (<Typography variant="title"><a href={Config.client_url + 'reports/embarazadas/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso del programa social de apoyo económico para jóvenes y adolescentes embarazadas </a></Typography>) : null) : null} */}

                            {/* Embarazadas */}

                            {/* PACI */}
                            {/* {choice.saved ? (choice.data.code === 'P0010' ? (<Typography variant="title"><a href={Config.client_url + 'reports/carta_responsiva/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso del programa social de apoyo económico para jóvenes y adolescentes embarazadas </a></Typography>) : null) : null} */}

                            {/* {choice.saved ? (choice.data.code === 'P0010' ? (<Typography variant="title"><a href={Config.client_url + 'reports/carta_protesta/?peticion_id=' + choice.saved.id} target="_blanck">Carta compromiso del programa social de apoyo económico para jóvenes y adolescentes embarazadas </a></Typography>) : null) : null} */}

                            {/* PACI */}

                            {/* BAC */}
                            {/* {choice.saved ? (choice.data.code === 'P0008' ? (<Typography variant="title"><a href="https://trello-attachments.s3.amazonaws.com/5c4739465c568781459b553f/5c527a1a10f03c65ff03023d/cd9ce9ede298d34979992125cc10fb7f/Carta_Responsiva_bac_y_alcaldia.pdf" target="_blanck">Carta responsiva BAC y alcaldia</a></Typography>) : null) : null} */}
                            {/* BAC */}

                        </div>
                    </div>
                </div>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Finalizar..."}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Ha descargado su Escrito de solicitud?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose} variant="outlined" color="secondary">
                        No
                    </Button>
                    <Button onClick={this.onNext} variant="outlined" color="primary" autoFocus>
                        Lo tengo
                    </Button>
                    </DialogActions>
                </Dialog>

                <div className="m-auto">
                    <div className='flex-1'>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleClickOpen}
                            className="max-w-160 mr-6 mt-16 mb-16 normal-case float-right"
                            aria-label="SALIR"
                            value="legacy"
                        >
                            FINALIZAR
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        resetSolicitudeForm: Actions.resetSolicitudeForm,
        clean_program_choice: Actions.clean_program_choice,
        resetUploadCompleted: Actions.resetUploadCompleted,
        resetSolicitudeSave: Actions.resetSolicitudeSave
    }, dispatch);
}

function mapStateToProps({ program, solicitude }) {
    return {
        choice: program.choice,
        finalize: solicitude.finalize
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ThanksView)));