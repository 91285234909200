import { combineReducers } from 'redux';
import register from './register.reducer';
import edit from './edit.reducer';
import list from './list.reducer';
import is_active from './is.active.reducer';
import update_program_role_user from './update.program.role.reducer';


const usersReducers = combineReducers({
    register,
    edit,
    list,
    is_active,
    update_program_role_user
});

export default usersReducers;