import { fetchPetitionFinalize } from 'api'

export const FETCHING_FINALIZE_SOLICITUDE = 'FETCHING_FINALIZE_SOLICITUDE';
export const FETCHING_FINALIZE_SOLICITUDE_SUCCESS = 'FETCHING_FINALIZE_SOLICITUDE_SUCCESS';
export const FETCHING_FINALIZE_SOLICITUDE_FAILURE = 'FETCHING_FINALIZE_SOLICITUDE_FAILURE';
export const RESET_FETCHING_SOLICITUDE_FINALIZE = 'RESET_FETCHING_SOLICITUDE_FINALIZE';
export const RESET_SOLICITUDE_FINALIZE = 'RESET_SOLICITUDE_FINALIZE';


export const getData = () => {
	return {
		type: FETCHING_FINALIZE_SOLICITUDE
	}
}

export const getDataSuccess = data => {
	return {
		type: FETCHING_FINALIZE_SOLICITUDE_SUCCESS,
		data
	}
}

export const getDataFailure = data => {
	return {
		type: FETCHING_FINALIZE_SOLICITUDE_FAILURE,
		data
	}
}

export const finalizeSolicitude = (data) => {
	return (dispatch) => {
		dispatch(getData())
		fetchPetitionFinalize(data).then(data => {
			dispatch(getDataSuccess(data.data))
		}).catch(error => {
			dispatch(getDataFailure(error.response ? error.response.data : {}))
		}).finally(() => {
			dispatch(resetFetching())
		})
	}
}

export function resetSolicitudeFinalize(){
    return {
        type: RESET_SOLICITUDE_FINALIZE
    }
}

export function resetFetching(){
    return {
        type: RESET_FETCHING_SOLICITUDE_FINALIZE
    }
}