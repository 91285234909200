import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageCarded } from '@fuse';
import { LinearProgress } from '@material-ui/core';
import HeaderComponent from '../../components/HeaderComponent';
import { FuseAnimate } from '@fuse';
import RegisterForm from './RegisterForm';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


const styles = theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        padding: 24,
    },
    leftIcon: {
        marginRight: theme.spacing.unit
    },
    rightIcon: {
        marginLeft: theme.spacing.unit
    },
    iconSmall: {
        fontSize: 20
    },
    layoutRoot: {}
});

class Register extends Component {
    render() {
        const { classes, register } = this.props;

        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <HeaderComponent />
                }
                content={
                    <div>
                        {register.isFetching && <LinearProgress color="primary" />}
                        <FuseAnimate animation={{ translateX: [0, '100%'] }}>
                            <div className="p-24">
                                <RegisterForm />
                            </div>
                        </FuseAnimate>
                    </div>
                }
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({ users }) {
    return {
        register: users.register
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(Register)));