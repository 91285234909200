export const SET_SOLICITUDE_ID = 'SET_SOLICITUDE_ID';
export const SET_PROGRAM_FORM = 'SET_PROGRAM_FORM';
export const RESET_FULL_FORM = 'RESET_FULL_FORM';
export const RESET_SOLICITUDE_FORM = 'RESET_SOLICITUDE_FORM';
export const RESET_BENEFICIARY_FORM = 'RESET_BENEFICIARY_FORM';
export const RESET_DEPENDANT_FORM = 'RESET_DEPENDANT_FORM';
export const RESET_BENEFICIARY_USER_TRANS_FORM = 'RESET_BENEFICIARY_USER_TRANS_FORM';
export const RESET_DEPENDENT_ETHNIC_FORM = 'RESET_DEPENDENT_ETHNIC_FORM';
export const ADD_BENEFICIARY_FORM = 'ADD_BENEFICIARY_FORM';
export const ADD_BENEFICIARY_USER_TRANS_FORM = 'ADD_BENEFICIARY_USER_TRANS_FORM';
export const ADD_BENEFICIARY_TRANS_FORM = 'ADD_BENEFICIARY_TRANS_FORM';
export const ADD_BENEFICIARY_USER_FORM = 'ADD_BENEFICIARY_USER_FORM';
export const ADD_DEPENDENT_FORM = 'ADD_DEPENDENT_FORM';
export const REMOVE_BENEFICIARY_FORM = 'REMOVE_BENEFICIARY_FORM';
export const UPDATE_BENEFICIARY_TEXT_FORM = 'UPDATE_BENEFICIARY_TEXT_FORM';
export const SET_BENEFICIARY_SELECT_FORM = 'SET_BENEFICIARY_SELECT_FORM';
export const SET_RELATION_SHIP_SELECT_FORM = 'SET_RELATION_SHIP_SELECT_FORM';
export const SET_GENDER_TRANS_SELECT_FORM = 'SET_GENDER_TRANS_SELECT_FORM';
export const SET_ETHNIC_SELECT_FORM = 'SET_ETHNIC_SELECT_FORM';
export const SET_ELECTORAL_SECTION_SELECT_FORM = 'SET_ELECTORAL_SECTION_SELECT_FORM';
export const ADD_DEPENDENT_ETHNIC_FORM = 'ADD_DEPENDENT_ETHNIC_FORM';
export const ADD_PEOPLE_CARE_FORM = 'ADD_PEOPLE_CARE_FORM'
export const SET_PEOPLE_CARE_FORM = 'SET_PEOPLE_CARE_FORM'
export const SET_COMMENT_FORM = 'SET_COMMENT_FORM'


export function setSolicitudeId(id) {
    return {
        type: SET_SOLICITUDE_ID,
        id: id
    }
}

export function setProgramForm(data) {
    return {
        type: SET_PROGRAM_FORM,
        data: data
    }
}

export function resetSolicitudeForm() {
    return {
        type: RESET_SOLICITUDE_FORM
    }
}

export function resetFullForm(curp) {
    return {
        type: RESET_FULL_FORM,
        curp: curp
    }
}

export function resetBeneficiaryForm(index) {
    return {
        type: RESET_BENEFICIARY_FORM,
        index: index
    }
}

export const resetBeneficiaryTransForm = (index) => {
    return {
        type: RESET_BENEFICIARY_USER_TRANS_FORM,
        index: index
    }
}

export function resetDependantForm(index) {
    return {
        type: RESET_DEPENDANT_FORM,
        index: index
    }
}

export function resetDependentEthnicForm(index) {
    return {
        type: RESET_DEPENDENT_ETHNIC_FORM,
        index: index
    }
}

export function addBeneficiaryUserForm(data, user) {
    return {
        type: ADD_BENEFICIARY_USER_FORM,
        data: data,
        user: user
    }
}

export function addBeneficiaryForm(data) {
    return {
        type: ADD_BENEFICIARY_FORM,
        data: data
    }
}

export function addBeneficiaryUserTransForm(data, user) {
    return {
        type: ADD_BENEFICIARY_USER_TRANS_FORM,
        data: data,
        user: user
    }
}

export function addBeneficiaryTransForm(data) {
    return {
        type: ADD_BENEFICIARY_TRANS_FORM,
        data: data
    }
}


export function addDependentEthnicForm(data) {
    return {
        type: ADD_DEPENDENT_ETHNIC_FORM,
        data: data,
    }
}

export function addDependentForm(data) {
    return {
        type: ADD_DEPENDENT_FORM,
        data: data
    }
}

export function removeBeneficiaryForm(index, data) {
    return {
        type: REMOVE_BENEFICIARY_FORM,
        index: index,
        data: data
    }
}

export function updateBeneficiaryTextForm(event, index) {
    return {
        type: UPDATE_BENEFICIARY_TEXT_FORM,
        target: event.target,
        index: index
    }
}

export function setBeneficiarySelectForm(value, index) {
    return {
        type: SET_BENEFICIARY_SELECT_FORM,
        value: value,
        index: index
    }
}


export const setRelationShipSelectForm = (value, index) => {
    return {
        type: SET_RELATION_SHIP_SELECT_FORM,
        value: value,
        index: index
    }
}

export const setElectoralSectionForm = (value, index) => {
    return {
        type: SET_ELECTORAL_SECTION_SELECT_FORM,
        value: value,
        index: index
    }
}

export const setGenderTransSelectForm = (value, index) => {
    return {
        type: SET_GENDER_TRANS_SELECT_FORM,
        value: value,
        index: index
    }
}

export const setEthnicSelectForm = (value, index) => {
    return {
        type: SET_ETHNIC_SELECT_FORM,
        value: value,
        index: index
    }
}

export const setCommentForm = (value) => {
    return {
        type: SET_COMMENT_FORM,
        value: value
    }
}

export const addPeopleCareForm = () => {
    return {
        type: ADD_PEOPLE_CARE_FORM
    }
}

export const setPeopleCareForm = (value) => {
    return {
        type: SET_PEOPLE_CARE_FORM,
        value: value
    }
}