
import React, { Component } from 'react';
import { Icon, Tooltip, Button, Typography, withStyles, Divider, AppBar, Toolbar, IconButton, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import { connect } from 'react-redux';
import green from '@material-ui/core/colors/green';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse';
import Select2 from '../../components/Select2'
import CheckoxFormsy from '../../components/CheckboxFormsy';
import classNames from 'classnames';
import TextUFormsy from '../../components/TextUFormsy'
import CircularProgress from '@material-ui/core/CircularProgress';

const opciones__general_indigena = [
    { value: 'NO', label: 'No' }
]

const opciones_general = [
    { value: 'SI', label: 'SI' },
    { value: 'NO', label: 'No' }
]

const opciones_servicio_salud = [
    { value: 'IMSS', label: 'IMSS' },
    { value: 'ISSSTE', label: 'ISSSTE' },
    { value: 'INSABI', label: 'INSABI (ANTES SEGURO POPULAR)' },
    { value: 'PARTICULAR', label: 'PARTICULAR' },
    { value: 'OTRO', label: 'OTRO' }
]

const opciones_gastos_medicos = [
    { value: '$0', label: '$0' },
    { value: 'MENOS DE $500', label: 'MENOS DE $500' },
    { value: 'MAS DE $600 Y MENOS DE $1000', label: 'MAS DE $600 Y MENOS DE $1000' },
    { value: 'MAS DE $1000', label: 'MAS DE $1000' },
]

const opciones_actividad_principal = [
    { value: 'TRABAJA, EMPLEO FORMAL', label: 'TRABAJA, EMPLEO FORMAL' },
    { value: 'TRABAJA, EMPLEO INFORMAL', label: 'TRABAJA, EMPLEO INFORMAL' },
    {value: 'DEPORTISTA',label : 'DEPORTISTA'},
    {value: 'ESTUDIANTE',label : 'ESTUDIANTE'},
    { value: 'HOGAR', label: 'HOGAR' },
    { value: 'OTRO', label: 'OTRO' }
]

const opciones_parentesco = [
    { value: 'NIETO/A', label: 'NIETO/A' },
    { value: 'SOBRINO/A', label: 'SOBRINO/A' },
    { value: 'AHIJADO/A', label: 'AHIJADO/A' },
    { value: 'HIJO/A', label: 'HIJO/A' },
    { value: 'ESPOSO/A', label: 'ESPOSO/A' },
    { value: 'MADRE', label: 'MADRE' },
    { value: 'PADRE', label: 'PADRE' },
    { value: 'ABUELO/A', label: 'ABUELO/A' },
    { value: 'HERMANO/A', label: 'HERMANO/A' }
]

const opciones_ingreso_mensual = [
    { value: 'DE $2000 A $3000', label: 'DE $2000 A $3000' },
    { value: 'DE $4000 A $5000', label: 'DE $4000 A $5000' },
    { value: 'MAS DE $6000', label: 'MAS DE $6000' },
]


const styles = theme => ({
    layoutRoot: {},
    textField: {
        flexGrow: 1,
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    button: {
        marginLeft: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    toolbar: {
        zIndex: 10,
        marginLeft: theme.spacing.unit,
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        // button: '50%',
        top: '50%',
        left: '70%',
        marginTop: -12,
        marginLeft: -12,
    },
    load: {
        position: 'absolute',
        marginTop: 30,
        marginLeft: -20,
    },
});


class SocioeconomicProfileForm extends Component {
    state = {
        disabled: false,
        isFormValid: false,
        form_dependent: [],
        form_gasto_mensual: [],
        data_found: []
    }

    // componentWillUnmount() {
    //     this.props.resetSolicitudeForm();
    // }

    componentDidMount() {
        const { curp_search, save } = this.props
        const beneficiario_directo = save.data.beneficiarios.find(directo => directo.tipo_beneficiario === "directo")

        this.props.relationShipList()
        if (curp_search.data.found) {
            var result = []
            if (this.props.program.data.code === 'BI' || this.props.program.data.code === 'BD') {
                result = []
            } else {
                result = this.props.curp_search.data.data.beneficiarios.filter(beneficiario => beneficiario.curp !== beneficiario_directo.curp)
            }
            const result_gasto = this.props.curp_search.data.data.socioeconomic_profile.monthly_expense
            this.setState({
                form_dependent: result,
                form_gasto_mensual: result_gasto
            })
        }
        // console.log('devuelve ->>',curp_search)
        console.log("%cdevuelve", "color:green", curp_search)
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.add_socioeconomic.success && nextProps.add_socioeconomic.success !== this.props.add_socioeconomic.success) {
            const pathname = '/adjuntos';
            this.props.history.push({
                pathname
            });
        }

        if (nextProps.update_socioeconomic.success && nextProps.update_socioeconomic.success !== this.props.update_socioeconomic.success) {
            const pathname = '/adjuntos';
            this.props.history.push({
                pathname
            });
        }
    }

    submitForm = (values) => {
        console.group()
        console.log(values)
        const { curp_search, addSocioeconomic, updateSocioeconomic } = this.props
        // normalizacion de los datos de dependientes
        var dependientes = []
        if (values.beneficiaries !== undefined) {
            values.beneficiaries.map((ben) => {
                (ben.id === null || ben.id === undefined) ?
                    dependientes.push({
                        email: ben.email,
                        phone_type: ben.phone_type,
                        phone_number: ben.phone_number,
                        ethnic_group: ben.ethnic_group,
                        curp: ben.curp,
                        birth_date: ben.birth_date,
                        gender: ben.gender,
                        relationship: ben.relationship.value,
                        first_name: ben.first_name,
                        last_name: ben.last_name,
                        m_last_name: ben.m_last_name !== undefined ? ben.m_last_name : '',
                        diase: ben.diase.value,
                        disability: ben.disability.value,
                        which_diase: ben.which_diase !== undefined ? ben.which_diase : '',
                        motor_disability: ben.motor_disability === undefined ? false : ben.motor_disability,
                        visual_disability: ben.visual_disability === undefined ? false : ben.visual_disability,
                        hearing_disability: ben.hearing_disability === undefined ? false : ben.hearing_disability,
                        intellectual_disability: ben.intellectual_disability === undefined ? false : ben.intellectual_disability,
                        psychosial_disability: ben.psychosial_disability === undefined ? false : ben.psychosial_disability,
                        studies: {
                            doctorado: ben.studies.doctorado,
                            maestria: ben.studies.maestria,
                            ninguno: ben.studies.ninguno,
                            preparatoria: ben.studies.preparatoria,
                            primaria: ben.studies.primaria,
                            sabe_escribir: ben.studies.sabe_escribir,
                            sabe_hacercuentas: ben.studies.sabe_hacercuentas,
                            sabe_leer: ben.studies.sabe_leer,
                            secundaria: ben.studies.secundaria,
                            universidad: ben.studies.universidad,
                        },
                        sickness: {
                            artritis: ben.sickness.artritis === undefined ? false : ben.sickness.artritis,
                            cancer: ben.sickness.cancer === undefined ? false : ben.sickness.cancer,
                            diabetes: ben.sickness.diabetes === undefined ? false : ben.sickness.diabetes,
                            enfermedad_renal: ben.sickness.enfermedad_renal === undefined ? false : ben.sickness.enfermedad_renal,
                            epoc: ben.sickness.epoc === undefined ? false : ben.sickness.epoc,
                            esclerosis_multiple: ben.sickness.esclerosis_multiple === undefined ? false : ben.sickness.esclerosis_multiple,
                            gonartrosis: ben.sickness.gonartrosis === undefined ? false : ben.sickness.gonartrosis,
                            hipertension: ben.sickness.hipertension === undefined ? false : ben.sickness.hipertension,
                            obesidad: ben.sickness.obesidad === undefined ? false : ben.sickness.obesidad,
                            osteoporosis: ben.sickness.osteoporosis === undefined ? false : ben.sickness.osteoporosis,
                            otra: ben.sickness.otra === undefined ? false : ben.sickness.otra,
                            otra_enfermedad: ben.sickness.otra_enfermedad === undefined ? '' : ben.sickness.otra_enfermedad,
                            vih: ben.sickness.vih === undefined ? false : ben.sickness.vih,
                        }
                    })
                    :
                    dependientes.push({
                        id: ben.id,
                        email: ben.email,
                        phone_type: ben.phone_type,
                        phone_number: ben.phone_number,
                        ethnic_group: ben.ethnic_group,
                        curp: ben.curp,
                        birth_date: ben.birth_date,
                        gender: ben.gender,
                        relationship: ben.relationship.value,
                        first_name: ben.first_name,
                        last_name: ben.last_name,
                        m_last_name: ben.m_last_name !== undefined ? ben.m_last_name : '',
                        diase: ben.diase.value,
                        disability: ben.disability.value,
                        which_diase: ben.which_diase !== undefined ? ben.which_diase : '',
                        motor_disability: ben.motor_disability === undefined ? false : ben.motor_disability,
                        visual_disability: ben.visual_disability === undefined ? false : ben.visual_disability,
                        hearing_disability: ben.hearing_disability === undefined ? false : ben.hearing_disability,
                        intellectual_disability: ben.intellectual_disability === undefined ? false : ben.intellectual_disability,
                        psychosial_disability: ben.psychosial_disability === undefined ? false : ben.psychosial_disability,
                        studies: {
                            id: ben.studies.id,
                            doctorado: ben.studies.doctorado,
                            maestria: ben.studies.maestria,
                            ninguno: ben.studies.ninguno,
                            preparatoria: ben.studies.preparatoria,
                            primaria: ben.studies.primaria,
                            sabe_escribir: ben.studies.sabe_escribir,
                            sabe_hacercuentas: ben.studies.sabe_hacercuentas,
                            sabe_leer: ben.studies.sabe_leer,
                            secundaria: ben.studies.secundaria,
                            universidad: ben.studies.universidad,
                        },
                        sickness: {
                            id: ben.sickness.id,
                            artritis: ben.sickness.artritis === undefined ? false : ben.sickness.artritis,
                            cancer: ben.sickness.cancer === undefined ? false : ben.sickness.cancer,
                            diabetes: ben.sickness.diabetes === undefined ? false : ben.sickness.diabetes,
                            enfermedad_renal: ben.sickness.enfermedad_renal === undefined ? false : ben.sickness.enfermedad_renal,
                            epoc: ben.sickness.epoc === undefined ? false : ben.sickness.epoc,
                            esclerosis_multiple: ben.sickness.esclerosis_multiple === undefined ? false : ben.sickness.esclerosis_multiple,
                            gonartrosis: ben.sickness.gonartrosis === undefined ? false : ben.sickness.gonartrosis,
                            hipertension: ben.sickness.hipertension === undefined ? false : ben.sickness.hipertension,
                            obesidad: ben.sickness.obesidad === undefined ? false : ben.sickness.obesidad,
                            osteoporosis: ben.sickness.osteoporosis === undefined ? false : ben.sickness.osteoporosis,
                            otra: ben.sickness.otra === undefined ? false : ben.sickness.otra,
                            otra_enfermedad: ben.sickness.otra_enfermedad === undefined ? '' : ben.sickness.otra_enfermedad,
                            vih: ben.sickness.vih === undefined ? false : ben.sickness.vih,
                        }
                    })
            })
        }

        // Normalizacion de los datos de aportadores mensual
        var aportadores = []
        if (values.monthly_expense !== undefined) {
            values.monthly_expense.map((aporta) => {
                aporta.id === null ?
                    aportadores.push({
                        relationship: aporta.relationship.value,
                        contributor_name: aporta.contributor_name
                    })
                    :
                    aportadores.push({
                        id: aporta.id,
                        relationship: aporta.relationship.value,
                        contributor_name: aporta.contributor_name
                    })
            })
        }

        // normalizacion del formulario general
        let studies = {}
        let sickness = {}
        if (this.props.save.data.socioeconomic_profile.id !== null) {
            studies = {
                id: values.solicitante.studies.id,
                doctorado: values.solicitante.studies.doctorado,
                maestria: values.solicitante.studies.maestria,
                ninguno: values.solicitante.studies.ninguno,
                preparatoria: values.solicitante.studies.preparatoria,
                primaria: values.solicitante.studies.primaria,
                sabe_escribir: values.solicitante.studies.sabe_escribir,
                sabe_hacercuentas: values.solicitante.studies.sabe_hacercuentas,
                sabe_leer: values.solicitante.studies.sabe_leer,
                secundaria: values.solicitante.studies.secundaria,
                universidad: values.solicitante.studies.universidad,
            }
            sickness = {
                id: values.solicitante.sickness.id,
                artritis: values.solicitante.sickness.artritis === undefined ? false : values.solicitante.sickness.artritis,
                cancer: values.solicitante.sickness.cancer === undefined ? false : values.solicitante.sickness.cancer,
                diabetes: values.solicitante.sickness.diabetes === undefined ? false : values.solicitante.sickness.diabetes,
                enfermedad_renal: values.solicitante.sickness.enfermedad_renal === undefined ? false : values.solicitante.sickness.enfermedad_renal,
                epoc: values.solicitante.sickness.epoc === undefined ? false : values.solicitante.sickness.epoc,
                esclerosis_multiple: values.solicitante.sickness.esclerosis_multiple === undefined ? false : values.solicitante.sickness.esclerosis_multiple,
                gonartrosis: values.solicitante.sickness.gonartrosis === undefined ? false : values.solicitante.sickness.gonartrosis,
                hipertension: values.solicitante.sickness.hipertension === undefined ? false : values.solicitante.sickness.hipertension,
                obesidad: values.solicitante.sickness.obesidad === undefined ? false : values.solicitante.sickness.obesidad,
                osteoporosis: values.solicitante.sickness.osteoporosis === undefined ? false : values.solicitante.sickness.osteoporosis,
                otra: values.solicitante.sickness.otra === undefined ? false : values.solicitante.sickness.otra,
                otra_enfermedad: values.solicitante.sickness.otra_enfermedad === undefined ? '' : values.solicitante.sickness.otra_enfermedad,
                vih: values.solicitante.sickness.vih === undefined ? false : values.solicitante.sickness.vih,
            }
        } else {
            studies = {
                doctorado: values.solicitante.studies.doctorado,
                maestria: values.solicitante.studies.maestria,
                ninguno: values.solicitante.studies.ninguno,
                preparatoria: values.solicitante.studies.preparatoria,
                primaria: values.solicitante.studies.primaria,
                sabe_escribir: values.solicitante.studies.sabe_escribir,
                sabe_hacercuentas: values.solicitante.studies.sabe_hacercuentas,
                sabe_leer: values.solicitante.studies.sabe_leer,
                secundaria: values.solicitante.studies.secundaria,
                universidad: values.solicitante.studies.universidad,
            }
            sickness = {
                artritis: values.solicitante.sickness.artritis === undefined ? false : values.solicitante.sickness.artritis,
                cancer: values.solicitante.sickness.cancer === undefined ? false : values.solicitante.sickness.cancer,
                diabetes: values.solicitante.sickness.diabetes === undefined ? false : values.solicitante.sickness.diabetes,
                enfermedad_renal: values.solicitante.sickness.enfermedad_renal === undefined ? false : values.solicitante.sickness.enfermedad_renal,
                epoc: values.solicitante.sickness.epoc === undefined ? false : values.solicitante.sickness.epoc,
                esclerosis_multiple: values.solicitante.sickness.esclerosis_multiple === undefined ? false : values.solicitante.sickness.esclerosis_multiple,
                gonartrosis: values.solicitante.sickness.gonartrosis === undefined ? false : values.solicitante.sickness.gonartrosis,
                hipertension: values.solicitante.sickness.hipertension === undefined ? false : values.solicitante.sickness.hipertension,
                obesidad: values.solicitante.sickness.obesidad === undefined ? false : values.solicitante.sickness.obesidad,
                osteoporosis: values.solicitante.sickness.osteoporosis === undefined ? false : values.solicitante.sickness.osteoporosis,
                otra: values.solicitante.sickness.otra === undefined ? false : values.solicitante.sickness.otra,
                otra_enfermedad: values.solicitante.sickness.otra_enfermedad === undefined ? '' : values.solicitante.sickness.otra_enfermedad,
                vih: values.solicitante.sickness.vih === undefined ? false : values.solicitante.sickness.vih,
            }
        }

        let data = {
            solicitante: {
                id: values.solicitante.id,
                diase: values.solicitante.diase.value,
                disability: values.solicitante.disability.value,
                which_diase: values.which_diase !== undefined ? values.solicitante.which_diase : '',
                motor_disability: values.solicitante.motor_disability === undefined ? false : values.solicitante.motor_disability,
                visual_disability: values.solicitante.visual_disability === undefined ? false : values.solicitante.visual_disability,
                hearing_disability: values.solicitante.hearing_disability === undefined ? false : values.solicitante.hearing_disability,
                intellectual_disability: values.solicitante.intellectual_disability === undefined ? false : values.solicitante.intellectual_disability,
                psychosial_disability: values.solicitante.psychosial_disability === undefined ? false : values.solicitante.psychosial_disability,
                studies: studies,
                sickness: sickness
            },
            petition: values.petition,
            height: values.height !== undefined ? values.height : '',
            weight: values.weight !== undefined ? values.weight : '',
            health_service: values.health_service.value,
            medical_expenses: values.medical_expenses.value,
            major_activity: values.major_activity.value,
            financially_dependent: values.financially_dependent.value,
            monthly_family_income: values.monthly_family_income.value,
            support_another_institution: values.support_another_institution.value,
            which_health_service: values.which_health_service !== undefined ? values.which_health_service.value : '',
            other_health_service: values.other_health_service !== undefined ? values.other_health_service : '',
            other_major_activity: values.other_major_activity !== undefined ? values.other_major_activity : '',
            beneficiaries: dependientes,
            monthly_expense: aportadores,
            type_support: values.type_support !== undefined ? values.type_support : ''
        }
        console.log("%cSubmit", "color:pink", data)
        if (curp_search.data.found) {
            console.log("search_curp", curp_search)
            if (curp_search.data.data.socioeconomic_profile.id !== null) {
                console.log('update_socioeconomic')
                updateSocioeconomic(curp_search.data.data.socioeconomic_profile.id, data)
            } else {
                console.log('create_socioeconomic_2')
                addSocioeconomic(data)
            }
        } else {
            console.log('create_socioeconomic_1')
            addSocioeconomic(data)
        }

        // ===============================================================
        //  FALTA MANDAR A LA API EL DATA PARA VALIDAR DEL LADO DEL BACK
        // ===============================================================

    }

    onNext = () => {
        const pathname = '/adjuntos';
        this.props.history.push({
            pathname
        });
    }

    disableButton = () => {
        this.setState({
            isFormValid: false
        })
    }

    enableButton = () => {
        this.setState({
            isFormValid: true
        })
    }

    addItem = (e) => {
        e.preventDefault()
        // const { program } = this.props;
        // this.props.addDependentForm(program.data)
        let data = { curp: '', email: 'mail@mail.com', first_name: '', last_name: '', m_last_name: '', phone_type: 'movil', relationship_value: '', relationship: null, phone_number: '0000', ethnic_group: 1, diase: null, disability: null }
        this.setState({
            form_dependent: [...this.state.form_dependent, data]
        })
    }
    cleanForm = (e, index) => {
        e.preventDefault()
    }

    removeItem = (e, select_index) => {
        // console.log('index >>> ', index)
        e.preventDefault()
        const { form_dependent } = this.state
        // let form_dependent = this.state.form_dependent.filter((item, index) => index !== select_index);
        // if (this.form.getModel().beneficiaries[select_index]) {
        //     this.form.reset()
        // }
        const filter_form_dependent = form_dependent.slice(0, select_index).concat(form_dependent.slice(select_index + 1, form_dependent.length));
        this.setState({
            form_dependent: filter_form_dependent
        })
    }

    addItemGastoMensual = (e) => {
        e.preventDefault()
        let data = { relationship: null, contributor_name: '' }
        this.setState({
            form_gasto_mensual: [...this.state.form_gasto_mensual, data]
        })
    }

    removeItemAportador = (e, select_index) => {
        e.preventDefault()
        const { form_gasto_mensual } = this.state
        const filter_form_gasto_mensual = form_gasto_mensual.slice(0, select_index).concat(form_gasto_mensual.slice(select_index + 1, form_gasto_mensual.length))
        this.setState({
            form_gasto_mensual: filter_form_gasto_mensual
        })
    }

    getGender = (curp) => {
        if (curp) {
            if (curp.length >= 11) {
                const digit = curp.substr(10, 1).toUpperCase()
                if (digit === 'H') {
                    return 'Hombre'
                } else if (digit === 'M') {
                    return 'Mujer'
                }
            } else {
                return ''
            }
        }
    }

    getGenero = (curp) => {
        if (curp) {
            if (curp.length >= 11) {
                const digit = curp.substr(10, 1).toUpperCase()
                return digit
            } else {
                return 'N'
            }
        }
    }

    getBirthDate = (curp) => {
        // console.log('curp', curp)
        if (curp) {
            if (curp.length >= 10) {
                let year = parseInt(curp.substr(4, 2)) + 1900
                const month = curp.substr(6, 2)
                const day = curp.substr(8, 2)
                if (year < 1920) {
                    year += 100
                }
                return year + '-' + month + '-' + day
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    render() {
        const {
            classes,
            program,
            save,
            list_relation_ship,
            curp_search,
            add_socioeconomic,
            update_socioeconomic
        } = this.props;
        const relationShips = list_relation_ship.data.map(relationShip => ({
            value: relationShip.id,
            label: relationShip.name
        }))
        const form_value = this.form && this.form.getModel()
        // const form_validate = this.form && this.form.validateForm()
        // console.log("%cCurp", 'color:red;', this.props.curp_search)
        // console.log("%cEncuentra", 'color:yellow;', this.props.save)
        console.log("%cForm", 'color:blue;', curp_search)
        console.log('state', save.data)
        // console.log('%cForm', 'color:blue;', form_validate)
        return (
            <React.Fragment>
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h4" style={{ color: '#616161' }}> <strong>2. Evaluación socioeconómica</strong></Typography>
                    <Typography variant="h5" style={{ color: '#E61B72' }}> <strong>Programa: {program.name_program}</strong></Typography>
                    <br />
                    <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 1 }} />
                </div>
                <br />
                <Formsy
                    onValidSubmit={this.submitForm}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    ref={(form) => this.form = form}
                    className="flex flex-col justify-center"
                >
                    <TextFieldFormsy
                        name={'solicitante[id]'}
                        required
                        type="hidden"
                        value={save.data.beneficiarios[0].id}
                    // value={1520}
                    />
                    <TextFieldFormsy
                        name='petition'
                        required
                        type="hidden"
                        value={save.data.id}
                    // value={1}
                    />
                    <TextFieldFormsy
                        name='solicitante[studies][id]'
                        type="hidden"
                        value={save.data.beneficiarios[0].studies_data ? save.data.beneficiarios[0].studies_data.id : null}
                    // value={1}
                    />
                    <TextFieldFormsy
                        name='solicitante[sickness][id]'
                        type="hidden"
                        value={save.data.beneficiarios[0].sickness_data ? save.data.beneficiarios[0].sickness_data.id : null}
                    // value={1}
                    />
                    <div className='flex flex-wrap'>
                        <TextFieldFormsy
                            placeholder='Altura'
                            name='height'
                            variant="outlined"
                            className={classes.textField}
                            value={curp_search.data.found ? curp_search.data.data.socioeconomic_profile.height : ''}


                        />
                        <TextFieldFormsy
                            placeholder='Peso'
                            name='weight'
                            variant="outlined"
                            className={classes.textField}
                            value={curp_search.data.found ? curp_search.data.data.socioeconomic_profile.weight : ''}

                        />
                    </div>
                    <div>
                        <Typography variant='h6'>¿Cuál es su grado de estudios?</Typography>
                    </div>
                    <div className='flex flex-wrap'>
                        <React.Fragment>
                            <div className=''>
                                <CheckoxFormsy name='solicitante[studies][ninguno]' label='Ninguno' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.ninguno : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][sabe_leer]' label='Sabe leer' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.sabe_leer : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][sabe_escribir]' label='Sabe escribir' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.sabe_escribir : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][sabe_hacercuentas]' label='Sabe hacer cuentas' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.sabe_hacercuentas : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][primaria]' label='Primaria' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.primaria : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][secundaria]' label='Secundaria' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.secundaria : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][preparatoria]' label='Preparatoria' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.preparatoria : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][universidad]' label='Universidad' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.universidad : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][maestria]' label='Maestria' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.maestria : false) : false} />
                                <CheckoxFormsy name='solicitante[studies][doctorado]' label='Doctorado' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].studies_data !== null ? curp_search.data.data.beneficiarios[0].studies_data.doctorado : false) : false} />
                            </div>

                        </React.Fragment>
                    </div>

                    <div>
                        <Typography variant='h6'>¿Padece alguna enfermedad?</Typography>
                    </div>
                    <div className='flex flex-wrap'>
                        <Select2
                            name='solicitante[diase]'
                            placeholder='Seleccione una opción'
                            className={classes.textField}
                            options={opciones_general}
                            value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].diase !== null ? { value: curp_search.data.data.beneficiarios[0].diase, label: curp_search.data.data.beneficiarios[0].diase } : null) : null}
                            isClearable
                            required
                        />
                        {form_value && form_value.solicitante.diase && form_value.solicitante.diase.value === 'SI' &&
                            <React.Fragment>
                                <div >
                                    {/* <CheckoxFormsy name='solicitante[ninguna]' label='Ninguna' value={curp_search.data.found ? curp_search.data.data.beneficiarios[0].ninguna : false} /> */}
                                    <CheckoxFormsy name='solicitante[sickness][diabetes]' label='Diabetes' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.diabetes : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][hipertension]' label='Hipertensión' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.hipertension : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][cancer]' label='Cancer' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.cancer : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][epoc]' label='Epoc' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.epoc : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][artritis]' label='Artritis' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.artritis : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][enfermedad_renal]' label='Enfermedad renal' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.enfermedad_renal : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][esclerosis_multiple]' label='Esclerosis multiple' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.esclerosis_multiple : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][gonartrosis]' label='Gonartrosis' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.gonartrosis : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][vih]' label='VIH' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.vih : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][obesidad]' label='Obesidad' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.obesidad : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][osteoporosis]' label='Osteoporosis' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.osteoporosis : false) : false} />
                                    <CheckoxFormsy name='solicitante[sickness][otra]' label='Otra' value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.otra : false) : false} />
                                </div>
                                {form_value && form_value.solicitante.sickness && form_value.solicitante.sickness.otra && form_value.solicitante.sickness.otra === true &&
                                    <TextFieldFormsy
                                        placeholder='Describir cuál otra enfermedad'
                                        name='solicitante[sickness][otra_enfermedad]'
                                        variant="outlined"
                                        className={classes.textField}
                                        value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].sickness_data !== null ? curp_search.data.data.beneficiarios[0].sickness_data.otra_enfermedad : '') : ''}
                                        fullWidth
                                    />
                                }
                            </React.Fragment>
                        }
                    </div>
                    <div>
                        <Typography variant='h6'>¿Tiene alguna discapacidad?</Typography>
                    </div>
                    <div className='flex flex-wrap'>
                        <Select2
                            name='solicitante[disability]'
                            placeholder='Seleccione una opción'
                            className={classes.textField}
                            options={opciones_general}
                            value={curp_search.data.found ? (curp_search.data.data.beneficiarios[0].disability !== null ? { value: curp_search.data.data.beneficiarios[0].disability, label: curp_search.data.data.beneficiarios[0].disability } : null) : ''}
                            isClearable
                            required
                        />
                        {form_value && form_value.solicitante.disability && form_value.solicitante.disability.value === 'SI' &&
                            <React.Fragment>
                                <div className=''>
                                    <CheckoxFormsy name='solicitante[motor_disability]' label='Discapacidad motriz' value={curp_search.data.found ? curp_search.data.data.beneficiarios[0].motor_disability : false} />
                                    <CheckoxFormsy name='solicitante[visual_disability]' label='Discapacidad visual' value={curp_search.data.found ? curp_search.data.data.beneficiarios[0].visual_disability : false} />
                                    <CheckoxFormsy name='solicitante[hearing_disability]' label='Discapacidad auditiva' value={curp_search.data.found ? curp_search.data.data.beneficiarios[0].hearing_disability : false} />
                                    <CheckoxFormsy name='solicitante[intellectual_disability]' label='Discapacidad intelectual' value={curp_search.data.found ? curp_search.data.data.beneficiarios[0].intellectual_disability : false} />
                                    <CheckoxFormsy name='solicitante[psychosial_disability]' label='Discapacidad psicosocial' value={curp_search.data.found ? curp_search.data.data.beneficiarios[0].psychosial_disability : false} />
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className=''>
                        <Typography variant='h6'>¿Cuenta con algún servicio de salud?</Typography>
                        <Select2
                            name='health_service'
                            className={classes.textField}
                            placeholder='Seleccione una opciòn'
                            options={opciones_general}
                            isClearable
                            value={curp_search.data.found ? (curp_search.data.data.socioeconomic_profile.health_service !== null ? { value: curp_search.data.data.socioeconomic_profile.health_service, label: curp_search.data.data.socioeconomic_profile.health_service } : null) : null}
                            required
                        />
                        {form_value && form_value.health_service && form_value.health_service.value === 'SI' &&
                            <React.Fragment>
                                <Typography variant='h6'>¿Cuál?</Typography>
                                <Select2
                                    name='which_health_service'
                                    className={classes.textField}
                                    placeholder='Seleccione una opciòn'
                                    options={opciones_servicio_salud}
                                    value={curp_search.data.found ? (curp_search.data.data.socioeconomic_profile.which_health_service !== null ? { value: curp_search.data.data.socioeconomic_profile.which_health_service, label: curp_search.data.data.socioeconomic_profile.which_health_service } : null) : null}
                                    isClearable
                                    required
                                />
                            </React.Fragment>
                        }
                        {form_value && form_value.which_health_service && form_value.which_health_service.value === 'OTRO' &&
                            <React.Fragment>
                                <div className='flex flex-wrap'>
                                    <TextFieldFormsy
                                        placeholder='Describir cuál otro servicio'
                                        name='other_health_service'
                                        variant="outlined"
                                        className={classes.textField}
                                        value={curp_search.data.found ? curp_search.data.data.socioeconomic_profile.other_health_service : ''}
                                        required
                                    />
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div>
                        <Typography variant='h6'>¿Cuánto gasta al mes en medicamentos y/o terapias, propia, de algún familiar o ambas?</Typography>
                        <Select2
                            name='medical_expenses'
                            className={classes.textField}
                            placeholder='Seleccione una opción'
                            options={opciones_gastos_medicos}
                            value={curp_search.data.found ? (curp_search.data.data.socioeconomic_profile.medical_expenses !== null ? { value: curp_search.data.data.socioeconomic_profile.medical_expenses, label: curp_search.data.data.socioeconomic_profile.medical_expenses } : null) : ''}
                            isClearable
                            required
                        />
                    </div>
                    <div>
                        <Typography variant='h6'>¿Cuál es su actividad principal?</Typography>
                        <Select2
                            name='major_activity'
                            className={classes.textField}
                            placeholder='Seleccione una opción'
                            options={opciones_actividad_principal}
                            isClearable
                            value={curp_search.data.found ? (curp_search.data.data.socioeconomic_profile.major_activity !== null ? { value: curp_search.data.data.socioeconomic_profile.major_activity, label: curp_search.data.data.socioeconomic_profile.major_activity } : null) : ''}
                            required
                        />
                        {form_value && form_value.major_activity && form_value.major_activity.value === 'OTRO' &&
                            <React.Fragment>
                                <div className='flex flex-wrap'>
                                    <TextFieldFormsy
                                        placeholder='Describir cuál otra actividad'
                                        name='other_major_activity'
                                        variant="outlined"
                                        className={classes.textField}
                                        value={curp_search.data.found ? curp_search.data.data.socioeconomic_profile.other_major_activity : ''}
                                        required
                                    />
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div>
                        <Typography variant='h6'>¿Alguna persona depende económicamente de usted?</Typography>
                        <Select2
                            name='financially_dependent'
                            className={classes.textField}
                            placeholder='Seleccione una opción'
                            options={program.data && program.data.code === 'BI' ? opciones__general_indigena : opciones_general}
                            isClearable
                            value={curp_search.data.found ? (this.state.form_dependent.length !== 0 ? { value: 'SI', label: 'SI' } : (program.data && (program.data.code === 'BI' || program.data.code === 'BD') ? { value: 'NO', label: 'NO' } : (curp_search.data.data.socioeconomic_profile.financially_dependent !== null ? { value: curp_search.data.data.socioeconomic_profile.financially_dependent, label: curp_search.data.data.socioeconomic_profile.financially_dependent } : null))) : ''}
                            required
                        />
                        {form_value && form_value.financially_dependent && form_value.financially_dependent.value === 'SI' &&
                            <React.Fragment>
                                {(this.state.form_dependent.length > 0) && this.state.form_dependent.map((dependiente, index) => (
                                    <React.Fragment key={index}>
                                        <div className="flex flex-wrap">
                                            <AppBar className={classes.toolbar} position="static">
                                                <Toolbar>
                                                    <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                                                        <Icon>person_outline</Icon>
                                                    </IconButton>
                                                    <Typography variant="h6" color="inherit" className={classes.grow}>
                                                        {'Datos del dependiente'}
                                                    </Typography>
                                                    <Typography variant="h6" color="inherit">
                                                        Numero: {index}
                                                    </Typography>
                                                    {this.state.form_dependent.length !== 0 && <Tooltip title="Eliminar beneficiario" aria-label="Eliminar">
                                                        <IconButton color="inherit" onClick={(e) => this.removeItem(e, index)} aria-label="Menu">
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </Tooltip>}
                                                </Toolbar>
                                            </AppBar>
                                        </div>
                                        <br />
                                        <div className='flex flex-wrap'>
                                            {/* beneficiarios */}
                                            <TextFieldFormsy
                                                type='hidden'
                                                value={curp_search.data.borrador ? dependiente.id : null}
                                                name={"beneficiaries[" + index + "][id]"}
                                            />
                                            <TextFieldFormsy
                                                type='hidden'
                                                value={(curp_search.data.found && curp_search.data.borrador) && (dependiente.studies_data ? dependiente.studies_data.id : null)}
                                                name={"beneficiaries[" + index + "][studies][id]"}
                                            />
                                            <TextFieldFormsy
                                                type='hidden'
                                                value={(curp_search.data.found && curp_search.data.borrador) && (dependiente.sickness_data ? dependiente.sickness_data.id : null)}
                                                name={"beneficiaries[" + index + "][sickness][id]"}
                                            />

                                            <TextFieldFormsy
                                                name={"beneficiaries[" + index + "][email]"}
                                                required
                                                type="hidden"
                                                value='email@gmail.com'
                                            />
                                            <TextFieldFormsy
                                                name={"beneficiaries[" + index + "][phone_type]"}
                                                required
                                                type="hidden"
                                                value='movil'
                                            />
                                            <TextFieldFormsy
                                                name={"beneficiaries[" + index + "][phone_number]"}
                                                required
                                                type="hidden"
                                                value='1234567890'
                                            />
                                            <TextFieldFormsy
                                                type='number'
                                                name={"beneficiaries[" + index + "][ethnic_group]"}
                                                required
                                                type="hidden"
                                                value={1}
                                            />

                                            {/* beneficiarios */}
                                            <TextUFormsy
                                                className={classes.textField}
                                                type="text"
                                                name={"beneficiaries[" + index + "][curp]"}
                                                label="CURP"
                                                validations={{
                                                    minLength: 18,
                                                    matchRegexp: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
                                                }}
                                                validationErrors={{
                                                    minLength: form_value && form_value.beneficiaries && form_value.beneficiaries[index] && form_value.beneficiaries[index].curp && form_value.beneficiaries[index].curp.length + 1 + ' de 18',
                                                    matchRegexp: 'CURP no vàlida'
                                                }}
                                                inputProps={{
                                                    maxLength: 18,
                                                }}
                                                variant="outlined"
                                                helperText={<a href='https://www.gob.mx/curp/' target="_blank" rel="noopener noreferrer">Consultar curp</a>}
                                                value={curp_search.data.found && dependiente.curp}
                                            />
                                            <TextFieldFormsy
                                                name={"beneficiaries[" + index + "][birth_date]"}
                                                type="text"
                                                label="Fecha de nacimiento"
                                                variant="outlined"
                                                className={classes.textField}
                                                value={this.getBirthDate(form_value && form_value.beneficiaries && form_value.beneficiaries[index] && form_value.beneficiaries[index].curp)}
                                                disabled
                                                required
                                            />
                                            <TextFieldFormsy
                                                name={"beneficiaries[" + index + "][gender]"}
                                                label="Sexo"
                                                className={classes.textField}
                                                value={this.getGender(form_value && form_value.beneficiaries && form_value.beneficiaries[index] && form_value.beneficiaries[index].curp)}
                                                disabled
                                                variant="outlined"
                                            />
                                            <Select2
                                                name={"beneficiaries[" + index + "][relationship]"}
                                                placeholder='Parentesco'
                                                className={classes.textField}
                                                options={relationShips}
                                                isClearable
                                                value={curp_search.data.found && relationShips.find(item => item.value === dependiente.relationship)}
                                                required
                                            />
                                        </div>
                                        <div className='flex flex-wrap'>
                                            <TextUFormsy
                                                name={"beneficiaries[" + index + "][first_name]"}
                                                label="Nombre(s)"
                                                variant="outlined"
                                                className={classes.textField}
                                                value={curp_search.data.found && dependiente.first_name}
                                                required
                                            />
                                            <TextUFormsy
                                                name={"beneficiaries[" + index + "][last_name]"}
                                                label="Apellido paterno"
                                                variant="outlined"
                                                className={classes.textField}
                                                value={curp_search.data.found && dependiente.last_name}
                                                required
                                            />
                                            <TextUFormsy
                                                name={"beneficiaries[" + index + "][m_last_name]"}
                                                label="Apellido materno"
                                                variant="outlined"
                                                className={classes.textField}
                                                value={curp_search.data.found && dependiente.m_last_name}
                                            />
                                        </div>
                                        <div>
                                            <Typography variant='h6'>¿Cuál es su grado de estudios?</Typography>
                                        </div>
                                        <div className='flex flex-wrap'>
                                            <React.Fragment>
                                                <div className=''>
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][ninguno]"} label='Ninguno' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.ninguno : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][sabe_leer]"} label='Sabe leer' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.sabe_leer : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][sabe_escribir]"} label='Sabe escribir' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.sabe_escribir : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][sabe_hacercuentas]"} label='Sabe hacer cuentas' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.sabe_hacercuentas : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][primaria]"} label='Primaria' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.primaria : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][secundaria]"} label='Secundaria' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.secundaria : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][preparatoria]"} label='Preparatoria' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.preparatoria : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][universidad]"} label='Universidad' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.universidad : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][maestria]"} label='Maestria' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.maestria : false) : false} />
                                                    <CheckoxFormsy name={"beneficiaries[" + index + "][studies][doctorado]"} label='Doctorado' value={curp_search.data.found ? (dependiente.studies_data ? dependiente.studies_data.doctorado : false) : false} />
                                                </div>
                                            </React.Fragment>
                                        </div>
                                        <div>
                                            <Typography variant='h6'>¿Padece alguna enfermedad?</Typography>
                                        </div>
                                        <div className='flex flex-wrap'>
                                            <Select2
                                                name={"beneficiaries[" + index + "][diase]"}
                                                placeholder='Seleccione una opción'
                                                className={classes.textField}
                                                options={opciones_general}
                                                isClearable
                                                value={curp_search.data.found && (dependiente.diase !== null ? { value: dependiente.diase, label: dependiente.diase } : null)}
                                                required
                                            />
                                            {form_value && form_value.beneficiaries && form_value.beneficiaries[index] && form_value.beneficiaries[index].diase && form_value.beneficiaries[index].diase.value === 'SI' &&
                                                <React.Fragment>
                                                    <div >
                                                        {/* <CheckoxFormsy name={"beneficiaries[" + index + "][ninguna]"} label='Ninguna' value={curp_search.data.found ? dependiente.ninguna : false} /> */}
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][diabetes]"} label='Diabetes' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.diabetes : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][hipertension]"} label='Hipertensión' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.hipertension : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][cancer]"} label='Cancer' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.cancer : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][epoc]"} label='Epoc' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.epoc : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][artritis]"} label='Artritis' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.artritis : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][enfermedad_renal]"} label='Enfermedad renal' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.enfermedad_renal : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][esclerosis_multiple]"} label='Esclerosis multiple' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.esclerosis_multiple : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][gonartrosis]"} label='Gonartrosis' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.gonartrosis : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][vih]"} label='VIH' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.vih : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][obesidad]"} label='Obesidad' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.obesidad : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][osteoporosis]"} label='Osteoporosis' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.osteoporosis : false) : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][sickness][otra]"} label='Otra' value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.otra : false) : false} />
                                                    </div>
                                                    {form_value && form_value.beneficiaries && form_value.beneficiaries[index] && form_value.beneficiaries[index].sickness && form_value.beneficiaries[index].sickness.otra && form_value.beneficiaries[index].sickness.otra === true &&
                                                        <TextFieldFormsy
                                                            placeholder='Describir cuál otra enfermedad'
                                                            name={"beneficiaries[" + index + "][sickness][otra_enfermedad]"}
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            value={curp_search.data.found ? (dependiente.sickness_data ? dependiente.sickness_data.otra_enfermedad : '') : ''}
                                                            fullWidth
                                                        />
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div>
                                            <Typography variant='h6'>¿Tiene alguna discapacidad?</Typography>
                                        </div>
                                        <div className='flex flex-wrap'>
                                            <Select2
                                                name={"beneficiaries[" + index + "][disability]"}
                                                placeholder='Seleccione una opción'
                                                className={classes.textField}
                                                options={opciones_general}
                                                isClearable
                                                value={curp_search.data.found && (dependiente.disability !== null ? { value: dependiente.disability, label: dependiente.disability } : null)}
                                                required
                                            />
                                            {form_value && form_value.beneficiaries && form_value.beneficiaries[index] && form_value.beneficiaries[index].disability && form_value.beneficiaries[index].disability.value === 'SI' &&
                                                <React.Fragment>
                                                    <div className=''>
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][motor_disability]"} label='Discapacidad motriz' value={curp_search.data.found ? dependiente.motor_disability : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][visual_disability]"} label='Discapacidad visual' value={curp_search.data.found ? dependiente.visual_disability : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][hearing_disability]"} label='Discapacidad auditiva' value={curp_search.data.found ? dependiente.hearing_disability : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][intellectual_disability]"} label='Discapacidad intelectual' value={curp_search.data.found ? dependiente.intellectual_disability : false} />
                                                        <CheckoxFormsy name={"beneficiaries[" + index + "][psychosial_disability]"} label='Discapacidad psicosocial' value={curp_search.data.found ? dependiente.psychosial_disability : false} />
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </React.Fragment>
                                ))}
                                <div className="flex flex-wrap justify-center">
                                    <Tooltip title="Le permite agregar un dependiente económico" aria-label="Agregar dependiente">
                                        <Button variant="extendedFab" color="primary" aria-label="Agregar" onClick={(e) => this.addItem(e)} className={classes.button}>
                                            <Icon>add</Icon>
                                            {'Agregar dependiente'}
                                        </Button>
                                    </Tooltip>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div>
                        <Typography variant='h6'>¿Quién o quiénes aportan al gasto mensual familiar?</Typography>
                        {(this.state.form_gasto_mensual.length > 0) && this.state.form_gasto_mensual.map((gasto, index) => (
                            <React.Fragment key={index}>
                                <div className="flex flex-wrap">
                                    <AppBar className={classes.toolbar} position="static">
                                        <Toolbar>
                                            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                                                <Icon>person_outline</Icon>
                                            </IconButton>
                                            <Typography variant="h6" color="inherit" className={classes.grow}>
                                                {'Datos del aportador'}
                                            </Typography>
                                            <Typography variant="h6" color="inherit">
                                                Numero: {index + 1}
                                            </Typography>
                                            {this.state.form_gasto_mensual.length !== 0 && <Tooltip title="Eliminar aportador" aria-label="Eliminar">
                                                <IconButton color="inherit" onClick={(e) => this.removeItemAportador(e, index)} aria-label="Menu">
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </Tooltip>}
                                        </Toolbar>
                                    </AppBar>
                                </div>
                                <br />
                                <div className='flex flex-wrap'>
                                    <TextFieldFormsy
                                        type='hidden'
                                        value={curp_search.data.borrador ? gasto.id : null}
                                        name={"monthly_expense[" + index + "][id]"}
                                    />
                                    <Select2
                                        name={"monthly_expense[" + index + "][relationship]"}
                                        placeholder='Parentesco'
                                        className={classes.textField}
                                        options={opciones_parentesco}
                                        isClearable
                                        value={curp_search.data.found && (gasto.relationship !== null ? { value: gasto.relationship, label: gasto.relationship } : null)}
                                        required
                                    />
                                    <TextUFormsy
                                        name={"monthly_expense[" + index + "][contributor_name]"}
                                        label="Nombre(s)"
                                        variant="outlined"
                                        className={classes.textField}
                                        value={curp_search.data.found && gasto.contributor_name}
                                        required
                                    />
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="flex flex-wrap justify-center">
                            <Tooltip title="Le permite agregar una persona que aporta" aria-label="Agregar persona">
                                <Button variant="extendedFab" color="primary" aria-label="Agregar" onClick={(e) => this.addItemGastoMensual(e)} className={classes.button}>
                                    <Icon>add</Icon>
                                    {'Agregar persona'}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                    <div>
                        <Typography variant='h6'>¿El ingreso mensual familiar es de?</Typography>
                        <Select2
                            name='monthly_family_income'
                            className={classes.textField}
                            placeholder='Seleccione una opción'
                            options={opciones_ingreso_mensual}
                            isClearable
                            value={curp_search.data.found ? (curp_search.data.data.socioeconomic_profile.monthly_family_income !== null ? { value: curp_search.data.data.socioeconomic_profile.monthly_family_income, label: curp_search.data.data.socioeconomic_profile.monthly_family_income } : null) : ''}
                            required
                        />
                    </div>
                    <div>
                        <Typography variant='h6'>¿Recibe apoyo de otra institución?</Typography>
                        <Select2
                            name='support_another_institution'
                            className={classes.textField}
                            placeholder='Seleccione una opción'
                            options={opciones_general}
                            isClearable
                            value={curp_search.data.found ? (curp_search.data.data.socioeconomic_profile.support_another_institution !== null ? { value: curp_search.data.data.socioeconomic_profile.support_another_institution, label: curp_search.data.data.socioeconomic_profile.support_another_institution } : null) : ''}
                            required
                        />
                        {form_value && form_value.support_another_institution && form_value.support_another_institution.value === 'SI' &&
                            <React.Fragment>
                                <div className='flex flex-wrap'>
                                    <TextFieldFormsy
                                        placeholder='¿De qué tipo?'
                                        name='type_support'
                                        variant="outlined"
                                        className={classes.textField}
                                        value={curp_search.data.found ? curp_search.data.data.socioeconomic_profile.type_support : ''}
                                        required
                                    />
                                </div>
                            </React.Fragment>
                        }
                    </div>

                    {program.data !== undefined && program.data.code !== 'BI' && (program.data.min_beneficiary !== 0 && ((form_value && form_value.beneficiaries === undefined) || (form_value && form_value.beneficiaries.length) < program.data.min_beneficiary) &&
                        <div className="flex flex-wrap justify-end">
                            <Typography color="primary" className="text-12">
                                {'Requiere registrar almenos ' + program.data.min_beneficiary + ' dependiente(s)'}
                            </Typography>
                        </div>)
                    }
                    <div className={classNames(classes.root, 'justify-end')}>
                        <div className={classNames(classes.wrapper)}>
                            <Button
                                disabled={add_socioeconomic.isFetching || update_socioeconomic.isFetching || 
                                    (program.data !== undefined && program.data.code !== 'BI' && 
                                    (program.data.min_beneficiary !== 0 && (
                                        (form_value && form_value.beneficiaries === undefined) || (form_value && form_value.beneficiaries.length) < program.data.min_beneficiary
                                    )
                                    )
                                    )}
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                            >
                                CONTINUAR
                            </Button>
                            {(add_socioeconomic.isFetching || update_socioeconomic.isFetching) && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                </Formsy>
            </React.Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addDependentForm: Actions.addDependentForm,
        addSocioeconomic: Actions.addSocioeconomic,
        setRelationShipSelectForm: Actions.setRelationShipSelectForm,
        relationShipList: Actions.relationShipList,
        updateSocioeconomic: Actions.updateSocioeconomic
    }, dispatch);
}

function mapStateToProps({ auth, program, solicitude, catalogs }) {
    return {
        user: auth.user,
        program: program.choice,
        form: solicitude.form,
        save: solicitude.save,
        add_socioeconomic: solicitude.add_socioeconomic,
        list_relation_ship: catalogs.list,
        curp_search: solicitude.curp_search,
        program: program.choice,
        update_socioeconomic: solicitude.update_socioeconomic
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SocioeconomicProfileForm)));