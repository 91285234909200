import {FuseLoadable} from '@fuse';
import {authRoles} from 'auth';

export const ReportConfig = {
    settings: {
        layout: {
            config: {
            }
        }
    },
    auth    : authRoles.admin,//['admin']
    routes  : [
        {
            path     : '/reportes',
            component: FuseLoadable({
                loader: () => import('./Report')
            })
        }
    ]
};