import {fetchSolicitudePetition} from 'api'

export const FETCHING_PETITION_SOLICITUDE = 'FETCHING_PETITION_SOLICITUDE';
export const FETCHING_PETITION_SOLICITUDE_SUCCESS = 'FETCHING_PETITION_SOLICITUDE_SUCCESS';
export const FETCHING_PETITION_SOLICITUDE_FAILURE = 'FETCHING_PETITION_SOLICITUDE_FAILURE';
export const SET_COMMENT_SOLICITUDE = 'SET_COMMENT_SOLICITUDE';
export const CLEAN_COMMENT_SOLICITUDE = 'CLEAN_COMMENT_SOLICITUDE';

export const getData = () => {
    return {
        type: FETCHING_PETITION_SOLICITUDE
    }
}

export const getDataSuccess = (data) => {
    return {
        type: FETCHING_PETITION_SOLICITUDE_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_PETITION_SOLICITUDE_FAILURE,
        error
    }
}

export const set_comment_solicitude = e => {
    return {
        type: SET_COMMENT_SOLICITUDE,
        value: e.target.value
    }
}

export const clean_comment_solicitude = () => {
    return {
        type: CLEAN_COMMENT_SOLICITUDE
    }
}

export const solicitudePetition = (id_petition, comment) => {
    return (dispatch) => {
        dispatch(getData())
        fetchSolicitudePetition(id_petition, comment)
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))

            })
    }
}