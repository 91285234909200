import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FusePageCarded} from '@fuse';
import HeaderComponent from '../../components/HeaderComponent';
import {FuseAnimate} from '@fuse';
import AttachmentUploads from './AttachmentUploads';


const styles = theme => ({
    root: {
        flexGrow: 1,
        display : 'flex',
        flexWrap: 'wrap',
        padding: 24,
    },
    leftIcon : {
        marginRight: theme.spacing.unit
    },
    rightIcon: {
        marginLeft: theme.spacing.unit
    },
    iconSmall: {
        fontSize: 20
    },
    layoutRoot: {}
});

class Attachment extends Component {
    render(){
        const {classes} = this.props;
        return (
            <FusePageCarded
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <HeaderComponent />
                }
                content={
                    <div>
                        <FuseAnimate animation={{translateX: [0, '100%']}}>
                            <div className="p-24">
                                <AttachmentUploads />
                            </div>
                        </FuseAnimate>
                    </div>
                }
            />
        )
    }
}

export default withStyles(styles, {withTheme: true})(Attachment);