import { fetchListProgram } from 'api';

export const FETCHING_LIST_PROGRAM = 'FETCHING_LIST_PROGRAM';
export const FETCHING_LIST_PROGRAM_SUCCESS = 'FETCHING_LIST_PROGRAM_SUCCESS';
export const FETCHING_LIST_PROGRAM_FAILURE = 'FETCHING_LIST_PROGRAM_FAILURE';

export const getData = () => {
    return {
        type: FETCHING_LIST_PROGRAM
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_LIST_PROGRAM_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_LIST_PROGRAM_FAILURE,
        error
    }
}

export const listProgram = () => {
    return (dispatch) => {
        dispatch(getData())
        fetchListProgram()
            .then(data => {
                if (data.data) {
                    dispatch(getDataSuccess(data.data))
                }
            })
            .catch(error => {
                dispatch(getDataFailure(error.response.data))
            })
    }
}