import WayKnow from './WayKnow';
import {authRoles} from 'auth';

export const WayKnowConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth    : authRoles.user,
    routes  : [
        {
            path     : '/enterado',
            component: WayKnow
        }
    ]
};