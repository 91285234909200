import * as Actions from '../../actions/solicitude/index';

const initialState = {
    data: [],
    success: false,
    isFetching: false,
    error: false,
    id_type_notification: undefined
}

const list_type_notification = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FETCHING_TYPE_NOTIFICATION:
            return {
                ...state,
                data: [],
                success: false,
                error: false,
                isFetching: true
            }
        case Actions.FETCHING_TYPE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                data: action.data,
                success: true,
                error: false,
                isFetching: false
            }
        case Actions.FETCHING_TYPE_NOTIFICATION_FAILURE:
            return {
                ...state,
                data: action.error,
                error: true,
                success: false,
                isFetching: false
            }

        case Actions.SELECT_ID_TYPE_NOTIFICATION: {
            return {
                ...state,
                id_type_notification: action.id
            }
        }
        default:
            return state
    }
}

export default list_type_notification;