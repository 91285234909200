
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { TextField, Icon, Tooltip, Button, Typography, withStyles, Divider, AppBar, Toolbar, IconButton } from '@material-ui/core';
import Autocomplete from '../../components/Autocomplete'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import GoogleMap from 'google-map-react';

import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';
const genderTranas = [
    {
        value: 'H',
        label: 'Hombre'
    },
    {
        value: 'M',
        label: 'Mujer'
    }
].map(gender => ({
    value: gender.value,
    label: gender.label
}))

function Marker({ text }) {
    return (
        <Tooltip title={text} placement="top">
            <Icon className="text-red">location_on</Icon>
        </Tooltip>
    );
}

const styles = theme => ({
    layoutRoot: {},
    textField: {
        flexGrow: 1,
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    mapField: {
        flexGrow: 1,
        height: '250px',
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    selectField: {
        flexGrow: 1,
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    marField: {
        marginLeft: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    button: {
        marginLeft: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    toolbar: {
        zIndex: 10,
        marginLeft: theme.spacing.unit,
    },
    contentCard: {
        display: 'flex',
        flex: '1 1 100%',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        height: '100%',
        borderRadius: '8px 8px 0 0'
    },
    iOSSwitchBase: {
        '&$iOSChecked': {
            color: theme.palette.common.white,
            '& + $iOSBar': {
                backgroundColor: '#52d869'
            }
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp
        })
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none'
        }
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
    },
    iOSIcon: {
        width: 24,
        height: 24
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1]
    },
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        // button: '50%',
        top: '50%',
        left: '70%',
        marginTop: -12,
        marginLeft: -12,
    },
    load: {
        position: 'absolute',
        marginTop: 30,
        marginLeft: -20,
    },
});

function MaskedCurp(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[0-9]/, /[0-9]/]}
            placeholderChar={'\u2000'}

        />
    );
}

MaskedCurp.propTypes = {
    inputRef: PropTypes.func.isRequired,
}
class SolicitudeRegisterForm extends Component {
    state = {
        curp: '',
        disabled: false,
    }

    componentWillUnmount() {
        this.props.resetSolicitudeForm();
    }

    componentDidMount() {
        const { user, program, form } = this.props;
        if (program.data === undefined) {
            this.props.history.goBack();
        } else {
            this.props.setProgramForm(program.data);
            if (form.beneficiaries.length === 0) {
                if (user.role === 'user') {
                    if (program.data.code === "PT") {
                        this.props.addBeneficiaryUserTransForm(program.data, user)
                        this.setState({ disabled: true })
                    }
                    if (program.data.code !== "PT") {
                        this.props.addBeneficiaryUserForm(program.data, user)
                        this.setState({ disabled: true })
                    }
                } else {
                    if (program.data.code === "PT") {
                        this.props.addBeneficiaryTransForm(program.data)
                    }
                    if (program.data.code !== "PT") {
                        this.props.addBeneficiaryForm(program.data)
                    }
                }
                if (program.data.code === "PC") {
                    this.props.addPeopleCareForm()
                }
            }
        }
        this.props.resetGeolocation()
        this.props.relationShipList()
        this.props.listElectoralSections()
        this.props.listEthnicGroup()
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.save.success && nextProps.save.success !== this.props.save.success) {
            this.props.setBeneficiariesData(nextProps.save.data);
            const pathname = '/perfil_socioeconomico';
            this.props.history.push({
                pathname
            });
        }
        if (nextProps.curp_search.success && nextProps.curp_search.success !== this.props.curp_search.success) {
            if (nextProps.curp_search.data.found) {
                this.props.setSolicitudeId(nextProps.curp_search.data.id)

                let beneficiaries = nextProps.curp_search.data.data.beneficiarios
                const index = beneficiaries.map(e => e.curp).indexOf(this.state.curp)
                const beneficiary = beneficiaries[index]

                if (nextProps.curp_search.data.borrador) this.props.updateBeneficiaryTextForm({ target: { name: 'id', value: beneficiary.id } }, 0)
                this.props.updateBeneficiaryTextForm({ target: { name: 'first_name', value: beneficiary.first_name } }, 0)
                this.props.updateBeneficiaryTextForm({ target: { name: 'last_name', value: beneficiary.last_name } }, 0)
                this.props.updateBeneficiaryTextForm({ target: { name: 'm_last_name', value: beneficiary.m_last_name } }, 0)
                this.props.setBeneficiarySelectForm({ label: beneficiary.phone_type_label, value: beneficiary.phone_type }, 0)
                this.props.updateBeneficiaryTextForm({ target: { name: 'phone_number', value: beneficiary.phone_number } }, 0)

                if (beneficiary.address){
                    if (nextProps.curp_search.data.borrador) this.props.updateBeneficiaryTextForm({ target: { name: 'address_id', value: beneficiary.address.id } }, 0)
                    this.props.updateBeneficiaryTextForm({ target: { name: 'email', value: beneficiary.email } }, 0)
                    this.props.updateBeneficiaryTextForm({ target: { name: 'street', value: beneficiary.address.street } }, 0)
                    this.props.updateBeneficiaryTextForm({ target: { name: 'out_number', value: beneficiary.address.out_number } }, 0)
                    this.props.updateBeneficiaryTextForm({ target: { name: 'int_number', value: beneficiary.address.int_number } }, 0)
                    this.props.setElectoralSectionForm({ label: beneficiary.address.colony_label, value: beneficiary.address.colony }, 0)
                    const params = { colony_id: beneficiary.address.colony, street: beneficiary.address.street, out_number: beneficiary.address.out_number }
                    this.props.getGeolocation(params);
                }
                if (this.props.program.data.code === 'BI') {
                    this.props.setEthnicSelectForm({ label: beneficiary.ethnic_group_label, value: beneficiary.ethnic_group }, 0)
                }
                if (this.props.program.data.code === 'PT') {
                    this.props.updateBeneficiaryTextForm({ target: { name: 'social_name', value: beneficiary.social_name } }, 0)
                    this.props.setGenderTransSelectForm({ label: beneficiary.gender_trans_label, value: beneficiary.gender_trans }, 0)
                }

                // beneficiaries.splice(index, 1)

                if (this.props.program.data.code === 'BI' || this.props.program.data.code === 'BD') {
                    let indx = 1
                    beneficiaries.forEach((benefi, i) => {
                        if(i!==index){
                            this.props.addDependentForm(this.props.program.data)
                            if (nextProps.curp_search.data.borrador) this.props.updateBeneficiaryTextForm({ target: { name: 'id', value: benefi.id } }, indx)
                            this.props.updateBeneficiaryTextForm({ target: { name: 'curp', value: benefi.curp } }, indx)
                            this.props.updateBeneficiaryTextForm({ target: { name: 'first_name', value: benefi.first_name } }, indx)
                            this.props.updateBeneficiaryTextForm({ target: { name: 'last_name', value: benefi.last_name } }, indx)
                            this.props.updateBeneficiaryTextForm({ target: { name: 'm_last_name', value: benefi.m_last_name } }, indx)
                            this.props.setBeneficiarySelectForm({ label: benefi.phone_type_label, value: benefi.phone_type }, indx)
                            this.props.updateBeneficiaryTextForm({ target: { name: 'phone_number', value: benefi.phone_number } }, indx)
                            indx++
                        }
                    })
                }

                // nextProps.curp_search.data.data.beneficiarios.forEach((beneficiary, index) => {
                //     if (index === 0 || this.props.program.data.code === 'BI' || this.props.program.data.code === 'BD') {
                //         if (index > 0) this.props.addDependentForm(this.props.program.data)
                //         if (index === 0 || this.props.program.data.code === 'BI' || this.props.program.data.code === 'BD') {
                //             if (nextProps.curp_search.data.borrador) this.props.updateBeneficiaryTextForm({ target: { name: 'id', value: beneficiary.id } }, index)
                //             this.props.updateBeneficiaryTextForm({ target: { name: 'first_name', value: beneficiary.first_name } }, index)
                //             this.props.updateBeneficiaryTextForm({ target: { name: 'last_name', value: beneficiary.last_name } }, index)
                //             this.props.updateBeneficiaryTextForm({ target: { name: 'm_last_name', value: beneficiary.m_last_name } }, index)
                //             this.props.setBeneficiarySelectForm({ label: beneficiary.phone_type_label, value: beneficiary.phone_type }, index)
                //             this.props.updateBeneficiaryTextForm({ target: { name: 'phone_number', value: beneficiary.phone_number } }, index)
                //         }
                //         if ((index === 0 && this.props.program.data.code !== 'BI') || (index === 0 & nextProps.curp_search.data.borrador)) {
                //             if (nextProps.curp_search.data.borrador) this.props.updateBeneficiaryTextForm({ target: { name: 'address_id', value: beneficiary.address.id } }, index)
                //             if (beneficiary.address){
                //                 this.props.updateBeneficiaryTextForm({ target: { name: 'email', value: beneficiary.email } }, index)
                //                 this.props.updateBeneficiaryTextForm({ target: { name: 'street', value: beneficiary.address.street } }, index)
                //                 this.props.updateBeneficiaryTextForm({ target: { name: 'out_number', value: beneficiary.address.out_number } }, index)
                //                 this.props.updateBeneficiaryTextForm({ target: { name: 'int_number', value: beneficiary.address.int_number } }, index)
                //                 this.props.setElectoralSectionForm({ label: beneficiary.address.colony_label, value: beneficiary.address.colony }, index)
                //                 const params = { colony_id: beneficiary.address.colony, street: beneficiary.address.street, out_number: beneficiary.address.out_number }
                //                 this.props.getGeolocation(params);
                //             }
                //             if (this.props.program.data.code === 'PT') {
                //                 this.props.updateBeneficiaryTextForm({ target: { name: 'social_name', value: beneficiary.social_name } }, index)
                //                 this.props.setGenderTransSelectForm({ label: beneficiary.gender_trans_label, value: beneficiary.gender_trans }, index)
                //             }
                //         } else {
                //             if (this.props.program.data.code === 'BI' || this.props.program.data.code === 'BD') {
                //                 this.props.updateBeneficiaryTextForm({ target: { name: 'curp', value: beneficiary.curp } }, index)
                //                 this.props.setRelationShipSelectForm({ label: beneficiary.relationship_label, value: beneficiary.relationship }, index)
                //             }
                //             if (this.props.program.data.code === 'BI') {
                //                 this.props.setEthnicSelectForm({ label: beneficiary.ethnic_group_label, value: beneficiary.ethnic_group }, index)
                //             }
                //         }
                //     }
                // })
                this.props.setCommentForm(nextProps.curp_search.data.data.comment)
            }
            // else {
            //     const { form, program } = this.props;
            //     form.beneficiaries.forEach((beneficiary, index) => {
            //         if (index===0){
            //             this.props.resetFullForm(beneficiary.curp)
            //         } else {
            //             this.props.removeBeneficiaryForm(index, program.data)
            //         }
            //     })
            // }
        }
    }

    submitForm = (e) => {
        e.preventDefault()
        const { form, curp_search, saveSolicitude, updateSolicitude } = this.props
        if (curp_search.success) {
            form['found'] = curp_search.data.email_exist
            if (curp_search.data.found && curp_search.data.borrador) {
                console.log('update')
                updateSolicitude(form, curp_search.data.data.id)
            } else {
                console.log(form)
                saveSolicitude(form)
            }
        }
    }

    cleanForm = (e, index) => {
        e.preventDefault()
        if (index === 0) {
            if (this.props.program.data.code === 'PT') {
                this.props.resetBeneficiaryTransForm(index)
            } else {
                this.props.resetBeneficiaryForm(index)
            }
        } else {
            if (this.props.program.data.code === 'BI') {
                this.props.resetDependentEthnicForm()
            } else {
                this.props.resetDependantForm(index)
            }
        }
    }

    addItem = (e) => {
        e.preventDefault()
        const { program } = this.props;
        if (program.data.code === "BI") {
            this.props.addDependentEthnicForm(program.data)
        } else {
            this.props.addDependentForm(program.data)
        }
    }

    removeItem = (e, index) => {
        e.preventDefault()
        const { program } = this.props;
        this.props.removeBeneficiaryForm(index, program.data)
    }

    onBack = () => {
        this.props.clean_program_choice();
        this.props.resetSolicitudeForm();
        this.props.resetSolicitudeSave();
        this.props.history.goBack();
    }

    onNext = () => {
        const pathname = '/adjuntos';
        this.props.history.push({
            pathname
        });
    }

    changeCurp = (e, index) => {
        e.preventDefault()
        this.props.updateBeneficiaryTextForm(e, index);
        const { program } = this.props;
        const curp = e.target.value;

        this.setState({curp: curp})

        if (curp.length === 10) {
            let year = parseInt(curp.substr(4, 2)) + 1900
            const month = curp.substr(6, 2)
            const day = curp.substr(8, 2)
            if (year < 1920) {
                year += 100
            }
            if (program.data) {
                const min_age = program.data.min_age;
                const max_age = program.data.max_age;
                const age = moment().diff(moment(year + month + day, "YYYYMMDD"), 'years')
                if (age < min_age) {
                    this.props.showMessage({
                        message: 'Disculpe usted no cumple con la edad mínima requerida.',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                    })
                }
                if (age >= max_age) {
                    this.props.showMessage({
                        message: 'Disculpe usted no cumple con la edad máxima permitida.',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                    })
                }
            }
        }

        if (index === 0 && curp.length === 18) {
            this.props.curpSearch(curp)
        }
        if (index === 0 && curp.length < 18) {
            this.props.resetSolicitudeSave()
            this.props.resetFullForm(curp)
            this.props.resetGeolocation()
        }
    }

    changeColony = (e, index) => {
        this.props.setElectoralSectionForm(e, index);
        const { street, out_number } = this.props.form.beneficiaries[index].address
        if (e) {
            const params = { colony_id: e.value, street: street, out_number: out_number }
            this.props.getGeolocation(params);
        }
    }

    getGender = (curp) => {
        if (curp.length >= 11) {
            const digit = curp.substr(10, 1).toUpperCase()
            if (digit === 'H') {
                return 'Hombre'
            } else if (digit === 'M') {
                return 'Mujer'
            }
        } else {
            return ''
        }
    }

    render() {
        const {
            classes,
            updateBeneficiaryTextForm,
            setBeneficiarySelectForm,
            setRelationShipSelectForm,
            setElectoralSectionForm,
            setGenderTransSelectForm,
            form,
            save,
            program,
            list_relation_ship,
            electoral_sections,
            curp_search,
            ethnic,
            setEthnicSelectForm,
            setPeopleCareForm,
            user,
            setCommentForm,
            geolocation
        } = this.props;
        const options = [{ value: 'movil', label: 'Móvil' }, { value: 'casa', label: 'Casa' }];
        const people_care_options = [{ value: 'menor', label: 'Menores de edad' }, { value: 'adulto_mayor', label: 'Adultos mayores' }, { value: 'discapacitado', label: 'Personas con discapacidad' }];
        const { disabled } = this.state;
        const relationShips = list_relation_ship.data.map(relationShip => ({
            value: relationShip.id,
            label: relationShip.name
        }))

        const ethnicList = ethnic.data.map(ethnicItem => ({
            value: ethnicItem.id,
            label: ethnicItem.ethnic_group
        }))
        const buttonClassname = classNames({
            [classes.buttonSuccess]: save.isFetching,
        })

        return (
            // <MuiPickersUtilsProvider utils={MomentUtils} locale="es" moment={moment}>
            <React.Fragment>


                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h4" style={{ color: '#616161' }}> <strong>2. Registro de solicitud</strong></Typography>
                    <Typography variant="h5" style={{ color: '#E61B72' }}> <strong>Programa: {program.name_program}</strong></Typography>
                    <br />
                    <Divider style={{ backgroundImage: 'url("../../assets/images/backgrounds/ubuntu.png"', height: '5px', borderRadius: 1 }} />
                </div>
                <br />
                <form onSubmit={this.submitForm} noValidate autoComplete="off">
                    {form.beneficiaries.map((beneficiary, index) => (
                        <div key={index}>
                            <div className="flex flex-wrap">
                                <AppBar className={classes.toolbar} position="static">
                                    <Toolbar>
                                        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                                            <Icon>person_outline</Icon>
                                        </IconButton>
                                        <Typography variant="h6" color="inherit" className={classes.grow}>
                                            {'Datos del ' + (index === 0 ? program.data.beneficiary_label : program.data.dependent_label)}
                                        </Typography>
                                        <Tooltip title="Limpiar formulario" aria-label="Limpiar">
                                            <IconButton color="inherit" onClick={(e) => this.cleanForm(e, index)} aria-label="Menu">
                                                <Icon>clear</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        {index !== 0 && <Tooltip title="Eliminar beneficiario" aria-label="Eliminar">
                                            <IconButton color="inherit" onClick={(e) => this.removeItem(e, index)} aria-label="Menu">
                                                <Icon>delete</Icon>
                                            </IconButton>
                                        </Tooltip>}
                                    </Toolbar>
                                </AppBar>
                            </div>
                            <div className="flex flex-wrap">
                                <TextField
                                    name="curp"
                                    label="CURP"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    required
                                    disabled={index === 0 && disabled || index === 0 && curp_search.isFetching}
                                    value={beneficiary.curp.toUpperCase()}
                                    onChange={(e) => this.changeCurp(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].curp ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].curp ? save.data.beneficiaries[index].curp : '') : '') : '') : (beneficiary.curp.length >= 1 ? beneficiary.curp.length + ' de 18' : <a href='https://www.gob.mx/curp/' target="_blank">Consultar curp</a>)}
                                    inputProps={{
                                        maxLength: 18,
                                    }}
                                />
                                {(index !== 0 && curp_search.isFetching) && <CircularProgress size={24} className={classes.load} />}
                                {/* helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].curp ? true : '') : '') : '') : form.beneficiaries[index].curp.length === 18 ? (form.beneficiaries[index] !== form.beneficiaries[index] ? (form.beneficiaries[index].curp === form.beneficiaries[index].curp ? 'No pueden ser igual' : '') : '') : (<a href='https://www.gob.mx/curp/' target="_blank">Consultar curp</a>)} */}
                                <TextField
                                    name="birth_date"
                                    label="Fecha de nacimiento"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    required
                                    value={beneficiary.birth_date}
                                    onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].birth_date ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].birth_date ? save.data.beneficiaries[index].birth_date : '') : '') : '') : ''}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                    disabled
                                    name="gender"
                                    label="Sexo"
                                    value={this.getGender(beneficiary.curp)}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                />
                                {index !== 0 &&
                                    <Autocomplete
                                        name="relationship"
                                        placeholder='Parentesco'
                                        options={relationShips}
                                        className={classes.selectField}
                                        value={beneficiary.relationship_value}
                                        onChange={(e) => setRelationShipSelectForm(e, index)}
                                        error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].relationship ? true : false) : false) : false) : false}
                                        helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].relationship ? save.data.beneficiaries[index].relationship : '') : '') : '') : ''}
                                        isClearable
                                    />
                                }
                            </div>

                            <div className="flex flex-wrap">
                                {index === 0 && <TextField
                                    name="email"
                                    label="Correo Electrónico"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    required
                                    disabled={disabled}
                                    value={beneficiary.email}
                                    onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].email ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].email ? save.data.beneficiaries[index].email : '') : '') : '') : ''}
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />}
                                <TextField
                                    name="first_name"
                                    label="Nombre(s)"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    required
                                    value={beneficiary.first_name}
                                    onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].first_name ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].first_name ? save.data.beneficiaries[index].first_name : '') : '') : '') : ''}
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                                <TextField
                                    name="last_name"
                                    label="Apellido paterno"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    required
                                    value={beneficiary.last_name}
                                    onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].last_name ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].last_name ? save.data.beneficiaries[index].last_name : '') : '') : '') : ''}
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                                <TextField
                                    name="m_last_name"
                                    label="Apellido materno"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    value={beneficiary.m_last_name}
                                    onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].m_last_name ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].m_last_name ? save.data.beneficiaries[index].m_last_name : '') : '') : '') : ''}
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </div>

                            {index == 0 && program.data && program.data.code === "BI" && <div className="flex flex-wrap">
                                <Autocomplete
                                    name="ethnic_group"
                                    placeholder='Grupo étnico'
                                    options={ethnicList}
                                    className={classes.selectField}
                                    value={beneficiary.ethnic_group_value}
                                    onChange={(e) => setEthnicSelectForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].ethnic_group ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].ethnic_group ? save.data.beneficiaries[index].ethnic_group : '') : '') : '') : ''}
                                    isClearable
                                />
                            </div>}

                            {index === 0 && program.data && program.data.code === "PT" && <div className="flex flex-wrap">
                                <TextField
                                    name="social_name"
                                    label="Nombre social"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    required
                                    value={beneficiary.social_name}
                                    onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].social_name ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].social_name ? save.data.beneficiaries[index].social_name : '') : '') : '') : ''}
                                    inputProps={{
                                        maxLength: 150,
                                    }}
                                />
                                <Autocomplete
                                    name="gender_trans"
                                    placeholder='Género con el que se identifica'
                                    options={genderTranas}
                                    className={classes.selectField}
                                    value={beneficiary.gender_trans_value}
                                    onChange={(e) => setGenderTransSelectForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].relationship ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].relationship ? save.data.beneficiaries[index].relationship : '') : '') : '') : ''}
                                    isClearable
                                />
                            </div>}

                            {index === 0 && <div className="flex flex-wrap">
                                <TextField
                                    name="phone_number"
                                    label="Número de teléfono"
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                    required
                                    value={beneficiary.phone_number}
                                    onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].phone_number ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].phone_number ? save.data.beneficiaries[index].phone_number : '') : '') : '') : ''}
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                />
                                <Autocomplete
                                    name="phone_type"
                                    placeholder='Tipo de teléfono'
                                    options={options}
                                    className={classes.selectField}
                                    required
                                    value={beneficiary.phone_type_value}
                                    onChange={(e) => setBeneficiarySelectForm(e, index)}
                                    error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].phone_type ? true : false) : false) : false) : false}
                                    helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].phone_type ? save.data.beneficiaries[index].phone_type : '') : '') : '') : ''}
                                    isClearable
                                />
                            </div>}
                            {index === 0 && <div>
                                <div className="flex flex-wrap">
                                    <div className="flex-grow text-center">
                                        <Typography color="inherit" className="font-bold text-16">
                                            Domicilio
                                    </Typography>
                                    </div>
                                </div>
                                <div className="flex flex-wrap">
                                    <TextField
                                        name="street"
                                        label="Calle"
                                        margin="normal"
                                        variant="outlined"
                                        className={classes.textField}
                                        required
                                        value={beneficiary.address.street}
                                        onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                        error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].address ? (save.data.beneficiaries[index].address.street ? true : false) : false) : false) : false) : false}
                                        helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].address ? (save.data.beneficiaries[index].address.street ? save.data.beneficiaries[index].address.street : '') : '') : '') : '') : ''}
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                    <TextField
                                        name="out_number"
                                        label="Número exterior"
                                        margin="normal"
                                        variant="outlined"
                                        className={classes.textField}
                                        required
                                        value={beneficiary.address.out_number}
                                        onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                        error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].address ? (save.data.beneficiaries[index].address.out_number ? true : false) : false) : false) : false) : false}
                                        helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].address ? (save.data.beneficiaries[index].address.out_number ? save.data.beneficiaries[index].address.out_number : '') : '') : '') : '') : ''}
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                    />
                                    <TextField
                                        name="int_number"
                                        label="Número interior"
                                        margin="normal"
                                        variant="outlined"
                                        className={classes.textField}
                                        value={beneficiary.address.int_number}
                                        onChange={(e) => updateBeneficiaryTextForm(e, index)}
                                        error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].address ? (save.data.beneficiaries[index].address.int_number ? true : false) : false) : false) : false) : false}
                                        helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].address ? (save.data.beneficiaries[index].address.int_number ? save.data.beneficiaries[index].address.int_number : '') : '') : '') : '') : ''}
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                    />
                                </div>

                                <div className="flex flex-wrap">
                                    <Autocomplete
                                        name="colony"
                                        placeholder='Colonia'
                                        options={electoral_sections.data}
                                        className={classes.selectField}
                                        value={beneficiary.address.electoral_section_value}
                                        onChange={(e) => this.changeColony(e, index)}
                                        error={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].address ? (save.data.beneficiaries[index].address.colony ? true : false) : false) : false) : false) : false}
                                        helperText={save.error ? (save.data.beneficiaries ? (save.data.beneficiaries[index] ? (save.data.beneficiaries[index].address ? (save.data.beneficiaries[index].address.colony ? save.data.beneficiaries[index].address.colony : '') : '') : '') : '') : ''}
                                        isClearable
                                    />
                                </div>

                                {/* {program.data.min_beneficiary !== 0 ? (<Typography className="text-18 font-bold ml-16 mt-12 mb-12">**Registrar dependientes</Typography>) : null} */}
                                <div className="flex flex-wrap">
                                    <div className={classes.mapField}>
                                        <GoogleMap
                                            bootstrapURLKeys={{
                                                key: "AIzaSyAYvTx7vs4_JRJo-SloAnp-6cX_jGOoKoE"
                                            }}
                                            defaultZoom={16}
                                            defaultCenter={[19.419444, -99.145556]}
                                            zoom={geolocation.zoom}
                                            center={[geolocation.latitude, geolocation.longitude]}
                                        >
                                            <Marker
                                                text="Domicilio"
                                                lat={geolocation.latitude}
                                                lng={geolocation.longitude}
                                                
                                            />
                                            
                                        </GoogleMap>

                                    </div>
                                </div>
                            </div>}
                        </div>
                    ))}

                    {program.data !== undefined && (program.data.code === 'BI' || program.data.code === 'BD') && (program.data.max_beneficiary !== 0 && (form.beneficiaries.length - 1) < program.data.max_beneficiary &&
                        <div className="flex flex-wrap justify-center">
                            <Tooltip title="Le permite agregar un dependiente económico" aria-label="Agregar dependiente">
                                <Button variant="extendedFab" color="primary" aria-label="Agregar" onClick={(e) => this.addItem(e)} className={classes.button}>
                                    <Icon>add</Icon>
                                    {'Agregar ' + program.data.dependent_label}
                                </Button>
                            </Tooltip>
                        </div>
                    )
                    }

                    {program.data !== undefined && (program.data.code === 'BI' || program.data.code === 'BD') && (program.data.min_beneficiary !== 0 && (form.beneficiaries.length - 1) < program.data.min_beneficiary &&
                        <div className="flex flex-wrap justify-end">
                            <Typography color="primary" className="text-12">
                                {'Requiere registrar almenos ' + program.data.min_beneficiary + ' dependiente(s)'}
                            </Typography>
                        </div>)
                    }

                    {program.data && program.data.code === "PC" &&
                        <Autocomplete
                            name="people_care"
                            placeholder='Tipo de persona a la que cuida'
                            options={people_care_options}
                            className={classes.selectField}
                            value={form.people_care_value}
                            onChange={(e) => setPeopleCareForm(e)}
                            error={save.error ? (save.data.people_care ? true : false) : false}
                            helperText={save.error ? (save.data.people_care ? 'Este campo no puede quedar en blanco' : '') : ''}
                            isClearable
                        />
                    }

                    {user.role !== 'user' && <div className="flex flex-wrap">
                        <TextField
                            name="commnet"
                            label="Comentario"
                            margin="normal"
                            variant="outlined"
                            className={classes.textField}
                            value={form.comment}
                            multiline
                            rows={5}
                            fullWidth
                            onChange={(e) => setCommentForm(e.target.value)}
                        />
                    </div>}

                    <div className="flex flex-wrap justify-end">
                        <Typography color="error" className="text-12">
                            {save.data.non_field_errors}
                        </Typography>
                    </div>

                    {/* <div className="flex flex-wrap justify-end"></div> */}
                    <div className={classNames(classes.root, 'justify-end')}>
                        <div className={classNames(classes.wrapper)}>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={this.onBack}
                                className={classes.button}
                                aria-label="ATRAS"
                                value="legacy"
                            >
                                ATRAS
                        </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                disabled={program.data !== undefined && ((program.data.code === 'BI' || program.data.code === 'BD') && !form.canSubmit) || save.isFetching}
                                aria-label="CONTINUAR"
                                value="legacy"
                            >
                                CONTINUAR
                        </Button>
                            {save.isFetching && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>

                    {/* <div className={classes.wrapper}>
                    <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            disabled={!form.canSubmit || save.isFetching}
                            aria-label="CONTINUAR"
                            value="legacy"
                        >
                            CONTINUAR
                        </Button>
                        {save.isFetching && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div> */}
                </form>
            </React.Fragment>
            // </MuiPickersUtilsProvider>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        showMessage: Actions.showMessage,
        hideMessage: Actions.hideMessage,
        setSolicitudeId: Actions.setSolicitudeId,
        setProgramForm: Actions.setProgramForm,
        resetBeneficiaryForm: Actions.resetBeneficiaryForm,
        resetBeneficiaryTransForm: Actions.resetBeneficiaryTransForm,
        resetDependantForm: Actions.resetDependantForm,
        resetDependentEthnicForm: Actions.resetDependentEthnicForm,
        addBeneficiaryUserForm: Actions.addBeneficiaryUserForm,
        addBeneficiaryForm: Actions.addBeneficiaryForm,
        addBeneficiaryUserTransForm: Actions.addBeneficiaryUserTransForm,
        addBeneficiaryTransForm: Actions.addBeneficiaryTransForm,
        addDependentEthnicForm: Actions.addDependentEthnicForm,
        addDependentForm: Actions.addDependentForm,
        removeBeneficiaryForm: Actions.removeBeneficiaryForm,
        updateBeneficiaryTextForm: Actions.updateBeneficiaryTextForm,
        setBeneficiarySelectForm: Actions.setBeneficiarySelectForm,
        setRelationShipSelectForm: Actions.setRelationShipSelectForm,
        setGenderTransSelectForm: Actions.setGenderTransSelectForm,
        saveSolicitude: Actions.saveSolicitude,
        updateSolicitude: Actions.updateSolicitude,
        setBeneficiariesData: Actions.setBeneficiariesData,
        clean_program_choice: Actions.clean_program_choice,
        relationShipList: Actions.relationShipList,
        listElectoralSections: Actions.listElectoralSections,
        setElectoralSectionForm: Actions.setElectoralSectionForm,
        resetSolicitudeForm: Actions.resetSolicitudeForm,
        resetSolicitudeSave: Actions.resetSolicitudeSave,
        listEthnicGroup: Actions.listEthnicGroup,
        setEthnicSelectForm: Actions.setEthnicSelectForm,
        addPeopleCareForm: Actions.addPeopleCareForm,
        setPeopleCareForm: Actions.setPeopleCareForm,
        setCommentForm: Actions.setCommentForm,
        curpSearch: Actions.curpSearch,
        resetFullForm: Actions.resetFullForm,
        getGeolocation: Actions.getGeolocation,
        resetGeolocation: Actions.resetGeolocation,
    }, dispatch);
}

function mapStateToProps({ auth, program, solicitude, catalogs }) {
    return {
        user: auth.user,
        program: program.choice,
        form: solicitude.form,
        save: solicitude.save,
        list_relation_ship: catalogs.list,
        electoral_sections: catalogs.electoral_sections,
        ethnic: catalogs.ethnic,
        curp_search: solicitude.curp_search,
        geolocation: catalogs.geolocation
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(SolicitudeRegisterForm)));