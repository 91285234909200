import SocioeconomicProfile from './SocioeconomicProfile';
import {authRoles} from 'auth';

export const SocioeconomicProfileConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    // auth    : authRoles.user,
    routes  : [{
            path     : '/perfil_socioeconomico',
            component: SocioeconomicProfile
        }
    ]
};