import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';


const styles = theme => ({
    root     : {
        display   : 'flex',
    },
    // Reproduce the Facebook spinners.
    facebook             : {
        position: 'relative'
    },
    facebook1            : {
        color: '#eef3fd'
    },
    facebook2            : {
        color            : '#B22469',
        animationDuration: '550ms',
        position         : 'absolute',
        left             : 0
    }
});

class FacebookLoading extends Component {
    render() {
        const {classes, size} = this.props;
        return (
            <div className={classes.facebook}>
                <CircularProgress
                    variant="determinate"
                    value={100}
                    className={classes.facebook1}
                    size={size?size:27}
                    thickness={4}
                />
                <CircularProgress
                    variant="indeterminate"
                    className={classes.facebook2}
                    size={size?size:27}
                    thickness={4}
                />
            </div>
        )
    }
}

export default withStyles(styles, {withTheme: true})(FacebookLoading);