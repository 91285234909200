import { fetchListUsers } from 'api'

export const FETCHING_USER_LIST = 'FETCHING_USER_LIST';
export const FETCHING_USER_LIST_SUCCESS = 'FETCHING_USER_LIST_SUCCESS';
export const FETCHING_USER_LIST_FAILURE = 'FETCHING_USER_LIST_FAILURE';


export const getData = () => {
    return {
        type: FETCHING_USER_LIST
    }
}

export const getDataSuccess = data => {
    return {
        type: FETCHING_USER_LIST_SUCCESS,
        data
    }
}

export const getDataFailure = error => {
    return {
        type: FETCHING_USER_LIST_FAILURE,
        error
    }
}

export const listUsers = data => {
    return (dispatch) => {
        dispatch(getData())
        fetchListUsers(data)
            .then(data => {
                dispatch(getDataSuccess(data.data))
            })
            .catch(error => {
                dispatch(getDataFailure(error.response ? error.response.data : {}))
            })
    }
}