import { combineReducers } from 'redux';
import auth from 'auth/store/reducers';
import fuse from './fuse';
import program from './program';
import solicitude from './solicitude';
import catalogs from './catalogs';
import users from './users';
const createReducer = (asyncReducers) =>
    combineReducers({
        auth,
        fuse,
        program,
        solicitude,
        catalogs,
        users,
        ...asyncReducers
    });

export default createReducer;
